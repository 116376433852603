<template>
    <Popin ref="popin" class="product-lightbox">
        <template v-slot:content v-if="product">
            <div class="product-lightbox__inner">
                <ImagesSlider v-if="images?.length > 1" :images="images"/>
                <div v-else class="product-lightbox__single-image">
                    <ImageProduct :image="images ? images[0] : null"/>
                </div>
                <div class="product-lightbox__infos">
                    <TextTitle size="l" tag="h2">{{ product.title }}</TextTitle>
                    <br>
                    <TextTitle size="s" tag="p">{{ product.price | money }}</TextTitle>
                    <ul>
                        <li v-for="(field, i) in fields" v-show="product[field.key] && product[field.key] !== ''" :key="i"><TextSimple size="m" weight="medium">{{ field | fieldLabel }}:</TextSimple> <TextSimple size="m">{{ product[field.key] }}</TextSimple></li>
                    </ul>
                    <ButtonLink v-if="canAccessResaleRoutes" icon="right" size="m" color="blue" :to="{name: 'resale', params: {id: product.id}}">{{ $t('global.resale') }}</ButtonLink>
                    <ButtonLink icon="shopify" size="m" color="grey" :to="product.url">{{ $t('resale.see-product') }}</ButtonLink>
                </div>
            </div>
        </template>
    </Popin>
</template>

<script>
import { mapGetters } from "vuex";
import Popin from '@/components/ui/popin/Popin';
import ImagesSlider from '@/components/ui/images/ImagesSlider';
import TextTitle from '@/components/ui/text/TextTitle';
import ImageProduct from '@/components/ui/images/ImageProduct';
import ButtonLink from '@/components/ui/button/ButtonLink';
import TextSimple from '@/components/ui/text/TextSimple';

export default {
    name: 'ProductLightbox',
    components: {TextSimple, ButtonLink, ImageProduct, TextTitle, ImagesSlider, Popin},
    props: {
        product: Object
    },
    data() {
        return {
            fields: []
        }
    },
    methods: {
        open() {
           this.$refs.popin.open()
        },
        getFields() {
            if (!this.fields?.lenght && this.product) {
                let fields = [
                    {key: 'id'},
                    {key: 'size'},
                    {key: 'color'},
                    {key: 'etat', label: 'condition'},
                ];
                let newFields = Object.keys(this.product).filter(x => x.includes('tag_')).map(x => ({
                    key: x,
                    label: this.$options.filters.capitalize(x.replaceAll('tag_', 'Tag ')),
                    noTranslate: true
                }));
                this.fields = [...fields, ...newFields]
            }
        }
    },
    computed: {
        ...mapGetters(['roles']),
        canAccessResaleRoutes() {
            const allowedRoles = this.$router.options.routes.find(x=>x.name === 'resale').meta.roles;
            return this.roles.some(role => allowedRoles.includes(role));
        },
        images() {
            let images = [];
            ['photo1', 'photo2', 'photo3', 'photo4', 'photo5', 'photo6'].forEach(key => {
                if (this.product[key]) images.push(this.product[key])
            });
            return images
        }
    },
    watch: {
        product() {
            this.getFields()
        }
    },
    mounted() {
        this.getFields();
    }
}
</script>

<style lang="scss">
.product-lightbox {

    .popin__inner {
        max-width: 1000px;
        padding: 0;

        @media screen and (max-width: 800px) {
            overflow-y: auto;
        }

        .images-slider img {
            max-height: calc(100vh - var(--page-margin) - var(--page-margin));
            object-fit: contain;
            object-position: center;
        }
    }

    .images-slider .slider-item {
        @media screen and (max-width: 800px) {
            width: 50%;
        }
    }

    &__inner {

        @media screen and (min-width: 801px) {
            display: flex;
        }

        & > * {
            width: 100%;
            position: relative;

            @media screen and (min-width: 801px) {
                max-width: 50%;
            }

           /*
            &:first-child {
                position: relative;

                &:before {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: var(--color-bg-grey);
                    z-index: 3;
                    mix-blend-mode: multiply;
                }
            }

            */
        }
    }

    &__infos {
        text-align: left;
        padding: 40px;
        max-height: calc(100vh - var(--page-margin) - var(--page-margin));
        overflow-y: auto;
        background-color: var(--color-bg-light);

        @media screen and (max-width: 800px) {
            padding: 20px;
            max-height: calc(100vh - 30px);
        }

        ul {
            list-style-type: disc;
            list-style-position: inside;
            margin: 40px 0;
            line-height: 1.4;
        }

        li {
            margin: 5px 0;

            & > * {
                display: inline;
            }
        }

        .button {
            margin-left: 0 !important;

            &:first-of-type {
                margin-top: 40px;
            }
        }
    }

    &__single-image {
        img {
            width: 100%;
            height: 100%;
            position: absolute;
            object-position: center;
            object-fit: cover;
            top: 0;
            left: 0;
        }
    }
}
</style>