var render = function render(){var _vm=this,_c=_vm._self._c;return _c('TemplateDetails',{attrs:{"get-function":_vm.getTradeinArticle,"data":_vm.recom_tradeinArticle,"loading":_vm.recom_tradeinArticleLoading}},[_c('PageBack'),(_vm.article)?_c('div',{staticClass:"recom-ts-article"},[_c('PageHeader',{attrs:{"title":_vm.$t('article_cms.title', { id: _vm.article.reference })},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.article?.url)?_c('ButtonLink',{attrs:{"to":_vm.article?.url,"icon":"eye","size":"m","color":"black"}},[_vm._v(_vm._s(_vm.$t("article_cms.see_product")))]):_vm._e()]},proxy:true},{key:"text",fn:function(){return [_c('FieldSwitch',{staticStyle:{"width":"fit-content"},attrs:{"model":_vm.view,"title_1":_vm.$t('article_cms.view_tradein'),"value_1":"tradein","title_2":_vm.$t('article_cms.view_sell'),"value_2":"sell"},on:{"change":(newView) => _vm.$router.replace({ query: { view: newView } })}})]},proxy:true}],null,false,2821841894)}),_c('GridContainer',{scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('GridCard',[_c('GridContent',[_c('GridHeader',{attrs:{"title":_vm.$t('article_cms.general')}}),_c('SectionTableInfos',{attrs:{"object":{
                ..._vm.article,
                channel_distribution: _vm.$t('article.canal.web'),
              },"fields":_vm.informationFields}})],1)],1)]},proxy:true}],null,false,857185837)},[(_vm.view === 'sell')?_c('GridCard',{staticClass:"recom-ts-article__sell-table"},[_c('GridContent',[_c('GridHeader',{attrs:{"title":_vm.$t('article_cms.informations')}}),_c('div',{staticClass:"recom-ts-article__sell-table__content"},[(_vm.photos?.length)?_c('ImagesWithLightbox',{attrs:{"images":_vm.photos,"with-navigation":""}}):_vm._e(),_c('div',{staticClass:"recom-ts-article__sell-table__infos"},[_c('TextTitle',{attrs:{"size":"s"}},[_vm._v(_vm._s((_vm.article && _vm.article.metadata && _vm.article.metadata.title) || ""))]),_c('SectionTableInfos',{attrs:{"object":_vm.articleMetadataObject,"fields":_vm.metadataFields}}),(_vm.article.metadata?.description?.length)?_c('Collapse',{class:{ 'is-grey': !_vm.article.metadata?.composition?.length },attrs:{"title":_vm.$t('global.description'),"content":_vm.article.metadata.description}}):_vm._e(),(_vm.article.metadata?.composition?.length)?_c('Collapse',{staticClass:"is-grey",attrs:{"title":_vm.$t('article_cms.composition'),"content":_vm.article.metadata.composition}}):_vm._e()],1)],1)],1)],1):_vm._e(),(_vm.view === 'tradein')?_c('GridCard',{staticClass:"recom-ts-article__tradein-table"},[_c('GridContent',[_c('GridHeader',{attrs:{"title":_vm.$t('article_cms.informations')}}),_c('Table',{staticClass:"recom-ts-article__comparaison"},[_c('Thead',{attrs:{"fields":_vm.controlledFields}}),_c('Tbody',_vm._l((['sku', 'color', 'size', 'condition']),function(prop){return _c('Row',{key:prop,attrs:{"set":(_vm.key = `global.${_vm.article[prop]}`)}},[(_vm.article?.controlReview)?_c('TableCell',{staticClass:"is-size--xs",attrs:{"type":"check","value":_vm.article.initialReview?.[prop] ===
                    _vm.article.controlReview?.[prop],"size":"xl"}}):_vm._e(),_c('TableCell',{attrs:{"value":_vm.$t(`recom_resale.attributes.${prop}`) + ' :'}}),_c('TableCell',{attrs:{"set":(_vm.key = `global.${_vm.article.initialReview?.[prop]}`),"value":_vm.$te(_vm.key) ? _vm.$t(_vm.key) : _vm.article.initialReview?.[prop]}}),_c('TableCell',{attrs:{"set":(_vm.isInvalid =
                      _vm.article.initialReview?.[prop] !==
                      _vm.article?.controlReview?.[prop])}},[(_vm.article?.controlReview?.[prop])?_c('TextSimple',{attrs:{"set":(_vm.key = `global.${_vm.article?.controlReview?.[prop]}`),"color":_vm.isInvalid ? 'red' : null}},[_vm._v(" "+_vm._s(_vm.$te(_vm.key) ? _vm.$t(_vm.key) : _vm.article.controlReview?.[prop])+" "),(
                        _vm.isInvalid && prop === 'condition' && _vm.rejectedReason
                      )?_c('ToolTip',{attrs:{"infos":_vm.rejectedReason}}):_vm._e()],1):_c('TextSimple')],1)],1)}),1)],1),(_vm.article.controlReview?.faultPhotos?.length)?_c('ImagesWithLightbox',{staticClass:"recom-ts-article__images",attrs:{"images":_vm.article.controlReview?.faultPhotos.map((x) => x.url),"with-navigation":""}}):_vm._e()],1)],1):_vm._e(),_c('GridCard',[_c('GridContent',[_c('GridHeader',{attrs:{"title":_vm.view === 'tradein'
                ? _vm.$t('article_cms.tradein_history')
                : _vm.$t('article_cms.sell_history')}}),_c('GridHistory',{attrs:{"dates":_vm.history}})],1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }