<template>
    <div class="order-summary">
        <div class="order-summary__content">
            <p class="order-summary__row">
                <TextSimple>{{ $t('global.subtotal') }} :</TextSimple>
                <TextSimple>{{ order.itemsTotal | formatPrice }}</TextSimple>
            </p>
            <div class="order-summary__row">
                <TextSimple>{{ $t('global.shippingTotal') }} :</TextSimple>
                <div class="order-summary__flex">
                    <PopinTooltip v-if="order?.shipment?.refund">
                        <template #button>
                            <Tag :text="$t('global.refund')" color="orange" iconRight="info"/>
                        </template>
                        <template #content>
                            <div class="order-summary__popin">
                                <TextSimple weight="medium">{{ $t('global.payback') }} ({{ order.shipment.refund.createdAt | dateHourFromUTC }})</TextSimple>
                                <div class="order-summary__summary" v-if="order?.shipment?.refund?.reason">
                                    <p class="order-summary__row">
                                        <TextSimple size="m" color="grey">{{ $t("order.refund_reason") }}:</TextSimple>
                                        <TextSimple size="m" color="grey">{{ order.shipment.refund.reason }}</TextSimple>
                                    </p>
                                </div>
                            </div>
                        </template>
                    </PopinTooltip>
                    <TextSimple>{{ order.shippingTotal | formatPrice }}</TextSimple>
                </div>
            </div>
            <template v-if="order.discountTotal">
                <div class="order-summary__row">
                    <div class="order-summary__discount">
                        <TextSimple color="green">
                            {{ $t('global.totalDiscountApplied')}}:
                        </TextSimple>
                        <Tag v-for="discount in order.discounts" :key="discount.id" :text="discount?.code" color="green" icon-left="promotion"/>
                    </div>
                    <TextSimple color="green">{{ order.discountTotal | formatPrice }}</TextSimple>
                </div>
            </template>
            <p class="order-summary__row">
                <TextSimple>{{ $t('global.taxTotal') }} :</TextSimple>
                <TextSimple>{{ order.taxTotal | formatPrice }}</TextSimple>
            </p>
            <br>
            <p class="order-summary__row">
                <TextSimple size="xl" weight="medium">{{ $t('global.totalttc') }}:</TextSimple>
                <TextSimple size="xl" weight="medium">{{ order.total | formatPrice }}</TextSimple>
            </p>
            <p class="order-summary__row" v-for="payment in order.payments" :key="payment.id">
                <TextSimple color="grey">{{ $t('global.paidWith') }} {{ payment.type }} {{ payment.gateway == 'CORE' ? `(${payment.reference})` : '' }}</TextSimple>
                <TextSimple color="grey">{{ payment.amount | formatPrice }}</TextSimple>
            </p>
            <br v-if="order.refunds">
            <div class="order-summary__row" v-for="refund in order.refunds" :key="refund.id">
                <div>
                    <PopinTooltip>
                        <template #button>
                            <TextSimple size="l" weight="medium">{{ $t('global.payback') }} ({{ refund.createdAt | dateHourFromUTC }})</TextSimple>
                            <SvgIcon name="info" />
                        </template>
                        
                        <template #content>
                            <div class="order-summary__popin">
                                <TextSimple weight="medium">{{ $t('global.payback') }} ({{ refund.createdAt | dateHourFromUTC }})</TextSimple>
                                <div class="order-summary__summary">
                                    <p v-for="transaction in refund.transactions" :key="transaction.id" class="order-summary__row">
                                        <TextSimple size="m" color="grey">{{ $t('global.refunded_on') }} {{transaction.type}}</TextSimple>
                                        <TextSimple size="m" color="grey">{{ transaction.amount | formatPrice }}</TextSimple>
                                    </p>
                                    <p class="order-summary__row" v-if="refund.feeAmount">
                                        <TextSimple size="m" color="grey">{{ $t('global.retainedReturnFeeDetailed') }}</TextSimple>
                                        <TextSimple size="m" color="grey">{{ refund.feeAmount | formatPrice }}</TextSimple>
                                    </p>
                                </div>
                            </div>
                        </template>
                    </PopinTooltip>
                </div>
                <TextSimple size="l" weight="medium">{{ refund.amount | formatPrice }}</TextSimple>
            </div>
            <br v-if="totalRefund">
            <p class="order-summary__row" v-if="totalRefund">
                <TextSimple size="xl" weight="medium">{{$t('global.refundTotalTtc')}} :</TextSimple>
                <TextSimple  size="xl" weight="medium">{{ totalRefund | formatPrice }}</TextSimple>
            </p>

            <!-- Remboursements par type -->
            <p v-for="refundType in refundsByType" :key="refundType.type" class="order-summary__row">
                <TextSimple color="grey">{{$t(refundLabel)}} {{refundType.type }}:</TextSimple>
                <TextSimple color="grey">{{ refundType.amount | formatPrice }}</TextSimple>
            </p>

            <!-- Montant prélevé à l'utilisateur -->
            <p class="order-summary__row" v-if="deductedDelivery">
                <TextSimple color="grey">{{ $t('global.returnItemDeliveryChargedFees') }}:</TextSimple>
                <TextSimple color="grey">-{{ deductedDelivery | formatPrice }}</TextSimple>
            </p>

            <!-- Montant total net -->
            <br>
            <p class="order-summary__row">
                <TextSimple size="xl" weight="medium">{{ $t('global.totalNetTtc') }}:</TextSimple>
                <TextSimple size="xl" weight="medium">
                    {{ totalPaidByUser | formatPrice }} 
                </TextSimple>
            </p>
        </div>
    </div>
</template>

<script>
import TextSimple from '@/components/ui/text/TextSimple';
import PopinTooltip from '@/components/ui/popin/PopinTooltip';
import SvgIcon from '@/components/ui/svg/SvgIcon';
import Tag from "@/components/ui/tag/Tag";
import { mapGetters } from 'vuex';

export default {
    name: "OrderSummary",
    components: {
        TextSimple,
        PopinTooltip,
        SvgIcon,
        Tag
    },
    props: {
        order: {
            type: Object,
            required: true
        },
        mode: {
            type: String,
            default: 'detail', // 'detail' or 'refund'
            validator: value => ['detail', 'refund'].includes(value)
        }
    },
    computed: {
        ...mapGetters([
            'totalRefund',
            'totalPaidByUser',
            'refundsByType',
            'deductedDelivery'
        ]),
        refundLabel() {
            return this.mode === 'detail' ? 'global.refunded_on' : 'global.refund_on';
        }
    }
}
</script>

<style lang="scss">
.order-summary {
    .tooltip__content {
      width: 350px;
      max-width: 100vw;
    }
    .tooltip__button {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      gap: 10px;
      padding-top: 0;
    }
    &__popin {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    
    &__flex {
        display: flex;
        gap: 10px;
        padding-top: 0;
        align-items: center;
    }

    &__discount {
        --svg-color: var(--color-text-green);
        display: flex;
        flex-direction: row;
        column-gap: 5px;
    }

    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 15px;
        margin-bottom: 12px;
    }
}
</style>