<template>
    <TemplateClient :hide-back="!isAdmin">

        <template v-slot:actions v-if="client">
            <ButtonLink size="m" icon="shopify" color="grey" :to="client.shopifyUrl + '/admin'">{{ $t('client.cta_shopify_admin') }}</ButtonLink>
            <ButtonLink size="m" icon="home" color="grey" :to="client.frontUrl">{{ $t('client.cta_shopify_store') }}</ButtonLink>
        </template>

        <CheckerboardContainer v-if="client">
            <CheckerboardItem v-for="(item, i) in allowed"
                              :key="i"
                              :title="$t(item.title)"
                              title-size="s"
                              :route="{name: item.route}"
                              :image="item.icon"/>
        </CheckerboardContainer>

    </TemplateClient>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ButtonLink from '@/components/ui/button/ButtonLink';
import CheckerboardContainer from '@/components/ui/checkerboard/Checkerboard';
import CheckerboardItem from '@/components/ui/checkerboard/CheckerboardItem';
import TemplateClient from '@/components/templates/TemplateClient';

export default {
    name: 'ClientPage',
    components: {TemplateClient, CheckerboardItem, CheckerboardContainer, ButtonLink},
    data() {
        return {
            stores: null,
        }
    },
    computed: {
        ...mapState(['client']),
        ...mapGetters(['isAdmin', 'roles', 'roleIsAllowed', 'userRecomType']),
        allowed() {
            return this.list.filter(item => this.allRoutes.find(route => route.name === item.route)?.roles.find(role => this.roles.includes(role)))
        },
        allRoutes() {
            return this.$router.options.routes.map(route => ({name: route.name, roles: route.meta?.roles || []}))
        },
        list() { 
            const response = [
                {
                    title: 'global.informations',
                    icon: '/img/goldenmetrics/details.svg',
                    route: 'client-details'
                },
                {
                    title: 'client.recommerce.title',
                    icon: '/img/goldenmetrics/cart.svg',
                    route: 'client-recommerce'
                },
                {
                    title: 'catalogue.setup',
                    icon: '/img/goldenmetrics/catalog.svg',
                    route: 'client-catalog'
                },
                    /*
                {
                    title: 'client.gift_card.title',
                    icon: '/img/goldenmetrics/gift-card.svg',
                    route: 'client-gift-card'
                },
                {
                    title: 'client.shipping_return.title',
                    icon: '/img/goldenmetrics/shipping.svg',
                    route: 'client-shipping-return'
                },
                     */
                {
                    title: 'global.stores',
                    icon: '/img/goldenmetrics/store.svg',
                    route: 'client-stores'
                },
                {
                    title: 'languages.languagesTitle',
                    icon: '/img/goldenmetrics/translation.svg',
                    route: 'client-configuration-languages'
                }
            ]
            // Show emailbuilder access here for user not admin ut with acess to mailbuilder ( only faume cms user)
            if( 
                this.roleIsAllowed(['ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV', 'ROLE_CLIENT', 'ROLE_FINANCE']) 
                && !this.roleIsAllowed(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']) 
                && this.userRecomType.includes('FAUME')
            ) {
                response.push({
                    title: 'client.mailbuilder',
                    icon: '/img/goldenmetrics/email.svg',
                    route: 'client-new-recommerce-emails'
                })
            }
            return response;
        },
    }
}
</script>
