import { debounce } from "lodash";

export default {
  data() {
    return {
      pagination: {
        currentPage: 1,
        totalPages: 0,
        totalItems: 0,
        itemsPerPage: 10,
      },
      activeFilters: {},
      currentTab: "all",
    };
  },

  methods: {
    async handleSearch(value) {
      this.pagination.currentPage = 1;

      const query = {
        ...this.$route.query,
        page: 1,
      };

      if (value) {
        query.reference = value;
      } else {
        delete query.reference;
      }

      await this.$router.push({ query });

      await this.loadTableData({
        ...this.activeFilters,
        ...(value ? { "reference[eq]": value } : {}),
      });
    },
   
    // Gestion des tabs
    handleTabChange(tabId) {
      if (this.currentTab === tabId) return;
      
      // Vérifie si nous sommes sur l'onglet "all" avec un filtre draft/unpublished
      const isLeavingAllTabWithSpecialFilters = this.currentTab === "all" && 
        this.activeFilters.publishStates && 
        this.activeFilters.publishStates.values && 
        this.activeFilters.publishStates.values.some(value => 
          value === "draft" || value === "unpublished"
        );
      
      // Mise à jour de l'état local
      this.currentTab = tabId;
      this.pagination.currentPage = 1;

      // Mise à jour de l'URL
      this.$router.push({
        query: {
          ...this.$route.query,
          tab: tabId,
          page: 1,
        },
      }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          console.error('Erreur navigation:', err);
        }
      });
      
      if (!isLeavingAllTabWithSpecialFilters) {
        this.loadTableData(this.activeFilters);
      }
    },

    // Gestion des filtres
    handleFiltersChange: debounce(function (newFilters) {

      const targetTab = newFilters._targetTab;
      if (targetTab) {
        delete newFilters._targetTab;
      }

      if (JSON.stringify(this.activeFilters) === JSON.stringify(newFilters))
        return;

      this.activeFilters = newFilters || {};
      this.pagination.currentPage = 1;


      // Vérifier si des erreurs sont sélectionnées
      const hasErrorsFilter = newFilters?.errors && newFilters.errors.values.length > 0;

      // Si des erreurs sont sélectionnées, basculer l'onglet actif vers "to_correct"
      if (hasErrorsFilter && this.currentTab !== "to_correct") {
        this.currentTab = "to_correct";
      }

      else if (targetTab && this.currentTab !== targetTab) {
        this.currentTab = targetTab;
      }

      const params = {
        "currency[in]": this.currentCurrency,
      };

      // Ajout des autres filtres s'ils existent
      const apiFilters = this.transformFiltersForAPI(this.activeFilters);
      Object.entries(apiFilters).forEach(([key, value]) => {
        params[key] = value;
      });

      this.$store.dispatch("fetchTotalStatePublish", params);
      this.$router.push({
        query: {
          ...this.$route.query,
          tab: this.currentTab, 
          page: 1,
          filters: Object.keys(this.activeFilters).length
            ? JSON.stringify(this.activeFilters)
            : undefined,
        },
      });
      this.loadTableData(this.activeFilters);
    }, 300),

    // Gestion de la pagination
    handlePageChange(page) {
      if (page < 1 || page > this.pagination.totalPages) return;

      // Mise à jour de l'état local
      this.pagination.currentPage = page;

      // Mise à jour de l'URL
      this.$router.push({
        query: { ...this.$route.query, page },
      });

      // Chargement des données
      this.loadTableData(this.activeFilters);
    },

    handleItemsPerPageChange(newItemsPerPage) {
      // Mise à jour de l'état local
      this.pagination.itemsPerPage = newItemsPerPage;
      this.pagination.currentPage = 1;

      // Mise à jour de l'URL
      this.$router.push({
        query: {
          ...this.$route.query,
          limit: newItemsPerPage,
          page: 1,
        },
      });

      // Chargement des données
      this.loadTableData(this.activeFilters);
    },

    transformFiltersForAPI(filters) {
      const apiFilters = {};
      if (!filters) return apiFilters;

      // Référence
      if (this.$route.query.reference) {
        apiFilters["reference[eq]"] = this.$route.query.reference;
      }

      // Canaux de vente
      if (filters.channels) {
        const operator = filters.channels.operator === "inclus" ? "in" : "nin";
        apiFilters[`channel[${operator}]`] = filters.channels.values.join(",");
      }

      // Pour les états de publication
      // if (filters.publishStates) {
      //   const operator =
      //     filters.publishStates.operator === "inclus" ? "in" : "nin";
      //   apiFilters[`publishState[${operator}]`] =
      //     filters.publishStates.values.join(",");
      // } else if (this.currentTab !== "all") {
      //   // Si pas de filtre d'état mais un tab actif
      //   apiFilters["publishState[in]"] = this.currentTab;
      // }

      // Dates de stock
      if (filters.stockDate) {
        const stockDateFilter = filters.stockDate;

        // Vérifions si nous avons des valeurs de date
        if (stockDateFilter.value1 || stockDateFilter.value2) {
          // Si nous avons deux dates, toujours utiliser gte/lte
          if (stockDateFilter.value1 && stockDateFilter.value2) {
            apiFilters[`stockAt[gte]`] = stockDateFilter.value1;
            apiFilters[`stockAt[lte]`] = stockDateFilter.value2;
          }
          // Si nous avons une seule date
          else {
            const operator = stockDateFilter.operator;
            const value = stockDateFilter.value1 || stockDateFilter.value2;

            if (operator === "supérieur à") {
              apiFilters[`stockAt[gte]`] = value;
            } else if (operator === "inférieur à") {
              apiFilters[`stockAt[lte]`] = value;
            } else if (operator === "Entre") {
              // Pour un opérateur "Entre" avec une seule date
              if (stockDateFilter.value1) {
                apiFilters[`stockAt[gte]`] = stockDateFilter.value1;
              }
              if (stockDateFilter.value2) {
                apiFilters[`stockAt[lte]`] = stockDateFilter.value2;
              }
            }
          }
        }
      }

      // Dates de publication
      if (filters.publicationDate) {
        console.log(
          "DEBUG - publicationDate filter raw:",
          JSON.stringify(filters.publicationDate)
        );

        const publicationDateFilter = filters.publicationDate;

        // Vérifions si nous avons des valeurs de date valides
        if (
          publicationDateFilter.value1?.trim() ||
          publicationDateFilter.value2?.trim()
        ) {
          // Si nous avons deux dates, toujours utiliser gte/lte
          if (publicationDateFilter.value1 && publicationDateFilter.value2) {
            apiFilters[`publishedAt[gte]`] = publicationDateFilter.value1;
            apiFilters[`publishedAt[lte]`] = publicationDateFilter.value2;
          }
          // Si nous avons une seule date
          else {
            const operator = publicationDateFilter.operator;
            const value =
              publicationDateFilter.value1 || publicationDateFilter.value2;

            if (operator === "supérieur à" && value) {
              apiFilters[`publishedAt[gte]`] = value;
            } else if (operator === "inférieur à" && value) {
              apiFilters[`publishedAt[lte]`] = value;
            } else if (operator === "Entre") {
              // Pour un opérateur "Entre" avec une seule date
              if (publicationDateFilter.value1) {
                apiFilters[`publishedAt[gte]`] = publicationDateFilter.value1;
              }
              if (publicationDateFilter.value2) {
                apiFilters[`publishedAt[lte]`] = publicationDateFilter.value2;
              }
            }
          }
        }
      }

      // Erreurs
      if (filters.errors) {
        const operator = filters.errors.operator === "inclus" ? "in" : "nin";
        apiFilters[`reportingError[${operator}]`] =
          filters.errors.values.join(",");

          apiFilters["publishState[in]"] = "to_correct";
      }

      // États de publication
      if (filters.publishStates) {
        const operator =
          filters.publishStates.operator === "inclus" ? "in" : "nin";
        apiFilters[`publishState[${operator}]`] =
          filters.publishStates.values.join(",");
      }

      // Marques
      if (filters.brands) {
        const operator = filters.brands.operator === "inclus" ? "in" : "nin";
        apiFilters[`brand[${operator}]`] = filters.brands.values.join(",");
      }

      return apiFilters;
    },
  },

  created() {
    const query = this.$route.query;

    this.pagination.currentPage = parseInt(query.page) || 1;
    this.pagination.itemsPerPage =
      parseInt(query.limit) || this.pagination.itemsPerPage;
    this.activeFilters = query.filters ? JSON.parse(query.filters) : {};
    this.currentTab = query.tab || "all";

    this.loadTableData(this.activeFilters);
  },
};
