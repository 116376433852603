<template>
  <div class="custom-tooltip-wrapper">
    <div
      class="tooltip-trigger"
      @mouseenter="showTooltip"
      @mouseleave="hideTooltip"
      ref="trigger"
    >
      <slot></slot>
    </div>
    <transition name="tooltip-fade">
      <div v-if="show && !disabled" class="tooltip-content" :style="tooltipStyle">
        <div class="tooltip-inner">
          <div class="tooltip-header">{{ title }}</div>
          <div class="tooltip-body">
            <slot name="content"></slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "CustomTooltip",
  props: {
    title: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false,
      tooltipPosition: {
        top: 0,
        left: 0,
      },
    };
  },
  computed: {
    tooltipStyle() {
      return {
        top: `${this.tooltipPosition.top}px`,
        left: `${this.tooltipPosition.left}px`,
        transform: "translateX(-50%)", // Pour centrer horizontalement par rapport à la position calculée
      };
    },
  },
  methods: {
    calculatePosition() {
      const trigger = this.$refs.trigger;
      if (!trigger) return;
      const rect = trigger.getBoundingClientRect();
      // Position initiale : centré horizontalement et 8px en dessous du trigger
      let left = rect.left + rect.width / 2;
      let top = rect.bottom + 8;
      const margin = 8; // marge minimale par rapport au viewport

      // Utiliser $nextTick pour s'assurer que le tooltip est rendu
      this.$nextTick(() => {
        const tooltipEl = this.$el.querySelector(".tooltip-content");
        if (!tooltipEl) return;
        const tooltipRect = tooltipEl.getBoundingClientRect();

        // Ajustement horizontal :
        // left représente le centre du tooltip. On vérifie que l'élément ne déborde pas à gauche ou à droite.
        if (left - tooltipRect.width / 2 < margin) {
          left = tooltipRect.width / 2 + margin;
        } else if (left + tooltipRect.width / 2 > window.innerWidth - margin) {
          left = window.innerWidth - tooltipRect.width / 2 - margin;
        }

        // Ajustement vertical :
        // Si l'espace sous le trigger est insuffisant pour afficher le tooltip, on le place au-dessus.
        if (top + tooltipRect.height > window.innerHeight - margin) {
          top = rect.top - tooltipRect.height - 8;
          if (top < margin) {
            top = margin;
          }
        }

        // Mettre à jour la position calculée
        this.tooltipPosition = { top, left };
      });
    },
    showTooltip() {
      this.show = true;
      this.$nextTick(() => {
        this.calculatePosition();
      });
    },
    hideTooltip() {
      this.show = false;
    },
  },
};
</script>

<style scoped>
.custom-tooltip-wrapper {
  position: relative;
  display: inline-block;
}

.tooltip-content {
  position: fixed;
  z-index: 1000;
  pointer-events: none;
  min-width: max-content;
}

/* Transition pour l'apparition/disparition */
.tooltip-fade-enter-active,
.tooltip-fade-leave-active {
  transition: opacity 0.2s, transform 0.2s;
}
.tooltip-fade-enter-from,
.tooltip-fade-leave-to {
  opacity: 0;
  transform: translateX(-50%) translateY(10px);
}

.tooltip-inner {
  background: #fff;
  color: #000;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
  font-size: 12px;
  line-height: 1.4;
  border: 1px solid #f0f0f0;
  overflow: hidden;
}

.tooltip-header {
  padding: 12px 16px;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  font-weight: 500;
  color: #666;
}

.tooltip-body {
  padding: 12px 16px;
}

/* Scrollbar styling */
.tooltip-body::-webkit-scrollbar {
  width: 4px;
}

.tooltip-body::-webkit-scrollbar-track {
  background: #f5f5f5;
}

.tooltip-body::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 2px;
}
</style>
