<template>
  <div class="modal-overlay" @click="closeModal">
    <div class="modal-content" @click.stop>
      <div class="modal-header">
        <div class="header-content">
          <h3>{{ title }}</h3>
          <p v-if="subtitle" class="subtitle">{{ subtitle }}</p>
        </div>
        <button class="modal-close" @click="closeModal">
          <SvgIcon name="cross" />
        </button>
      </div>

      <div class="modal-body">
        <!-- Selected Items Section -->
        <div class="selected-products" v-if="selectedItems.length">
          <div class="selected-products__header">
            <span class="selected-products__count">
              {{ selectedItems.length }}
              {{
                $tc(
                  "article_put_online.fix_action_bar.item_selected",
                  selectedItems.length
                )
              }}
            </span>
          </div>
          <div class="selected-products__list">
            <div
              v-for="item in selectedItems"
              :key="item.id"
              class="selected-products__item"
            >
              {{ item.reference }}
            </div>
          </div>
        </div>

        <!-- Main Content -->
        <slot name="content" v-bind="{ handleSubmit }"></slot>
      </div>

      <div class="modal-footer">
        <button class="btn-secondary" @click="closeModal">
          {{ cancelText }}
        </button>
        <button
          class="btn-primary"
          @click="handleFormSubmit"
          :disabled="submitDisabled"
        >
          {{ submitText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "../svg/SvgIcon.vue";

export default {
  name: "BaseModal",
  components: {
    SvgIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: "",
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    submitDisabled: {
      type: Boolean,
      default: false,
    },
    cancelText: {
      type: String,
      default: "Annuler",
    },
    submitText: {
      type: String,
      default: "Appliquer",
    },
  },

  methods: {
    closeModal() {
      this.$emit("close");
    },

    handleSubmit() {
      console.log("Modal submit clicked");
      // Propager l'appel au slot
      this.$slots.content[0].componentInstance.handleSubmit();
    },

    handleFormSubmit() {
      // Si nous avons besoin des IDs pour la soumission
      const selectedIds = this.selectedItems.map((item) => item.id);

      const formComponent = this.$children.find(
        (child) => child.$options.name === "ReportProblemForm"
      );
      if (formComponent) {
        formComponent.handleSubmit();
      } else {
        this.$emit("reset-selection"); 
        this.$emit("submit", selectedIds);
        this.$emit("close");
      }
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
}

.modal-content {
  background: white;
  border-radius: 8px;
  width: 100%;
  max-width: 656px;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.modal-header {
  padding: 16px 24px;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: sticky;
  top: 0;
}

.header-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.subtitle {
  font-size: 14px;
  color: #666;
  margin: 0;
}

.modal-header h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}

.modal-body {
  padding: 24px;
  flex: 1;
  overflow-y: auto;
}

.modal-footer {
  padding: 16px 24px;
  border-top: 1px solid #e0e0e0;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  position: sticky;
  bottom: 0;
}

.selected-products {
  margin-bottom: 24px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 16px;
}

.selected-products__header {
  margin-bottom: 12px;
}

.selected-products__count {
  font-size: 14px;
  color: #666;
}

.selected-products__list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  max-height: 120px;
  overflow-y: auto;
}

.selected-products__item {
  background: #f5f5f5;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  color: #333;
  font-family: monospace;
}

.btn-primary {
  background: #000;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
}

.btn-primary:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.btn-secondary {
  background: white;
  color: #000;
  border: 1px solid #e0e0e0;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
}
.modal-close {
  max-width: 24px;
  cursor: pointer;
}
</style>
