<template>
  <div v-if="!isClient" class="filter-group">
    <div class="filter-group-header">
      <h4>{{ $t("article_put_online.error_to_fix") }}</h4>
      <!-- <div class="filter-operator">
        <button
          v-for="op in operators"
          :key="op.value"
          :class="[
            'operator-btn',
            { active: currentOperator === op.value },
          ]"
          @click="updateOperator(op.value)"
        >
          {{ op.label }}
        </button>
      </div> -->
    </div>
    <div class="search-input-wrapper" v-if="showSearch">
      <input
        type="text"
        v-model="errorSearch"
        :placeholder="$t('article_put_online.placeholder_search_error')"
        class="search-input"
      />
    </div>
    <div class="filter-options scrollable">
      <label
        v-for="error in filteredErrors"
        :key="error"
        class="checkbox-label"
      >
        <input
          type="checkbox"
          :value="error"
          v-model="selectedErrors"
          @change="emitUpdate"
        />
        <span>{{ $t(`article_put_online.errors.${error}`) }}</span>
      </label>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ErrorFilter",
  props: {
    // Valeurs initiales depuis le parent
    value: {
      type: Array,
      default: () => []
    },
    operator: {
      type: String,
      default: "inclus"
    },
    operators: {
      type: Array,
      default: () => [
        { value: "inclus", label: "Inclus" },
        { value: "exclus", label: "Exclus" }
      ]
    },
    tableFilters: {
      type: Array,
      default: () => []
    },
    showSearch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      errorSearch: "",
      selectedErrors: [],
      currentOperator: "inclus"
    };
  },
  computed: {
    ...mapGetters(["isClient"]),
    
    availableErrors() {
      if (!this.tableFilters) return [];

      const errorFilter = this.tableFilters.find(
        (filter) => filter.key === "reportingError"
      );
      return errorFilter ? errorFilter.options.map((option) => option.id) : [];
    },
    
    filteredErrors() {
      if (!this.errorSearch) {
        return this.availableErrors;
      }
      
      return this.availableErrors.filter(error => 
        this.$t(`article_put_online.errors.${error}`)
          .toLowerCase()
          .includes(this.errorSearch.toLowerCase())
      );
    }
  },
  created() {
    // Initialisation des valeurs depuis les props
    this.selectedErrors = [...this.value];
    this.currentOperator = this.operator;
  },
  methods: {
    updateOperator(value) {
      this.currentOperator = value;
      this.emitUpdate();
    },
    
    emitUpdate() {
      this.$emit('update', 'error', this.selectedErrors, this.currentOperator);
    }
  },
  watch: {
    value(newVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(this.selectedErrors)) {
        this.selectedErrors = [...newVal];
      }
    },
    operator(newVal) {
      if (newVal !== this.currentOperator) {
        this.currentOperator = newVal;
      }
    }
  }
};
</script>

<style scoped>
.filter-group {
  background: white;
  padding: 20px;
  padding-bottom: 32px;
  border-bottom: 1px solid #e9ecef;
}

.filter-group-header {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter-group-header h4 {
  font-weight: 600;
  color: #1a1a1a;
  font-size: 14px;
}

.filter-operator {
  display: flex;
  gap: 1px;
  background: #e0e0e0;
  border-radius: 4px;
  padding: 1px;
}

.operator-btn {
  padding: 4px 12px;
  font-size: 12px;
  background: white;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
}

.filter-group .operator-btn:first-child {
  border-radius: 4px 0 0 4px;
}

.filter-group .operator-btn:last-child {
  border-radius: 0 4px 4px 0;
}

.filter-group .operator-btn.active {
  background: #000;
  color: white;
}

.search-input-wrapper {
  margin-bottom: 12px;
}

.search-input {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  transition: all 0.2s;
  font-size: 13px;
}

.search-input:focus {
  outline: none;
  border-color: #007bff;
  background: white;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.1);
}

.filter-options {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.filter-options.scrollable {
  max-height: 200px;
  overflow-y: auto;
}

.checkbox-label {
  padding: 8px 12px;
  border-radius: 6px;
  transition: background 0.2s;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  width: 98%;
}

.checkbox-label:hover {
  background: #f9f9f9;
}

.checkbox-label input[type="checkbox"] {
  position: relative;
  width: 18px;
  height: 18px;
  cursor: pointer;
  appearance: none;
  border: 2px solid #e0e0e0;
  border-radius: 4px;
  background: white;
  transition: all 0.2s ease;
}

.checkbox-label input[type="checkbox"]:hover {
  border-color: #000;
}

.checkbox-label input[type="checkbox"]:checked {
  background: #000;
  border-color: #000;
}

.checkbox-label input[type="checkbox"]:checked:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 2px;
  width: 2px;
  height: 7px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkbox-label input[type="checkbox"]:focus {
  outline: none;
}

.checkbox-label input[type="checkbox"]:checked ~ span {
  color: #000;
  font-weight: 500;
}
</style>