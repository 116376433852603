<template>
  <Popin
    ref="confirmationPopin"
    :title="$t('order.refund.confirm_refund')"
    :subtitle="$t('order.refund.confirm_description')"
  >
    <template #content>
      <SvgLoader class="confirmation-refund__loader" v-if="loading"/>
      <div v-else class="confirmation-refund">
        
        <TextSimple class="confirmation-refund__groupTitle" size="xl" weight="medium">{{ $t('order.resume') }}</TextSimple>
        
        <!-- Items -->
        <template v-if="data?.refundItems?.length">
          <div 
            v-for="item in data.refundItems" 
            :key="item.id" 
            class="confirmation-refund__row confirmation-refund__row-sub"
          >
            <TextSimple color="grey">{{ item.name || $t('global.wear') }}</TextSimple>
            <TextSimple>{{ item.refund?.amount | formatPrice }}</TextSimple>
          </div>
        </template>

        <!-- Frais de transport -->
        <template v-if="data?.refundFees?.length">
          <div 
            v-for="fee in data.refundFees" 
            :key="fee.id" 
            class="confirmation-refund__row confirmation-refund__row-sub"
          >
            <TextSimple color="grey">{{ $t(`order.refund.${fee.id}_fee`) }}</TextSimple>
            <TextSimple>{{ fee.amount | formatPrice }}</TextSimple>
          </div>
        </template>
        <br>

        <!-- Total -->
        <div class="confirmation-refund__row confirmation-refund__row--total confirmation-refund__groupTitle">
          <TextSimple size="xl" weight="medium">{{ $t('global.refundTotalTtc')}}</TextSimple>
          <TextSimple size="xl" weight="medium">{{ data?.amount | formatPrice }}</TextSimple>
        </div>

        <template v-if="data?.transactions?.length">
          <div 
            v-for="transaction in data.transactions" 
            :key="transaction.id" 
            class="confirmation-refund__row confirmation-refund__row-sub"
          >
            <TextSimple color="grey">{{ $t('global.refunded_on') }} {{transaction.type}}</TextSimple>
            <TextSimple>{{ transaction.amount | formatPrice }}</TextSimple>
          </div>
        </template>

        <!-- Geste commercial -->
        <template v-if="data?.gesture_amount">
          <br>
          <div class="confirmation-refund__section">
            <div class="confirmation-refund__row confirmation-refund__groupTitle">
              <div>
                <TextSimple size="xl" weight="medium">{{ $t('order.refund.commercial_gesture') }}</TextSimple>
              </div>
              <TextSimple size="xl" weight="medium">{{ data.gesture_amount | formatPrice }}</TextSimple>
            </div>
          </div>
        </template>
      </div>
    </template>

    <template #bottom v-if="!loading">
      <div class="confirmation-refund__actions">
        <Button size="xl" color="grey" @click.native="$emit('cancel')">{{ $t('global.cancel') }}</Button>
        <Button size="xl" @click.native="$emit('confirm')">{{ $t('global.confirm') }}</Button>
      </div>
    </template>
  </Popin>
</template>

<script>
import Popin from '@/components/ui/popin/Popin';
import Button from '@/components/ui/button/Button';
import TextSimple from '@/components/ui/text/TextSimple';
import SvgLoader from '@/components/ui/svg/SvgLoader';

export default {
  name: 'RefundConfirmationPopin',
  components: {
    Popin,
    Button,
    TextSimple,
    SvgLoader
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    open() {
      this.$refs.confirmationPopin.open();
    },
    close() {
      this.$refs.confirmationPopin.close();
    }
  }
};
</script>

<style lang="scss">
.confirmation-refund {
  &__row {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    &-sub {
      margin-top: 5px;
    }

    &--total {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  &__actions {
    display: flex;
    gap: 15px;
  }

  &__loader {
    width: 100px;
    margin: 0 auto;
    display: block;
  }

  &__groupTitle {
    margin-block: 15px 10px;
  }

  &__section {
    margin-top: 15px;
  }
}
</style>