<template>
  <TemplateDetails
    :get-function="getTradeinArticle"
    :data="recom_tradeinArticle"
    :loading="recom_tradeinArticleLoading"
  >
    <PageBack />

    <div v-if="article" class="recom-ts-article">
      <!-- TITRE & SWITCH REPRISE/VENTE -->
      <PageHeader :title="$t('article_cms.title', { id: article.reference })">
        <template v-slot:actions>
          <ButtonLink
            v-if="article?.url"
            :to="article?.url"
            icon="eye"
            size="m"
            color="black"
            >{{ $t("article_cms.see_product") }}</ButtonLink
          >
        </template>
        <template v-slot:text>
          <FieldSwitch
            style="width: fit-content"
            :model="view"
            :title_1="$t('article_cms.view_tradein')"
            value_1="tradein"
            :title_2="$t('article_cms.view_sell')"
            value_2="sell"
            @change="(newView) => $router.replace({ query: { view: newView } })"
          />
        </template>
      </PageHeader>

      <GridContainer>
        <!-- INFORMATION DE L'ARTICLE -->
        <GridCard v-if="view === 'sell'" class="recom-ts-article__sell-table">
          <GridContent>
            <GridHeader :title="$t('article_cms.informations')" />
            <div class="recom-ts-article__sell-table__content">
              <ImagesWithLightbox
                v-if="photos?.length"
                :images="photos"
                with-navigation
              />
              <div class="recom-ts-article__sell-table__infos">
                <TextTitle size="s">{{
                  (article && article.metadata && article.metadata.title) || ""
                }}</TextTitle>
                <SectionTableInfos
                  :object="articleMetadataObject"
                  :fields="metadataFields"
                />
                <Collapse
                  v-if="article.metadata?.description?.length"
                  :class="{ 'is-grey': !article.metadata?.composition?.length }"
                  :title="$t('global.description')"
                  :content="article.metadata.description"
                />
                <Collapse
                  v-if="article.metadata?.composition?.length"
                  class="is-grey"
                  :title="$t('article_cms.composition')"
                  :content="article.metadata.composition"
                />
              </div>
            </div>
          </GridContent>
        </GridCard>

        <GridCard
          v-if="view === 'tradein'"
          class="recom-ts-article__tradein-table"
        >
          <GridContent>
            <GridHeader :title="$t('article_cms.informations')" />
            <Table class="recom-ts-article__comparaison">
              <Thead :fields="controlledFields" />
              <Tbody>
                <Row
                  v-for="prop in ['sku', 'color', 'size', 'condition']"
                  :key="prop"
                  :set="(key = `global.${article[prop]}`)"
                >
                  <TableCell
                    v-if="article?.controlReview"
                    type="check"
                    :value="
                      article.initialReview?.[prop] ===
                      article.controlReview?.[prop]
                    "
                    size="xl"
                    class="is-size--xs"
                  />
                  <TableCell
                    :value="$t(`recom_resale.attributes.${prop}`) + ' :'"
                  />
                  <TableCell
                    :set="(key = `global.${article.initialReview?.[prop]}`)"
                    :value="$te(key) ? $t(key) : article.initialReview?.[prop]"
                  />
                  <TableCell
                    :set="
                      (isInvalid =
                        article.initialReview?.[prop] !==
                        article?.controlReview?.[prop])
                    "
                  >
                    <TextSimple
                      v-if="article?.controlReview?.[prop]"
                      :set="(key = `global.${article?.controlReview?.[prop]}`)"
                      :color="isInvalid ? 'red' : null"
                    >
                      {{ $te(key) ? $t(key) : article.controlReview?.[prop] }}
                      <ToolTip
                        v-if="
                          isInvalid && prop === 'condition' && rejectedReason
                        "
                        :infos="rejectedReason"
                      />
                    </TextSimple>
                    <TextSimple v-else></TextSimple>
                  </TableCell>
                </Row>
              </Tbody>
            </Table>
            <ImagesWithLightbox
              class="recom-ts-article__images"
              v-if="article.controlReview?.faultPhotos?.length"
              :images="article.controlReview?.faultPhotos.map((x) => x.url)"
              with-navigation
            />
          </GridContent>
        </GridCard>

        <!-- HISTORIQUE -->
        <GridCard>
          <GridContent>
            <GridHeader
              :title="
                view === 'tradein'
                  ? $t('article_cms.tradein_history')
                  : $t('article_cms.sell_history')
              "
            />
            <GridHistory :dates="history" />
          </GridContent>
        </GridCard>

        <!-- INFORMATIONS GENERALES -->
        <template v-slot:aside>
          <GridCard>
            <GridContent>
              <GridHeader :title="$t('article_cms.general')" />
              <SectionTableInfos
                :object="{
                  ...article,
                  channel_distribution: $t('article.canal.web'),
                }"
                :fields="informationFields"
              />
            </GridContent>
          </GridCard>
        </template>
      </GridContainer>
    </div>
  </TemplateDetails>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PageHeader from "@/components/ui/page/PageHeader";
import Thead from "@/components/ui/table/thead/Thead";
import Row from "@/components/ui/table/row/Row";
import TableCell from "@/components/ui/table/cell/TableCell";
import Table from "@/components/ui/table/Table";
import TemplateDetails from "@/components/templates/TemplateDetails";
import PageBack from "@/components/ui/page/PageBack";
import Tbody from "@/components/ui/table/body/Tbody";
import GridContainer from "@/components/ui/grid/GridContainer";
import GridCard from "@/components/ui/grid/GridCard";
import GridHeader from "@/components/ui/grid/GridHeader";
import GridContent from "@/components/ui/grid/GridContent";
import ButtonLink from "@/components/ui/button/ButtonLink";
import SectionTableInfos from "@/components/sections/SectionTableInfos";
import GridHistory from "@/components/ui/grid/GridHistory";
import FieldSwitch from "@/components/ui/form/fields/FieldSwitch";
import TextSimple from "@/components/ui/text/TextSimple";
import ToolTip from "@/components/ui/tootip/ToolTip.vue";
import ImagesWithLightbox from "@/components/ui/imagesWithLightbox";
import TextTitle from "@/components/ui/text/TextTitle";
import Collapse from "@/components/ui/collapse/Collapse";

export default {
  name: "RecomTradeinArticle",
  components: {
    TextTitle,
    Collapse,
    ImagesWithLightbox,
    ToolTip,
    TextSimple,
    GridHistory,
    FieldSwitch,
    SectionTableInfos,
    ButtonLink,
    GridContent,
    GridHeader,
    GridCard,
    GridContainer,
    Tbody,
    PageBack,
    TemplateDetails,
    Table,
    PageHeader,
    TableCell,
    Row,
    Thead,
  },
  data() {
    return {
      metadataFields: [
        { key: "gender" },
        { key: "category" },
        { key: "type" },
        { key: "season", translation: this.$t("article_cms.season") },
        { key: "ean" },
        { key: "sku" },
        { key: "color", translation: this.$t("article_cms.color") },
        {
          key: "color_filters",
          translation: this.$t("article_cms.color_filters"),
        },
        { key: "size", translation: this.$t("article_cms.size") },
        {
          key: "size_filters",
          translation: this.$t("article_cms.size_filters"),
        },
        { key: "condition", type: "state" },
      ],
    };
  },
  computed: {
    ...mapState(["recom_tradeinArticle", "recom_tradeinArticleLoading"]),
    article() {
      const article = this.recom_tradeinArticle;
      if (!article)
        return {
          metadata: { title: "" },
          initialReview: {},
          controlReview: {},
          photos: [],
          tradeInHistory: [],
          saleHistory: [],
        };

      // Créer un clone pour éviter de modifier l'objet original
      const safeArticle = { ...article };

      // S'assurer que les propriétés existent
      if (!safeArticle.metadata) safeArticle.metadata = { title: "" };
      else if (!safeArticle.metadata.title) safeArticle.metadata.title = "";
      if (!safeArticle.initialReview) safeArticle.initialReview = {};
      if (!safeArticle.controlReview) safeArticle.controlReview = {};
      if (!safeArticle.photos) safeArticle.photos = [];
      if (!safeArticle.tradeInHistory) safeArticle.tradeInHistory = [];
      if (!safeArticle.saleHistory) safeArticle.saleHistory = [];

      return safeArticle;
    },
    articleMetadataObject() {
      if (!this.article) {
        return {};
      }

      const review = this.article.controlReview || this.article.initialReview;
      const condition = review?.condition;

      return {
        ...this.article.metadata,
        sku: review?.sku,
        ean: this.article?.ean,
        condition: condition ? this.$t(`global.${condition}`) : null,
      };
    },
    view() {
      const view = this.$route.query?.view || "tradein";
      return view?.length ? view : "tradein";
    },
    controlledFields() {
      const fields = [
        {},
        { translation: "article_cms.declared" },
        { translation: "article_cms.controlled" },
      ];
      if (this.article?.controlReview) fields.unshift({});
      return fields;
    },
    informationFields() {
      if (this.view === "tradein") {
        return [
          { key: "brand.name", type: "client", translationKey: "brand" },
          {
            key: "customer.email",
            type: "CmsCustomer",
            size: "xl",
            translationKey: "user",
          },
          {
            key: "reference",
            translationKey: "article_id",
            type: "copy",
            route: "recom_resale",
          },
          {
            key: "tradeInCart.reference",
            translationKey: "trade_in_id",
            type: "copy",
            route: "recom_resale",
          },
          {
            key: "warehouse.isStock",
            type: "boolean",
            translationKey: "stocked",
          },
          { key: "state", type: "tag", route: "blue2" },
          {
            key: "warehouse",
            type: "warehouseSlotPosition",
            translationKey: "warehouseSlotPosition",
          },
          {
            key: "tradeInCart.channel",
            type: "channel_trade_in",
            translationKey: "channel_trade_in",
          },
          { key: "priceOrigin", type: "formatPrice", force: true },
          {
            key: "initialReview.price",
            translationKey: "priceOffer",
            type: "formatPrice",
            force: true,
          },
          {
            key: "priceCredit",
            type: "formatPrice",
            translationKey: "itemsCreditedAmount",
            force: true,
          },
        ];
      } else {
        return [
          { key: "brand.name", type: "client", translationKey: "brand" },
          {
            key: "reference",
            translationKey: "id",
            type: "copy",
            route: "recom_resale",
          },
          {
            key: "warehouse.isStock",
            type: "boolean",
            translationKey: "stocked",
          },
          { key: "state", type: "tag", route: "blue2" },
          {
            key: "warehouse",
            type: "warehouseSlotPosition",
            translationKey: "warehouseSlotPosition",
          },
          { key: "channel_distribution", type: "tagType" },
          { key: "priceOrigin", type: "formatPrice", force: true },
          {
            key: "initialReview.price",
            translationKey: "priceOffer",
            type: "formatPrice",
            force: true,
          },
          {
            key: "priceCredit",
            type: "formatPrice",
            translationKey: "itemsCreditedAmount",
            force: true,
          },
          { key: "priceResale", type: "formatPrice" },
        ];
      }
    },
    history() {
      return [
        ...this.article[
          this.view === "tradein" ? "tradeInHistory" : "saleHistory"
        ],
      ].reverse();
    },
    rejectedReason() {
      return this.article?.controlReview?.faultReason;
    },
    rejectedPhotos() {
      return (
        this.article?.photos
          ?.filter((x) => x.type === "fault")
          ?.map((x) => x.url) || []
      );
    },
    photos() {
      return (
        this.article?.photos
          ?.filter((x) => x.type !== "fault")
          ?.map((x) => x.url) || []
      );
    },
  },
  methods: {
    ...mapActions(["getTradeinArticle"]),
  },
};
</script>

<style lang="scss">
.recom-ts-article {
  &__comparaison {
    border: var(--border-1);
    border-radius: var(--border-radius-2);

    .tooltip {
      display: inline-block;

      & > i {
        background-color: #fff;
        color: var(--color-text-red);
        border: solid 1px var(--color-text-red);
        width: 17px;
        height: 17px;
      }
    }

    .text {
      overflow: visible;
    }

    .table-cell,
    .table-head-th {
      &.is-size--xs {
        width: 50px;
        min-width: 50px;
      }
    }

    th:first-of-type,
    th:last-of-type {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    th,
    td {
      text-align: left;
      &:nth-of-type(1) {
        padding-left: 10px !important;
      }
    }
  }

  .images-lightbox {
    .popin__inner {
      min-height: 100px;
      padding: 0;
    }
  }

  .tooltip__infos {
    top: auto;
    left: auto;
    right: -27px;
    bottom: 120%;

    &::after {
      top: auto;
      left: auto;
      right: 20px;
      bottom: -5px;
    }
  }

  &__tradein-table {
    .images-lightbox {
      margin-top: 15px;
    }
  }

  &__sell-table {
    .collapse {
      .title {
        font-size: 1.2rem;
        font-weight: 400;
      }

      svg {
        width: 10px;
      }

      &.is-grey {
        .collapse__heading {
          background-color: var(--color-bg-very-light);
        }
      }

      &__heading {
        border-bottom: solid 1px var(--color-bg-light);
      }
    }

    .images-lightbox__list {
      display: grid;
      gap: 10px;
      grid-template-columns: repeat(2, 1fr);

      @media screen and (min-width: 1024px) and (max-width: 1200px) {
        grid-template-columns: repeat(1, 1fr);
      }
      @media screen and (min-width: 700px) and (max-width: 1023px) {
        grid-template-columns: repeat(3, 1fr);
      }

      img {
        max-width: 100%;
        width: 100%;
      }
    }

    &__infos {
      & > .title {
        margin-bottom: 10px;
      }

      .field-select {
        margin-bottom: 20px;
        width: fit-content;
      }
    }

    &__content {
      @media screen and (min-width: 1024px) {
        display: flex;
        gap: 15px;
        align-items: flex-start;
      }
    }

    .images-lightbox {
      @media screen and (min-width: 1024px) {
        width: 50%;
        flex-shrink: 0;
      }

      @media screen and (max-width: 1023px) {
        margin-bottom: 15px;
      }
    }
  }
}
</style>
