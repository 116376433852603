<template>
    <component 
      class="layout-menu-link"
      exact
      :class="{ 'is-disabled': disabled }"
      :is="componentType"
      :target="href ? '_blank' : null"
      :to="routerLinkTo"
      :href="href">

        <SvgIcon v-if="icon" :name="icon"/>
        <TextSimple :color="disabled ? 'grey' : 'default'">
          {{ $t(`menu.${title}`) }}
        </TextSimple>

    </component>
</template>

<script>
import SvgIcon from '@/components/ui/svg/SvgIcon';
import TextSimple from '@/components/ui/text/TextSimple';
export default {
    name: 'LayoutMenuLink',
    components: {TextSimple, SvgIcon},
    props: {
        title: String,
        href: String,
        icon: String,
        to: String,
        disabled: Boolean
    },
    computed: {
        componentType() {
            if (this.disabled) {
                return 'span';
            }
            return this.to ? 'router-link' : 'a';
        },
        routerLinkTo() {
            if (this.disabled || !this.to) {
                return undefined;
            }
            return { name: this.to };
        }
    }
}
</script>

<style lang="scss">
.layout-menu-link {
    padding: 7px;
    border-radius: var(--border-radius-1);
    display: flex;
    align-items: center;
    transition: background-color ease-out .3s;
    margin-bottom: 5px;
    user-select: none;

    &.is-disabled {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
        
        &:hover {
            background-color: transparent;
        }
    }

    @media screen and (min-width: 1025px) {
        &:hover {
            background-color: var(--color-bg-light);
        }
    }

    .icon + p {
        margin-left: 10px;
    }

    &.router-link-exact-active {
        background-color: var(--color-bg-light);
    }
}
</style>