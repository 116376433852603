<template>
  <div class="toolbar">
    <div class="search-container">
      <input
        type="text"
        v-model="searchValue"
        @keyup.enter="handleSearch"
        :placeholder="placeholder"
        class="search-input"
      />
      <button class="search-icon" @click="handleSearchClick">
        <span v-if="!hasActiveSearch"><SvgIcon name="search" /></span>
        <span v-else>✕</span>
      </button>
    </div>
    <div class="actions">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import SvgIcon from "../svg/SvgIcon.vue";
export default {
  name: "SearchActionsBar",
  components: { SvgIcon },
  props: {
    placeholder: {
      type: String,
      default: "Rechercher...",
    },
    initialSearch: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      searchValue: "",
      hasActiveSearch: false,
    };
  },
  emits: ["search"],
  methods: {
    handleSearch() {
      const trimmedValue = this.searchValue.trim();
      if (!trimmedValue) {
        this.handleSearchClick();
        return;
      }
      this.hasActiveSearch = !!trimmedValue;
      this.$emit("search", trimmedValue);
    },
    handleSearchClick() {
      if (this.hasActiveSearch) {
        this.searchValue = "";
        this.hasActiveSearch = false;
        this.$emit("search", "");
      } else {
        this.handleSearch();
      }
    },
    setSearch(value) {
      this.searchValue = value;
      this.hasActiveSearch = !!value;
    },
  },
  watch: {
    searchValue(newValue) {
      if (!newValue) {
        this.handleSearch();
      }
    },
  },
  created() {
    if (this.initialSearch) {
      this.setSearch(this.initialSearch);
    }
  },
};
</script>

<style lang="scss" scoped>
.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.search-container {
  position: relative;
  flex-grow: 1;
  max-width: 400px;
}

.search-input {
  width: 100%;
  height: 40px;
  padding: 8px 40px 8px 16px;
  border: 1px solid #e0e0e0;
  background-color: #fff;
  border-radius: 4px;

  &:focus {
    outline: none;
    border-color: #2196f3;
  }
}

.search-icon {
  position: absolute;
  right: 1px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: none;
  cursor: pointer;
  padding: 4px;
  color: #666;
  transition: color 0.2s;
  height: 95%;
  width: 45px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  &:hover {
    background-color: #f1efec;
  }
}

.actions {
  display: flex;
  gap: 12px;
}

@media (max-width: 1024px) {
  .search-container {
    min-width: unset;
    flex-grow: 1;
  }
}
</style>
