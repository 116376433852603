<template>
    <TemplateTable
            :title="$t('menu.resales-all')"
            :text="$t('resales.description')"
            name="resales"
            :url="$listRoutes.resales"
            :table="resales"
            :filters="resalesFilters"
            :fields="fields"
    />
</template>

<script>
import { mapState } from 'vuex';
import TemplateTable from '@/components/templates/TemplateTable'
export default {
    name: 'ResalesPage',
    components: {TemplateTable},
    data() {
        return {
            fields: [
                {key: 'id', type: 'eye', route: 'resale', size: 'xs'},
                {key: 'client', type: 'client', size: 'l', noSortable: true},
                {key: 'user', type: 'userEmail', size: 'xl'},
                {key: 'user.type', type: 'tagType', translationKey: 'source'},
                {key: 'shopifyId', type: 'shopifyProduct', size: 'm'},
                 {key: 'sku', type: 'text', size: 'm'},
                {key: 'createdAt', type: 'date'},
                {key: 'tradeinPrice', type: 'formatPrice'},
                // {key: 'logisticsInformations.carrierSendingDate', type: 'date'},
                // {key: 'logisticsInformations.carrierDeliveryDate', type: 'date'},
                {key: 'logisticsInformations.warehouseReceiveDate', type: 'date'},
                {key: 'logisticsInformations.warehouseOpenDate', type: 'date'},
                {key: 'logisticsInformations.warehouseValidationDate', type: 'date'},
                {key: 'logisticsInformations.warehouseShootingDate', type: 'date'},
                {key: 'shopifyPublishedDate', type: 'date'},
                {key: 'shopifyPublished', type: 'boolean'},
                {key: 'inStock', type: 'boolean'},
                {key: 'logisticianCondition', type: 'tagType', noSortable: true},
                {key: 'irl', type: 'boolean'},
                {key: 'realStore', type: 'realStore', size: 'xl'},
                {key: 'tradein.seller.name', label: 'seller', size: 'm'},
                {key: 'country', size: 'xs', noSortable: true},
                //{key: 'postOpening'},
            ]
        }
    },
    computed: {
        ...mapState(['resales', 'resalesFilters'])
    }
}
</script>