<template>
  <div class="refund-element-selection">
    <div class="refund-element-selection__items" :class="{ 'is-disabled': isAllSelected }">
      <!-- Éléments à rembourser (articles ou frais) -->
      <template v-if="elements?.length">
        <div 
          v-for="element in elements" 
          :key="getElementId(element)" 
          class="refund-element-selection__row"
          :class="{'refund-element-selection__row--item': type === 'items'}"
        >
          <FieldCheckbox
            :model="selectedElements[getElementId(element)]"
            @change="(value) => handleElementSelection(getElementId(element), value)"
          />
          
          <!-- Affichage spécifique selon le type (items ou fees) -->
          <template v-if="type === 'items'">
            <RecomOrderCardItem 
              :item="element"
              :to="{name: 'recom_tradein-n-sell-article', params: {id: element.tradeinId}}"
            />
          </template>
          
          <template v-else-if="type === 'fees'">
            <div class="refund-element-selection__row-details">
              <TextSimple weight="medium">{{ $t(`order.refund.${element.type}_fee`) }}</TextSimple>
              <TextSimple size="l" weight="medium">{{ element.amount || null | formatPrice }}</TextSimple>
            </div>
          </template>
          
          <FieldSelect
            :disabled="!selectedElements[getElementId(element)]"
            :options="formattedRefundReasons"
            :selected="elementReasons[getElementId(element)]"
            :label="$t('order.refund_reason')"
            :border="true"
            @input="e => handleElementReasonChange(getElementId(element), e)"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import FieldCheckbox from '@/components/ui/form/fields/FieldCheckbox';
import FieldSelect from '@/components/ui/form/fields/FieldSelect';
import RecomOrderCardItem from '@/components/ui/order/RecomOrderCardItem';
import TextSimple from '@/components/ui/text/TextSimple';

export default {
  name: 'RefundElementSelection',
  components: {
    FieldCheckbox,
    FieldSelect,
    RecomOrderCardItem,
    TextSimple
  },
  props: {
    type: {
      type: String,
      required: true,
      validator: value => ['items', 'fees'].includes(value)
    },
    elements: {
      type: Array,
      default: () => []
    },
    formattedRefundReasons: {
      type: Array,
      default: () => []
    },
    isAllSelected: {
      type: Boolean,
      default: false
    },
    globalReason: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      selectedElements: {},
      elementReasons: {}
    };
  },
  computed: {
    idField() {
      return this.type === 'items' ? 'id' : 'type';
    }
  },
  watch: {
    elements: {
      immediate: true,
      handler(elements) {
        if (elements) {
          // Initialize selection state for new elements
          elements.forEach(element => {
            const id = this.getElementId(element);
            if (this.selectedElements[id] === undefined) {
              this.$set(this.selectedElements, id, false);
            }
          });
        }
      }
    },
    isAllSelected(newVal) {
      if (this.elements) {
        this.elements.forEach(element => {
          const id = this.getElementId(element);
          this.$set(this.selectedElements, id, newVal);
          if (newVal && this.globalReason) {
            this.$set(this.elementReasons, id, this.globalReason);
          }
        });
      }
      this.emitSelectionUpdate();
    },
    globalReason(newVal) {
      if (newVal && this.isAllSelected) {
        this.elements.forEach(element => {
          const id = this.getElementId(element);
          this.$set(this.elementReasons, id, newVal);
        });
        this.emitSelectionUpdate();
      }
    }
  },
  methods: {
    getElementId(element) {
      return element[this.idField];
    },
    handleElementSelection(elementId, value) {
      this.$set(this.selectedElements, elementId, value);
      this.emitSelectionUpdate();
    },
    handleElementReasonChange(elementId, reason) {
      this.$set(this.elementReasons, elementId, reason);
      this.emitSelectionUpdate();
    },
    emitSelectionUpdate() {
      // Émettre un événement standardisé avec le type et les données
      this.$emit('selection-update', {
        type: this.type,
        selections: this.selectedElements,
        reasons: this.elementReasons
      });
    }
  }
};
</script>

<style lang="scss">
.refund-element-selection {
  --select-width: 300px;
  --spacing: 20px;

  &__items {
    &.is-disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    gap: var(--spacing);
    padding: 15px 0;

    .field-select {
      width: var(--select-width);
      margin-left: auto;
    }

    &-details {
      flex: 1;
      display: flex;
      justify-content: space-between;
    }

    &--item {
      .recom-order-card {
        flex: 1;
        margin: 0;
      }
    }
  }
}
</style>