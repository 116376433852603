<template>
  <div class="badges-container">
    <template v-if="channels && channels.length">
      <span
        v-for="channel in channels"
        :key="channel"
        :class="['channel-badge', getChannelClass(channel)]"
      >
        {{ channel }}
      </span>
    </template>
    <span v-else class="empty-value">-</span>
  </div>
</template>

<script>
export default {
  name: "ChannelsCell",

  props: {
    channels: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    getChannelClass(channel) {
      const channelClasses = {
        "Point de vente": "channel-badge--store",
        "boutique en ligne": "channel-badge--online",
        Marketplace: "channel-badge--marketplace",
      };
      return channelClasses[channel] || "";
    },
  },
};
</script>

<style scoped>
.badges-container {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  align-items: center;
}

.channel-badge {
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
}

.channel-badge--store {
  background: #fff3ff;
  color: #b32cb3;
}

.channel-badge--online {
  background: #f3fffd;
  color: #1b847b;
}

.channel-badge--marketplace {
  background: #fff9f3;
  color: #af5c27;
}

.empty-value {
  font-size: 12px;
  color: #9e9e9e;
  margin: 0 auto;
}
</style>
