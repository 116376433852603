<template>
  <div class="photos-manager">
    <draggable
      v-model="localPhotos"
      class="photos-grid"
      :disabled="disabled"
      @end="handleDragEnd"
    >
      <div
        v-for="(photo, index) in localPhotos"
        :key="photo.id"
        class="photo-item"
        :class="{ deleted: photo.deleted }"
      >
        <img
          :src="photo.url"
          :alt="`Photo ${index + 1}`"
          :style="{ opacity: photo.deletedAt ? 0.5 : 1 }"
           @error="handleImageError(index)"
        />
        <div class="photo-actions">
          <button
            class="btn-icon"
            @click="toggleDelete(index)"
            :disabled="disabled"
            :class="{ 'is-delete': photo.deletedAt }"
          >
            <SvgIcon :name="photo.deletedAt ? 'eye' : 'hide'" />
          </button>
        </div>
        <div class="photo-position">{{ index + 1 }}</div>
      </div>
    </draggable>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import draggable from "vuedraggable";
import SvgIcon from "@/components/ui/svg/SvgIcon.vue";

export default {
  name: "PhotosManager",

  components: {
    draggable,
    SvgIcon,
  },

  props: {
    photos: {
      type: Array,
      required: true,
    },
    articleId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      localPhotos: [],
      disabled: false,
    };
  },

  watch: {
    photos: {
      immediate: true,
      handler(newPhotos) {
        if (!this.disabled) {
         
          this.localPhotos = newPhotos.map((photo) => ({
            ...photo,
            url: photo.url,
            id: photo.id,
            position: photo.position,
            deletedAt: photo.deletedAt,
            deleted: !!photo.deletedAt,
          }));
        }
      },
    },
  },

  methods: {
    ...mapActions(["updateArticlePhotos"]),

    async handleDragEnd() {
      await this.updatePhotos();
    },

    setPrimary(index) {
      if (this.localPhotos[index].deleted) return;

      this.localPhotos = this.localPhotos.map((photo, i) => ({
        ...photo,
        isPrimary: i === index,
      }));
      this.updatePhotos();
    },

    toggleDelete(index) {
      const photo = this.localPhotos[index];

      photo.deleted = !photo.deleted;

      const nonDeleted = [];
      const deleted = [];

      this.localPhotos.forEach((p) => {
        if (!p.deleted) {
          nonDeleted.push(p);
        } else {
          deleted.push(p);
        }
      });

      this.localPhotos = [...nonDeleted, ...deleted];
      
      this.updatePhotos();
    },

    async updatePhotos() {
      this.disabled = true;
      try {
        const payload = {
          photos: this.localPhotos.map((photo, index) => ({
            id: photo.id,
            position: index,
            deleted: photo.deleted,
            url: photo.url,
          })),
        };

        const response = await this.updateArticlePhotos({
          id: this.articleId,
          ...payload,
        });
       
        if (response?.photos) {
          this.localPhotos = response.photos.map((photo) => ({
            ...photo,
            url: photo.url, 
            deleted: !!photo.deletedAt,
          }));
          this.$emit("update:photos", this.localPhotos);
        }
      } catch (error) {
        console.error("Error updating photos:", error);
      } finally {
        setTimeout(() => {
          this.disabled = false;
        }, 1000);
      }
    },
    handleImageError(index) {
      // Retire l'image dont le chargement a échoué
      this.localPhotos.splice(index, 1);
    },
  },
};
</script>

<style scoped>
.photos-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 12px;
  margin: 20px 0;
}

.photo-item:first-child {
  grid-column: span 2;
  grid-row: span 2;
}

.photo-item {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  background: white;
  aspect-ratio: 2/3;
  cursor: grab;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 5;
    mix-blend-mode: multiply;
    position: absolute;
    background: #fff;
    background: radial-gradient(circle, #fff, #f5f3f1);
    pointer-events: none;
  }
}

.photo-item.deleted::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -42%;
  width: 188%;
  height: 1px;
  background: #e1e1e1;
  transform: rotate(56deg);
  z-index: 10;
  pointer-events: none;
}

.photo-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease;
}

.photo-actions {
  position: absolute;
  top: 12px;
  right: 12px;
  display: flex;
  gap: 8px;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.photo-item:hover .photo-actions {
  opacity: 1;
}

.btn-icon {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.95);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.btn-icon:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.photo-position {
  position: absolute;
  bottom: 12px;
  left: 12px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
}

.photos-grid[aria-disabled="true"] {
  opacity: 0.7;
  pointer-events: none;
}

@media (max-width: 1024px) {
  .photos-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .photos-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
