import { render, staticRenderFns } from "./PaginationTable.vue?vue&type=template&id=3a4edf88&scoped=true"
import script from "./PaginationTable.vue?vue&type=script&lang=js"
export * from "./PaginationTable.vue?vue&type=script&lang=js"
import style0 from "./PaginationTable.vue?vue&type=style&index=0&id=3a4edf88&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a4edf88",
  null
  
)

export default component.exports