var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-wrapper"},[_c('div',{staticClass:"table-container"},[_c('table',[_vm._m(0),_c('tbody',_vm._l((10),function(n){return _c('tr',{key:`row-${n}`},[_vm._m(1,true),_vm._m(2,true),_vm._m(3,true),_vm._m(4,true),_vm._m(5,true),_vm._m(6,true),_vm._m(7,true),_vm._m(8,true),_vm._m(9,true),_vm._m(10,true)])}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"checkbox-column"},[_c('div',{staticClass:"skeleton-checkbox"})]),_c('th',{staticClass:"image-column"},[_c('div',{})]),_c('th',{staticClass:"id-column"},[_c('div',{staticClass:"skeleton-header"})]),_c('th',{staticClass:"name-column"},[_c('div',{staticClass:"skeleton-header"})]),_c('th',{staticClass:"brand-column"},[_c('div',{staticClass:"skeleton-header"})]),_c('th',{staticClass:"date-column"},[_c('div',{staticClass:"skeleton-header"})]),_c('th',{staticClass:"date-column"},[_c('div',{staticClass:"skeleton-header"})]),_c('th',{staticClass:"status-column"},[_c('div',{staticClass:"skeleton-header"})]),_c('th',{staticClass:"channels-column"},[_c('div',{staticClass:"skeleton-header"})]),_c('th',{staticClass:"errors-column"},[_c('div',{staticClass:"skeleton-header"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"checkbox-column"},[_c('div',{staticClass:"skeleton-checkbox"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"image-column"},[_c('div',{staticClass:"skeleton-image"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"id-column"},[_c('div',{staticClass:"skeleton-text"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"name-column"},[_c('div',{staticClass:"skeleton-text"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"brand-column"},[_c('div',{staticClass:"skeleton-text"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"date-column"},[_c('div',{staticClass:"skeleton-text"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"date-column"},[_c('div',{staticClass:"skeleton-text"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"status-column"},[_c('div',{staticClass:"skeleton-status"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"channels-column"},[_c('div',{staticClass:"skeleton-channels"},[_c('div',{staticClass:"skeleton-status"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"errors-column"},[_c('div',{staticClass:"skeleton-status"})])
}]

export { render, staticRenderFns }