<template>
  <div
    class="popin-notification"
    :class="[color, { 'is-open': isOpen }]"
    @click.prevent="close"
  >
    <div>
      <TextSimple v-if="message">{{ message }}</TextSimple>
      <slot />
    </div>
    <SvgIcon name="cross" />
  </div>
</template>

<script>
import SvgIcon from "@/components/ui/svg/SvgIcon";
import TextSimple from "@/components/ui/text/TextSimple";

export default {
  name: "PopinNotification",
  components: { TextSimple, SvgIcon },
  props: {
    text: [String, Boolean, Object],
    duration: {
      type: Number,
      default: 5000
    }
  },
  data() {
    return {
      isOpen: false,
      timeout: null,
      internalText: null  // État local pour stocker le texte
    };
  },
  computed: {
    message() {
      const currentText = this.internalText || this.text;
      if (!currentText) return null;
      if (typeof currentText === "string") return currentText;
      if (typeof currentText === "object" && currentText.text)
        return currentText.text;
      return null;
    },
    color() {
      const currentText = this.internalText || this.text;
      return currentText && typeof currentText === "object"
        ? currentText.color
        : null;
    },
    confirm() {
      const currentText = this.internalText || this.text;
      return (
        currentText && typeof currentText === "object" && currentText.confirmation
      );
    }
  },
  methods: {
    open(newText) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      
      if (newText) {
        this.internalText = newText;
      }
      
      this.isOpen = true;
      
      if (!this.confirm) {
        const duration = (this.internalText || this.text)?.duration || this.duration;
        this.timeout = setTimeout(() => {
          this.isOpen = false;
          this.internalText = null;
        }, duration);
      }
    },
    close() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.isOpen = false;
      this.internalText = null;
    }
  },
  beforeDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }
};
</script>

<style lang="scss">
.popin-notification {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 100%;
  max-width: 300px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  background-color: #fff;
  box-shadow: var(--box-shadow-1);
  transition: opacity ease-out 0.3s, transform ease-out 0.3s;
  text-align: left;
  border-radius: var(--border-radius-2);
  white-space: normal;
  z-index: 9999;

    &.red {
        color: var(--color-red);
        background-color: var(--color-red-light);
    }
    &.green {
        color: var(--color-green);
        background-color: var(--color-green-light);
    }

  &:not(.is-open) {
    opacity: 0;
    transform: translateY(5px);
    pointer-events: none;
  }

  .icon {
    flex-shrink: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;

    svg {
      width: 12px;
    }
  }
}
</style>
