<template>
  <section class="admin-form">
    <PageBack />

    <PageHeader :title="title">
      <template v-slot:actions>
        <Button
          @click.native="$refs.popin.open()"
          size="l"
          color="grey"
          icon="help"
          >{{ $t("global.help") }}</Button
        >
      </template>
    </PageHeader>

    <Popin :title="$t('admin.create.helper.title')" ref="popin">
      <template v-slot:content>
        <FieldSelect
          :multiple="false"
          :border="true"
          :label="$t('admin.create.helper.role')"
          :selected="helpRole"
          :options="roles"
          @input="(e) => (helpRole = e)"
        />
        <TextSimple>{{ $t(`admin.create.helper.${helpRole}`) }}</TextSimple>
      </template>
    </Popin>

    <form @submit.prevent="submit">
      <img
        class="admin-form__avatar"
        v-if="formData.avatar"
        :src="formData.avatar"
        alt="admin"
      />

      <Field
        v-for="(field, i) in fields"
        :autocomplete="false"
        :model="formData[field.name]"
        :key="i"
        :name="field.name"
        :label="getFieldLabel(field.name, field.required)"
        :type="field.type || 'text'"
        :required="field.required"
        @change="(e) => (formData[field.name] = e)"
      />

      <FieldSelect
        :selected="formData.defaultLocale"
        :label="getFieldLabel('locale', true)"
        :options="locales.map((locale) => ({ id: locale, label: locale }))"
        @input="(e) => (formData.defaultLocale = e)"
      />

      <FieldSelect
        :selected="selectedRolesId"
        :label="getFieldLabel('roles', true)"
        :options="allowedRoles"
        :multiple="true"
        @input="(e) => (selectedRolesId = e)"
      />

      <FieldSelect
        v-if="displayClientSelect"
        :selected="formData.clientIds"
        :label="getFieldLabel('clients', true)"
        :options="clientsOptions"
        :multiple="true"
        @input="(e) => (formData.clientIds = e)"
      />

      <FieldSelect
        v-if="displayStoreSelect && stores?.length"
        :selected="formData.realStoreIds"
        :label="getFieldLabel('stores', true)"
        :options="stores"
        :multiple="true"
        @input="(e) => (formData.realStoreIds = e)"
      />

      <FieldSelect
        v-if="displayWarehouse"
        :selected="
          formData.warehouse?.id ? formData.warehouse?.id : formData.warehouse
        "
        :label="getFieldLabel('warehouse', true)"
        :options="warehousesOptions"
        @input="(e) => (formData.warehouse = e)"
      />

      <ButtonForm :disabled="buttonDisabled" :loading="adminLoading">{{
        $t("global.validate")
      }}</ButtonForm>
    </form>

    <PopinNotification ref="popinSuccess" :text="successMsg" />
    <PopinNotification ref="popinError" :text="errorMsg" />
  </section>
</template>

<script>
import PageBack from "@/components/ui/page/PageBack";
import PageHeader from "@/components/ui/page/PageHeader";
import { mapActions, mapState, mapGetters } from "vuex";
import ButtonForm from "@/components/ui/button/ButtonForm";
import Field from "@/components/ui/form/fields/Field";
import FieldSelect from "@/components/ui/form/fields/FieldSelect";
import ApiAdmin from "@/api/api-admin";
import PopinNotification from "@/components/ui/popin/PopinNotification";
import Button from "@/components/ui/button/Button";
import Popin from "@/components/ui/popin/Popin";
import TextSimple from "@/components/ui/text/TextSimple";

import currencyRouteMixin from "@/mixins/currencyRouteMixin";

export default {
  name: "AdminForm",
  mixins: [currencyRouteMixin],
  components: {
    TextSimple,
    Popin,
    Button,
    PopinNotification,
    FieldSelect,
    Field,
    ButtonForm,
    PageBack,
    PageHeader,
  },
  props: {
    title: String,
    action: Function,
    errorMsg: String,
    successMsg: String,
    model: Object,
    edit: Boolean,
  },
  data() {
    return {
      helpRole: "clientManager",
      selectedRolesId: [],
      clients: [],
      roles: [
        {
          id: "clientManager",
          label: this.$t("roles.clientManager"),
          client: true,
          roles: ["ROLE_CLIENT", "ROLE_BRAND_ADMIN"],
        },
        {
          id: "clientFinance",
          client: true,
          label: this.$t("roles.clientFinance"),
          roles: ["ROLE_BRAND_FINANCE"],
        },
        {
          id: "clientSav",
          client: true,
          label: this.$t("roles.clientSav"),
          roles: ["ROLE_BRAND_SAV"],
        },
        {
          id: "storeManager",
          client: true,
          store: true,
          label: this.$t("roles.storeManager"),
          roles: ["ROLE_SUPER_STORE"],
        },
        {
          id: "store",
          client: true,
          store: true,
          label: this.$t("roles.store"),
          roles: ["ROLE_STORE", "ROLE_BRAND_STORE"],
        },
        {
          id: "logistician",
          warehouse: true,
          label: this.$t("roles.logisticien"),
          roles: ["ROLE_LOGISTICIAN", "ROLE_WAREHOUSE_ADMIN"],
        },
      ],
      stores: null,
      locales: ["fr_FR", "en_GB"],
      formData: {
        email: null,
        password: null,
        warehouse: null,
        roles: [],
        clientIds: [],
        realStoreIds: [],
        defaultLocale: [],
        firstname: null,
        lastname: null,
        avatar: null,
        organization: null,
        chatbotKey: null,
      },
      fields: [
        { name: "avatar" },
        { name: "firstname", required: true },
        { name: "lastname", required: true },
        { name: "email", type: "email", required: true },
        { name: "password", type: "password", required: true },
        { name: "chatbotKey" },
      ],
    };
  },
  computed: {
    ...mapState({
      adminLoading: (state) => state.adminLoading,
      warehouses: (state) => state.warehouses,
      adminRoles: (state) => state.adminRoles,
      temporaryUser: (state) => state.temporaryUser,
      economicZoneClients: (state) => state.currency.economicZoneClients,
      loadingEconomicZoneClients: (state) =>
        state.currency.loadingEconomicZoneClients,
    }),
    ...mapGetters(["isAdmin", "currentCurrency"]),
    clientsOptions() {
      return this.economicZoneClients
        ? this.economicZoneClients.map((client) => ({
            id: client.id,
            label: client.name,
          }))
        : [];
    },
    selectedRoles() {
      return this.roles.filter((r) => this.selectedRolesId.includes(r.id));
    },
    formDataWithCurrency() {
      return {
        ...this.formData,
        currency: this.currentCurrency,
      };
    },

    displayClientSelect() {
      if (!this.isAdmin) return false;
      return !!this.selectedRoles.find((r) => r.client);
    },
    displayStoreSelect() {
      return !!this.selectedRoles.find((r) => r.store);
    },
    displayWarehouse() {
      return !!this.selectedRoles.find((r) => r.warehouse);
    },
    buttonDisabled() {
      if (
        !this.formData.roles?.length ||
        !this.formData.email ||
        !this.formData.firstname ||
        !this.formData.lastname
      )
        return true;
      if (!this.edit && !this.formData.password) return true;

     

      // Si des clients sont nécessaires et qu'il n'y a pas de clients dans economicZoneClients, ne pas bloquer
      if (
        this.displayClientSelect &&
        !this.formData.clientIds?.length &&
        this.economicZoneClients?.length > 0
      )
        return true;

      // Même logique pour les magasins
      if (
        this.displayStoreSelect &&
        !this.formData.realStoreIds?.length &&
        this.stores?.length > 0
      )
        return true;

      return false;
    },
    warehousesOptions() {
      return this.warehouses
        ? this.warehouses.map((w) => ({ id: w.id, label: w.name }))
        : [];
    },
    allowedRoles() {
      if (!this.adminRoles) return this.roles;
      return [...this.roles].filter((role) => this.adminRoles[role.id]);
    },
    ...mapGetters(["userClients"]),
  },
  methods: {
    ...mapActions([
      "getWarehouses",
      "getAdminRoles",
      "fetchEconomicZoneClients",
    ]),

    listIncludes(listA, listB) {
      if (!listA || !listA.length || !listB || !listB.length) return false;
      return !!listA.find((x) => listB.includes(x));
    },
    getStores() {
      this.stores = null;
      ApiAdmin.get(
        this.$listRoutes.stores.replace(":id", this.formData.clientIds[0]) +
          "?page=1&limit=200"
      ).then(
        (res) =>
        {
            this.stores = res.data?.items?.map((x) => ({
            id: x.id,
            label: x.name,
          }));
        }
      );
    },
    getFieldLabel(name, required) {
      const title = this.$t(`global.${name}`);
      return required ? `${title} *` : title;
    },
    async submit() {
      if (!this.formData.clientIds.length) {
        try {
          const firstClient = this.economicZoneClients[0];
          if (firstClient) {
            this.formData.clientIds.push(firstClient.id);
          }
        } catch (error) {
          console.warn("No clientIds provided and no fallback clients found");
          this.$refs.popinError.open();
          return;
        }
      }

      try {
        const admin = await this.action({ ...this.formDataWithCurrency });
        this.$refs.popinSuccess.open();
        this.$emit("next", admin);
      } catch (error) {
        this.$refs.popinError.open();
      }
    },
  },
  watch: {
    currentCurrency: {
      immediate: true,
      handler(newCurrency) {
        if (newCurrency) {
          this.fetchEconomicZoneClients(newCurrency);
          this.formData.clientIds = [];
        }
      },
    },
    economicZoneClients: {
      handler(newClients) {
        if (newClients?.length === 1 && !this.formData.clientIds.length) {
          this.formData.clientIds = [newClients[0].id];
        }
      },
      immediate: true,
    },
    selectedRolesId(listId) {
      let roles = [];
      this.roles.forEach((role) => {
        if (listId.includes(role.id)) roles = [...roles, ...role.roles];
      });
      this.formData.roles = roles;
    },
    "formData.warehouse": {
      handler(warehouse) {
        this.formData.organization = warehouse
          ? this.warehousesOptions.find((c) => c.id == warehouse)?.label
          : null;
      },
      deep: true,
      immediate: true,
    },
    "formData.clientIds": {
      handler(list) {
        this.formData.realStoreIds = [];
        if (list && list.length) {
          const client = this.economicZoneClients?.find((c) => c.id == list[0]);
          if (client) {
            this.formData.organization = client.organization || client.name;
            this.getStores();
          }
        }
      },
      deep: true,
      immediate: true,
    },
    "formData.roles": {
      handler(list) {
        const roles = this.roles.filter((role) =>
          role.roles.find((r) => list.includes(r))
        );
        if (!roles.find((r) => r.warehouse)) this.formData.warehouse = null;
        if (!roles.find((r) => r.client)) {
          this.formData.clientIds = [];
        }
        if (!roles.find((r) => r.store)) this.formData.realStoreIds = [];
      },
      deep: true,
      immediate: true,
    },
    displayClientSelect(value) {
      if (!value) {
        this.formData.clientIds = [];
      }
    },
    temporaryUser() {
      this.getAdminRoles();
    },
  },
  beforeMount() {
    if (this.edit)
      this.fields = this.fields.filter((f) => f.type !== "password");
  },
  async mounted() {
    if (this.currentCurrency) {
      await this.fetchEconomicZoneClients(this.currentCurrency);
    }
    this.getAdminRoles();
    if (!this.warehouses) this.getWarehouses();
    if (this.model) {
      Object.keys(this.formData).forEach((key) => {
        if (this.model[key]) this.formData[key] = this.model[key];
      });
      let roles = [];
      this.roles.forEach((role) => {
        if (role.roles.find((r) => this.formData.roles.includes(r)))
          roles.push(role.id);
      });
      this.selectedRolesId = roles;
      this.formData.clientIds = this.model.clients.map((client) => client.id);
    } else {
      if (!this.isAdmin) {
        this.formData.organization = this.userClients[0]?.name;
      }
    }
  },
};
</script>

<style lang="scss">
.admin-form {
  padding-bottom: 300px;

  &__avatar {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    max-width: 300px;
    border-radius: var(--border-radius-2);
  }

  .popin {
    .field-select {
      margin-bottom: 15px;

      & + p {
        white-space: break-spaces;
        line-height: 2;
      }
    }

    &__inner {
      min-height: 600px;
    }
  }
}
</style>
