<template>
  <div class="table-wrapper">
    <!-- Header Slot -->
    <slot name="header"></slot>

    <!-- Actions Section -->
    <div class="actions-wrapper">
      <div v-if="tabs && tabs.length" class="tabs-wrapper">
        <TabsTable
          v-if="tabs && tabs.length"
          :value="localTab"
          :tabs="tabs"
          :counters="tabCounters"
          @update:counters="handleCountersUpdate"
          @input="handleTabChange"
        />
      </div>
      <slot name="actions">
        <SearchActionsBar
          ref="searchBar"
          v-if="showSearch"
          :placeholder="searchPlaceholder"
          :initial-search="$route.query.reference || ''"
          @search="handleSearchEvent"
        >
          <template #default>
            <slot name="filters"></slot>
          </template>
        </SearchActionsBar>
      </slot>
    </div>

    <!-- Active Filter Tags - Nouvelle position -->
    <div v-if="activeFilterTags.length" class="active-filters">
      <TransitionGroup name="tag" tag="div" class="filter-tags">
        <div
          v-for="tag in translatedFilterTags"
          :key="tag.id"
          :class="['filter-tag', `filter-tag--${tag.type}`]"
          @click="handleTagClick(tag, $event)"
        >
          <span class="tag-operator">
            {{ tag.operator }}
          </span>
          <span class="tag-content">
            <span class="tag-label">{{ tag.label }}</span>
            <span v-if="tag.value" class="tag-value">{{ tag.value }}</span>
          </span>
          <button @click.stop="removeFilterTag(tag)" class="tag-remove">
            ×
          </button>

          <FilterTagPopup
            v-if="tag.id === activePopup"
            :type="tag.type"
            :title="getPopupTitle(tag.type)"
            :options="getOptionsForType(tag.type)"
            :current-values="Array.isArray(tag.values) ? tag.values : []"
            :current-value="tag.value"
            :current-operator="tag.operator"
            :operators="getOperatorsForType(tag.type)"
            @change="handlePopupChange($event, tag)"
            @close="closePopup(tag.id)"
          />
        </div>
      </TransitionGroup>
    </div>

    <!-- Skeleton -->
    <TableSkeleton v-if="loading" />

    <!-- Table Content -->
    <template v-else>
      <!-- Table Container -->
      <div class="table-container">
        <table v-if="processedItems.length > 0">
          <thead>
            <tr>
              <!-- Checkbox Column -->
              <th v-if="selectable" class="checkbox-column">
                <input
                  type="checkbox"
                  v-model="selectAllModel"
                  @change="handleSelectAll"
                  class="checkbox"
                />
              </th>

              <!-- Dynamic Columns -->
              <th
                v-for="column in columns"
                :key="column.key"
                :class="[
                  'table-header',
                  column.class,
                  { 'sortable-column': column.sortable },
                ]"
              >
                <div class="column-header">
                  {{ column.label }}
                  <!-- Sort indicator -->
                  <!-- //ADD @click="column.sortable && handleSort(column.key)" IN th table-header -->
                  <!-- <span v-if="column.sortable" class="sort-indicator">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="16px"
                      viewBox="0 -960 960 960"
                      width="16px"
                      class="sort-icon"
                    >
                      
                      <path
                        :class="[
                          'chevron-up',
                          {
                            active:
                              sortKey === column.key && sortOrder === 'asc',
                            inactive:
                              sortKey === column.key && sortOrder === 'desc',
                          },
                        ]"
                        d="M358-598l-58-58 180-180 180 180-58 58-122-122-122 122Z"
                      />
                      <path
                        :class="[
                          'chevron-down',
                          {
                            active:
                              sortKey === column.key && sortOrder === 'desc',
                            inactive:
                              sortKey === column.key && sortOrder === 'asc',
                          },
                        ]"
                        d="M480-120 300-300l58-58 122 122 122-122 58 58-180 180Z"
                      />
                    </svg>
                  </span> -->
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="item in processedItems"
              :key="item[itemKey]"
              :class="['table-row', { clickable: rowClickable }]"
              @click="handleRowClick(item, $event)"
            >
              <!-- Checkbox Cell -->
              <td v-if="selectable" @click.stop class="checkbox-column">
                <input
                  type="checkbox"
                  v-model="selectedItems"
                  :value="item[itemKey]"
                  class="checkbox"
                />
              </td>

              <!-- Dynamic Cells -->
              <td
                v-for="column in columns"
                :key="column.key"
                :class="[
                  column.cellClass,
                  { 'reference-cell': column.key === 'reference' },
                ]"
              >
                <slot
                  :name="'cell-' + column.key"
                  :item="item"
                  :value="item[column.key]"
                >
                  <!-- Pour la colonne reference, on affiche le texte et le bouton icône -->
                  <template v-if="column.key === 'reference'">
                    <div class="reference-container">
                      <span class="reference-text">{{ item[column.key] }}</span>
                      <button
                        class="copy-button"
                        @click.stop="copyToClipboard(item[column.key])"
                      >
                        <SvgIcon name="copy" />
                      </button>
                    </div>
                  </template>
                  <!-- Sinon, affichage classique -->
                  <template v-else>
                    {{ item[column.key] }}
                  </template>
                </slot>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Empty State -->
        <div v-else class="no-results">
          <slot name="empty">
            <p>{{ $t("article_put_online.table.empty") }}</p>
          </slot>
        </div>
      </div>

      <!-- Pagination -->
      <slot name="pagination">
        <PaginationTable
          v-if="showPagination"
          :current-page="currentPage"
          :total-pages="totalPages"
          :total-items="totalItems"
          :items-per-page="itemsPerPage"
          @page-change="handlePageChange"
          @items-per-page-change="handleItemsPerPageChange"
        />
      </slot>
    </template>
    <!-- Fixed Action Bar -->
    <slot v-if="!isClient" name="fixed-action-bar">
      <FixedActionBar
        v-if="selectable && selectedItems.length > 0"
        :selected-count="selectedItems.length"
        @clear="clearSelection"
      >
        <slot name="selected-actions">
          <div class="action-buttons">
            <div class="action-button-wrapper" v-if="showStatusButton">
              <button
                class="btn-action"
                @click="toggleStatusPopover"
                ref="statusButton"
              >
                {{ $t("article_put_online.change_status") }}
              </button>

              <StatusPopover
                v-if="showStatusPopover"
                :options="availableStatuses"
                :current-tab="localTab"
                @select="handleStatusSelect"
                v-click-outside="closeStatusPopover"
              />
            </div>

            <!-- <div class="action-button-wrapper">
              <button
                class="btn-action"
                @click="toggleChannelPopover"
                ref="channelButton"
              >
                Changer de canal de vente
              </button>

              <StatusPopover
                v-if="showChannelPopover"
                :options="availableChannels"
                @select="handleChannelSelect"
                v-click-outside="closeChannelPopover"
              />
            </div> -->
          </div>
        </slot>
      </FixedActionBar>
    </slot>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";
import dateFormatMixin from "@/mixins/dateFormatMixin";
import moment from "moment";
// import { debounce } from "lodash";
import TableSkeleton from "@/components/ui/dataTable/TableSkeleton.vue";
import TabsTable from "@/components/ui/dataTable/TabsTable.vue";
import SearchActionsBar from "@/components/ui/dataTable/SearchActionsBar.vue";
import PaginationTable from "@/components/ui/dataTable/PaginationTable.vue";
import FixedActionBar from "@/components/ui/dataTable/FixedActionBar.vue";
import FilterTagPopup from "@/components/ui/dataTable/FilterTagPopup.vue";
import StatusPopover from "@/components/ui/dataTable/StatusPopover.vue";
import SvgIcon from "@/components/ui/svg/SvgIcon.vue";
import { mapGetters } from "vuex";

export default {
  name: "BaseTable",
  mixins: [dateFormatMixin],
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    TabsTable,
    SearchActionsBar,
    PaginationTable,
    FixedActionBar,
    TableSkeleton,
    FilterTagPopup,
    StatusPopover,
    SvgIcon,
  },
  model: {
    prop: "tabValue",
    event: "changeTab",
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    // Data
    items: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    itemKey: {
      type: String,
      default: "id",
    },

    // Features flags
    selectable: {
      type: Boolean,
      default: false,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    rowClickable: {
      type: Boolean,
      default: false,
    },

    // Search
    searchPlaceholder: {
      type: String,
      default: "Search...",
    },
    searchFields: {
      type: Array,
      default: () => [],
    },

    // Pagination
    currentPage: {
      type: Number,
      default: 1,
    },
    totalPages: {
      type: Number,
      default: 1,
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },

    // Tabs
    tabs: {
      type: Array,
      default: () => [],
    },
    tabCounters: {
      type: Object,
      required: true, // Changement ici
    },
    tabValue: {
      type: String,
      default: "all",
    },

    activeFilterTags: {
      type: Array,
      default: () => [],
    },

    // Popup
    tableFilters: {
      type: Array,
      default: () => [],
    },
    publishStateLabels: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      localSearchQuery: "",
      selectedItems: [],
      sortKey: null,
      sortOrder: "asc",
      localTab: this.tabValue,
      activePopup: null,
      operators: [
        { value: "inclus", label: "Inclus" },
        { value: "exclus", label: "Exclus" },
      ],
      availableStatuses: [
        { id: "to_publish", name: "À publier" },
        { id: "on_sale", name: "En vente" },
        { id: "to_correct", name: "À corriger" },
        { id: "draft", name: "Brouillon" },
        { id: "unpublished", name: "Dépublié" },
      ],
      availableChannels: [
        { id: "store", name: "Point de vente" },
        { id: "online", name: "Boutique en ligne" },
        { id: "marketplace", name: "Marketplace" },
      ],
      showStatusPopover: false,
      showChannelPopover: false,
    };
  },

  computed: {
    ...mapGetters(["isClient"]),
    selectAllModel: {
      get() {
        return (
          this.processedItems.length > 0 &&
          this.selectedItems.length === this.processedItems.length
        );
      },
      set(value) {
        this.selectedItems = value
          ? this.processedItems.map((item) => item[this.itemKey])
          : [];
      },
    },

    processedItems() {
      let result = [...this.items];

      if (this.$route.query.reference) {
        return result;
      }
      // Apply search
      if (this.localSearchQuery && this.searchFields.length > 0) {
        const query = this.localSearchQuery.toLowerCase();
        result = result.filter((item) =>
          this.searchFields.some((field) => {
            const value = item[field];
            return value != null && String(value).toLowerCase().includes(query);
          })
        );
      }

      // Apply sorting
      if (this.sortKey) {
        result.sort((a, b) => {
          const modifier = this.sortOrder === "asc" ? 1 : -1;
          const aVal = a[this.sortKey];
          const bVal = b[this.sortKey];

          if (aVal < bVal) return -1 * modifier;
          if (aVal > bVal) return 1 * modifier;
          return 0;
        });
      }

      return result;
    },

    showStatusButton() {
      switch (this.localTab) {
        case "on_sale":
        case "to_correct":
          return true;
        case "all":
        case "to_publish":
        default:
          return false;
      }
    },
    translatedFilterTags() {
      return this.activeFilterTags.map((tag) => {
        const translatedTag = { ...tag };

        // Traduire l'opérateur
        translatedTag.operator = this.$t(
          `article_put_online.operator.${tag.operator}`
        );

        switch (tag.type) {
          case "brand":
            translatedTag.operator = this.$t("article_put_online.table.brand"); // Par exemple, "Marque"

            if (tag.values.length === 1) {
              translatedTag.label = this.getBrandLabel(tag.values[0]);
            } else {
              translatedTag.label = `(${tag.values.length})`;
            }
            break;

          case "publishState":
            // Ici, on définit l'opérateur sur "Statut"
            translatedTag.operator = this.$t(
              "article_put_online.operator.statut"
            );
            if (tag.values.length === 1) {
              // Le label affiche uniquement la valeur traduite (ex: "En vente")
              translatedTag.label = this.$t(
                `article_put_online.tabs.${tag.values[0]}`
              );
            } else {
              translatedTag.label = `(${tag.values.length})`;
            }
            break;
          case "error":
            // Ici, on définit l'opérateur sur "Errors"
            translatedTag.operator = this.$t(
              "article_put_online.operator.errors"
            );
            if (tag.values.length === 1) {
              translatedTag.label = this.$t(
                `article_put_online.errors.${tag.values[0]}`
              );
            } else {
              translatedTag.label = `(${tag.values.length})`;
            }
            break;
          case "stockDate":
          case "publicationDate":
            translatedTag.label = this.$t(`article_put_online.${tag.type}`);

            if (tag.value) {
              // Vérifier si c'est une plage de dates
              if (tag.value.indexOf(" - ") > -1) {
                const [date1, date2] = tag.value.split(" - ");

                // Parser les dates (qu'elles soient en DD/MM/YY ou YYYY-MM-DD)
                const parsedDate1 = moment(date1, ["DD/MM/YY", "YYYY-MM-DD"]);
                const parsedDate2 = moment(date2, ["DD/MM/YY", "YYYY-MM-DD"]);

                // Reformater selon la locale
                translatedTag.value = `${this.formatDateForDisplay(
                  parsedDate1.format("YYYY-MM-DD")
                )} → ${this.formatDateForDisplay(
                  parsedDate2.format("YYYY-MM-DD")
                )}`;
                translatedTag.operator = this.$t(
                  "article_put_online.operator.between"
                );
              } else {
                // Pour une seule date
                const parsedDate = moment(tag.value, [
                  "DD/MM/YY",
                  "YYYY-MM-DD",
                ]);
                translatedTag.value = this.formatDateForDisplay(
                  parsedDate.format("YYYY-MM-DD")
                );
              }
            }
            break;
          default:
            translatedTag.label = tag.label;
        }
        return translatedTag;
      });
    },
  },

  methods: {
    resetSelection() {
      this.selectedItems = [];
      this.$emit("selection-change", []);
    },
    handleCountersUpdate(newCounters) {
      this.$emit("update:tab-counters", newCounters);
    },
    handleSearchEvent(value) {
      this.$emit("search", value);
      this.localSearchQuery = value; // Ajoutez cette ligne
    },

    handleTabChange(tabId) {
      if (this.localTab === tabId) return;
      this.localTab = tabId;
      this.$emit("changeTab", tabId);
    },

    handleSort(key) {
      if (this.sortKey === key) {
        this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      } else {
        this.sortKey = key;
        this.sortOrder = "asc";
      }
      this.$emit("sort", { key, order: this.sortOrder });
    },

    handleSelectAll() {
      this.$emit("select-all", this.selectAllModel);
    },

    handleRowClick(item, event) {
      if (!this.rowClickable) return;

      // Ne pas naviguer si on clique sur la référence ou la checkbox
      if (event.target.classList.contains("checkbox")) {
        return;
      }

      this.$emit("row-click", item);
    },

    handlePageChange(page) {
      this.$emit("page-change", page);
    },

    handleItemsPerPageChange(value) {
      this.$emit("items-per-page-change", value);
    },

    clearSelection() {
      this.selectedItems = [];
      this.$emit("selection-clear");
    },

    removeFilterTag(tag) {
      this.$emit("remove-filter-tag", tag);
    },

    getPopupTitle(type) {
      const titles = {
        brand: "Marques",
        publishState: "État de publication spécifique",
        stockDate: "Date de mise en stock",
        publicationDate: "Date de publication",
        error: "Erreurs",
      };
      return titles[type] || type;
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
      // Optional: Show feedback
      this.$store.commit("SET_NOTIFICATION_MESSAGE", {
        text: this.$t("article_put_online.notification_popin.id_copied"),
        color: "green",
        confirmation: false,
        duration: 3000,
      });
    },

    getBrandLabel(brandId) {
      const brand = this.tableFilters
        ?.find((f) => f.key === "brand")
        ?.options.find((b) => b.id === Number(brandId));
      return brand?.label || brandId;
    },

    getOperatorsForType(type) {
      if (type === "stockDate" || type === "publicationDate") {
        return [
          { value: "supérieur à", label: "Supérieur à" },
          { value: "inférieur à", label: "Inférieur à" },
        ];
      }
      return this.operators;
    },
    getOptionsForType(type) {
      const filter = this.tableFilters?.find((f) => {
        if (type === "error") {
          return f.key === "reportingError"; // Modification ici
        }
        return f.key === type;
      });
      switch (type) {
        case "brand":
          return filter ? filter.options : [];
        case "publishState":
          return filter
            ? filter.options.map((opt) => ({
                id: opt.id,
                label: this.publishStateLabels[opt.id] || opt.label,
              }))
            : [];
        case "error":
          return filter ? filter.options : [];
        default:
          return [];
      }
    },

    handleTagClick(tag, event) {
      event.stopPropagation();
      this.activePopup = this.activePopup === tag.id ? null : tag.id;
    },

    closePopup(tagId) {
      if (this.activePopup === tagId) {
        this.activePopup = null;
      }
    },
    handlePopupChange(data, tag) {
      if (tag.type === "stockDate" || tag.type === "publicationDate") {
        if (!data.value1 && !data.value2) {
          this.$emit("remove-filter-tag", tag);
          return;
        }

        let newOperator = data.operator;
        if (tag.operator === "Entre" && (!data.value1 || !data.value2)) {
          newOperator = data.value1 ? "supérieur à" : "inférieur à";
        }

        // Les dates restent au format YYYY-MM-DD pour l'API
        // Mais l'affichage est formaté selon la locale
        const displayValue = this.formatDateRange(data.value1, data.value2);

        this.$emit("update-filter", {
          type: tag.type,
          operator: newOperator,
          value1: data.value1, // Format YYYY-MM-DD pour l'API
          value2: data.value2, // Format YYYY-MM-DD pour l'API
          value: displayValue, // Format selon la locale pour l'affichage
        });
      } else {
        this.$emit("update-filter", {
          type: tag.type,
          values: data.values,
          operator: data.operator,
        });
      }
      this.activePopup = null;
    },

    toggleStatusPopover() {
      console.log("Toggle status popover"); // Pour le debug
      this.showStatusPopover = !this.showStatusPopover;
      this.showChannelPopover = false;
    },

    toggleChannelPopover() {
      this.showChannelPopover = !this.showChannelPopover;
      this.showStatusPopover = false;
    },

    closeStatusPopover() {
      this.showStatusPopover = false;
    },

    closeChannelPopover() {
      this.showChannelPopover = false;
    },

    handleStatusSelect(status) {
      console.log("Status selected:", status); // Pour le debug
      this.$emit("status-selected", {
        items: this.selectedItems,
        status: status,
      });
      this.showStatusPopover = false;
    },

    handleChannelSelect(channel) {
      this.selectedChannel = channel;
      this.showChannelPopover = false;
      this.$emit("channel-selected", {
        items: this.selectedItems,
        channel: channel,
      });
    },
    restoreSelection(items) {
      if (items && items.length > 0) {
        this.selectedItems = items;
        this.$emit("selection-change", items);
      }
    },
  },
  created() {
    // Restaure la sélection depuis les paramètres d'URL
    const savedFilters = localStorage.getItem("savedTableFilters");
    if (savedFilters) {
      const { selectedItems } = JSON.parse(savedFilters);
      if (selectedItems && selectedItems.length > 0) {
        this.selectedItems = selectedItems;
        this.$emit("selection-change", selectedItems);
      }
    }
  },

  watch: {
    selectedItems(newValue) {
      this.$emit("selection-change", newValue);
    },
    tabValue(newVal) {
      if (this.localTab !== newVal) {
        this.localTab = newVal;
      }
    },
  },
};
</script>

<style scoped>
.actions-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.table-wrapper {
  width: 100%;
}
.table-container {
  width: 100%;
  font-size: 12px;
  overflow-x: auto;
  background: white;
  border-radius: 8px;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 4px;
  position: relative;
}

/* Personnalisation de la scrollbar pour Chrome, Edge, et Safari */
.table-container::-webkit-scrollbar {
  height: 8px;
}

.table-container::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 4px;
}

.table-container::-webkit-scrollbar-thumb {
  background: #ecebe5;
  border-radius: 4px;
  transition: background 0.3s ease;
}

.table-container::-webkit-scrollbar-thumb:hover {
  background: #ecebe5; /* Couleur au survol */
}

/* Pour Firefox */
.table-container {
  scrollbar-width: thin;
  scrollbar-color: #ecebe5 #fff;
}

/* Assurez-vous que la table prend toute la largeur nécessaire */
table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto; /* Pour adapter les colonnes à leur contenu */
  min-width: 1200px; /* Largeur minimale pour éviter une compression excessive */
}

.table-row {
  transition: background-color 0.2s ease;
}

.table-row:hover td {
  background-color: #f7f7f7;
}

.table-row td:first-of-type {
  padding-left: 16px;
}
.table-row.clickable {
  cursor: pointer;
}

.checkbox {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

th,
td {
  min-width: 80px;
  height: 48px;
  padding: 8px 16px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
  vertical-align: middle;
  white-space: nowrap;
}

/* Distribution des colonnes */
th:nth-child(1),
td:nth-child(1) {
  min-width: 100%;
}
th:nth-child(2),
td:nth-child(2) {
  min-width: 100%;
}

th:nth-child(4),
td:nth-child(4) {
  max-width: 154px;
  overflow: hidden;
}

th:nth-child(9),
td:nth-child(9) {
  min-width: 215px;
}


th {
  background: #f9f9f9;
  font-weight: 500;
  color: #666;
  position: sticky;
  top: 0;
  z-index: 1;
}

.reference-cell {
  position: relative;
  z-index: 1;
}

.reference-container {
  display: flex;
  align-items: center;
}

.copy-button {
  height: 26px;
  width: auto;
  cursor: pointer;
  border-radius: 99px;
  .icon {
    max-width: 14px;
    opacity: 0.5;
  }
}
.reference-text {
  min-width: 125px;
}
.reference-cell .copy-button:hover {
  background-color: #e3e3e3;

  .icon {
    opacity: 1;
  }
}
.sortable-column {
  /* cursor: pointer; ADD WHEN IS OK*/
  user-select: none;
}

.column-header {
  display: flex;
  align-items: center;
  gap: 8px;
}

.sort-indicator {
  color: #2196f3;
}

.no-results {
  padding: 40px 0;
  text-align: center;
  color: #666;
  font-size: 14px;
}
.active-filters {
  margin: 16px 0 24px;
}

.filter-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.filter-tag {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  background: white;
  border-radius: 99px;
  font-size: 13px;
  border: 1px solid #e0e0e0;
  transition: all 0.2s ease;
  cursor: pointer;
}
.filter-tag:hover {
  border: 1px solid #c4bfbf;
}

.tag-operator {
  font-size: 11px;
  color: #666;
  padding: 2px 6px;
  background: #f1efec;
  border-radius: 99px;
}

.tag-content {
  display: flex;
  align-items: center;
  gap: 4px;
}

.tag-count {
  color: #666;
}

.tag-remove {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border: none;
  background: none;
  color: #666;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.2s ease;
  padding: 0;
  font-size: 14px;
}

.tag-remove:hover {
  background: rgba(0, 0, 0, 0.1);
  color: #333;
}

/* Animations */
.tag-enter-active,
.tag-leave-active {
  transition: all 0.3s ease;
}

.tag-enter-from,
.tag-leave-to {
  opacity: 0;
  transform: scale(0.9);
}

/* Types spécifiques de tags */
.filter-tag--channel {
  background: #f8f9fa;
}

.filter-tag--channel[data-value="Point de vente"] {
  background: #fff3ff;
  color: #b32cb3;
  border-color: #b32cb3;
}

.filter-tag--channel[data-value="boutique en ligne"] {
  background: #f3fffd;
  color: #1b847b;
  border-color: #1b847b;
}

.filter-tag--channel[data-value="Marketplace"] {
  background: #fff9f3;
  color: #af5c27;
  border-color: #af5c27;
}
.action-buttons {
  display: flex;
  gap: 12px;
}

.action-button-wrapper {
  position: relative;
}

.btn-action {
  position: relative;
  z-index: 1;
}

.sort-indicator {
  display: flex;
  align-items: center;
}

.sort-icon {
  fill: #666;
}

.chevron-up,
.chevron-down {
  transition: all 0.2s ease;
}

.chevron-up.active,
.chevron-down.active {
  fill: #000;
}

.chevron-up.inactive,
.chevron-down.inactive {
  opacity: 0.3;
}

/* Fixe la colonne de checkbox */
.checkbox-column {
  position: sticky;
  left: 0;
  z-index: 2;
  background: white;
}

/* Ajouter une ombre légère pour indiquer le "fixage" */
.checkbox-column::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 1px;
  background-color: rgb(224 224 224 / 30%);
  pointer-events: none;
}

/* S'assurer que le fond de la case à cocher en-tête reste visible */
th.checkbox-column {
  background: #f9f9f9;
  z-index: 3;
}

td.checkbox-column {
  background: white;
}
/* Style pour le th checkbox */
.checkbox-column .checkbox {
  position: relative;
  width: 18px;
  height: 18px;
  cursor: pointer;
  appearance: none;
  border: 2px solid #e0e0e0;
  border-radius: 4px;
  background: white;
  transition: all 0.2s ease;
}

.checkbox-column .checkbox:hover {
  border-color: #000;
}

.checkbox-column .checkbox:checked {
  background: #000;
  border-color: #000;
}

.checkbox-column .checkbox:checked:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 2px;
  width: 2px;
  height: 7px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Style pour les td checkbox */
.table-row td input[type="checkbox"] {
  position: relative;
  width: 18px;
  height: 18px;
  cursor: pointer;
  appearance: none;
  border: 2px solid #e0e0e0;
  border-radius: 4px;
  background: white;
  transition: all 0.2s ease;
}

.table-row td input[type="checkbox"]:hover {
  border-color: #000;
}

.table-row td input[type="checkbox"]:checked {
  background: #000;
  border-color: #000;
}

.table-row td input[type="checkbox"]:checked:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 2px;
  width: 2px;
  height: 7px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Pour enlever le focus outline par défaut */
.table-row td input[type="checkbox"]:focus,
.checkbox-column .checkbox:focus {
  outline: none;
}
</style>
