import homeRoutes from '@/router/modules/home';
import dashboardRoutes from '@/router/modules/dashboard';
import resalesRoutes from '@/router/modules/resales';
import retailRoutes from '@/router/modules/retail';
import usersRoutes from '@/router/modules/customers';
import warehouseRoutes from '@/router/modules/warehouse';
//import monitoringRoutes from '@/router/modules/monitoring';
import configRoutes from '@/router/modules/config';
import ordersRoutes from '@/router/modules/orders';
//import productsRoutes from '@/router/modules/products';
import promotionsRoutes from "@/router/modules/promotions";
import articlesRoutes from "@/router/modules/articles";
import shippingsRoutes from "@/router/modules/shippings";

const state = {
  menu: [
    {
      ...homeRoutes.find((x) => x.name === "admin-home"),
    },
    {
      ...dashboardRoutes.find((x) => x.name === "dashboard"),
      links: [
        dashboardRoutes.find((x) => x.name === "dashboard-goldenmetrics"),
        dashboardRoutes.find((x) => x.name === "dashboard-operations"),
        dashboardRoutes.find((x) => x.name === "dashboard-orders"),
        dashboardRoutes.find((x) => x.name === "dashboard-resales"),
        dashboardRoutes.find((x) => x.name === "dashboard-stock"),
        dashboardRoutes.find((x) => x.name === "dashboard-performances"),
        // dashboardRoutes.find(x => x.name === 'dashboard-sellers'),
        // dashboardRoutes.find(x => x.name === 'dashboard-buyers'),
        dashboardRoutes.find((x) => x.name === "dashboard-buying-funnel"),
        dashboardRoutes.find((x) => x.name === "dashboard-selling-funnel"),
      ],
    },
    {
      ...ordersRoutes.find((x) => x.name === "orders-all"),
      links: [
        ordersRoutes.find((x) => x.name === "orders"),
        ordersRoutes.find((x) => x.name === "recom_orders"),
        ordersRoutes.find((x) => x.name === "orders-articles"),
        ordersRoutes.find((x) => x.name === "recom_articles"),
        ordersRoutes.find((x) => x.name === "orders-returned"),
        ordersRoutes.find((x) => x.name === "orders-returns-treated"),
        ordersRoutes.find((x) => x.name === "orders-refunded"),
        //  ordersRoutes.find(x => x.name === 'orders-returns'),
      ],
    },
    {
      ...resalesRoutes.find((x) => x.name === "resales"),
      links: [
        resalesRoutes.find((x) => x.name === "resales-all"),
        resalesRoutes.find((x) => x.name === "recom_resales-all"),
        resalesRoutes.find((x) => x.name === "recom_tradein-articles"),
        resalesRoutes.find((x) => x.name === "resales-credited"),
        //resalesRoutes.find(x => x.name === 'resales-waiting-review'),
        //resalesRoutes.find(x => x.name === 'resales-waiting-shooting'),
        //resalesRoutes.find(x => x.name === 'resales-waiting-publication'),
        resalesRoutes.find(
          (x) => x.name === "config-website-catalogues-articles-listing-all-bis"
        ),
        resalesRoutes.find((x) => x.name === "resales-stocks"),
        resalesRoutes.find((x) => x.name === "recom_resales-stocks"),
        resalesRoutes.find((x) => x.name === "resales-bookings"),
        resalesRoutes.find((x) => x.name === "resales-shipping"),
        resalesRoutes.find((x) => x.name === "resales-photos"),
      ],
    },
    /*
        {
            ...productsRoutes.find(x => x.name === 'products'),
            links: [
                productsRoutes.find(x => x.name === 'products'),
                productsRoutes.find(x => x.name === 'collections'),
            ]
        },
         */
    {
      ...retailRoutes.find((x) => x.name === "retail"),
      links: [
        retailRoutes.find((x) => x.name === "retail-stock"),
        retailRoutes.find((x) => x.name === "retail-stock-transfers"),
      ],
    },
    {
      ...usersRoutes.find((x) => x.name === "customers"),
      links: [
        usersRoutes.find((x) => x.name === "customers-all"),
        usersRoutes.find((x) => x.name === "recom_customers-all"),
        usersRoutes.find((x) => x.name === "customers-wallets"),
        usersRoutes.find((x) => x.name === "recom_subscribers"),
      ],
    },
    {
      ...promotionsRoutes.find((x) => x.name === "promotions"),
      links: [
        promotionsRoutes.find((x) => x.name === "vouchers"),
        promotionsRoutes.find((x) => x.name === "recom-vouchers"),
        promotionsRoutes.find((x) => x.name === "recom-promo-codes"),
      ],
    },
    {
      ...warehouseRoutes.find((x) => x.name === "warehouse"),
      links: [
        warehouseRoutes.find((x) => x.name === "warehouse-dashboard"),
        warehouseRoutes.find((x) => x.name === "warehouse-operators"),
        warehouseRoutes.find((x) => x.name === "warehouse-articles"),
        warehouseRoutes.find((x) => x.name === "warehouse-inventory"),
        warehouseRoutes.find((x) => x.name === "warehouse-inventory-refused"),
      ],
    },
    {
      ...configRoutes.find((x) => x.name === "config"),
      links: [configRoutes.find((x) => x.name === "clients")],
    },
      /*
        {
            ...monitoringRoutes.find(x => x.name === 'monitoring'),
            links: [
                monitoringRoutes.find(x => x.name === 'monitoringTests'),
                monitoringRoutes.find(x => x.name === 'monitoringDatas')
            ]
        },
         */
    {
      ...articlesRoutes.find(
        (x) => x.name === "config-website-catalogues-collections-listing"
      ),
    },
    {
      ...shippingsRoutes.find((x) => x.name === "shippings"),
      links: [shippingsRoutes.find((x) => x.name === "shippings")],
    },
  ],
};

const mutations = {};

const actions = {};

const getters = {
  allowedMenu(state) {
    const user = state.user
      ? state[state.temporaryUser ? "temporaryUser" : "user"]
      : {};
    const userRoles = user.roles || [];
    const userCMS = user.cms || [];

    if (!userRoles.length && !userCMS.length) return [];
    let menu = JSON.parse(JSON.stringify(state.menu));

    menu = menu.filter((category) => {
      if (userRoles.includes("ROLE_ADMIN") && category.name !== "shippings") {
        return true;
      }

      if (category.links) {
        // Filter the links based on roles and CMS
        category.links = category.links.filter((link) => {
          const hasLogisticianOrWarehouseAdmin = userRoles.includes("ROLE_LOGISTICIAN") || 
            userRoles.includes("ROLE_WAREHOUSE_ADMIN");
          
          const linkHasRole =
            !link?.meta?.roles ||
            link.meta.roles.some((r) => userRoles.includes(r));
          
          // Si l'utilisateur est LOGISTICIAN ou WAREHOUSE_ADMIN et le lien contient son rôle,
          // ignorer la condition de CMS pour cet utilisateur
          const linkHasCMS = hasLogisticianOrWarehouseAdmin && linkHasRole
            ? true  // Ignorer la vérification de CMS pour ces rôles spécifiques
            : !link?.meta?.cms ||
              link.meta.cms.some((value) => userCMS.includes(value));
              
          return linkHasRole && linkHasCMS;
        });
      }

      // Check if the category itself has any visible links
      const categoryHasVisibleLinks =
        category.links && category.links.length > 0;
        
      const hasLogisticianOrWarehouseAdmin = userRoles.includes("ROLE_LOGISTICIAN") || 
        userRoles.includes("ROLE_WAREHOUSE_ADMIN");
        
      const categoryHasRole =
        !category?.meta?.roles ||
        category.meta.roles.some((role) => userRoles.includes(role));
        
      // Même logique pour la catégorie que pour les liens
      const categoryHasCMS = hasLogisticianOrWarehouseAdmin && categoryHasRole
        ? true  // Ignorer la vérification de CMS pour ces rôles spécifiques
        : !category?.meta?.cms ||
          category.meta.cms.some((value) => userCMS.includes(value));

      // Return true if the category has roles/CMS and either has visible links or no links at all
      return (
        categoryHasVisibleLinks ||
        (categoryHasRole && categoryHasCMS && !category.links)
      );
    });
    
    // Filter out retail category based on isSalesPoint condition
    if (!state.temporaryUser) {
      menu = menu.filter((category) =>
        category.name === "retail"
          ? user?.stores?.some((s) => s?.isSalesPoint)
          : true
      );
    }

    return menu;
  },
};

const menu = {
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
};

export default menu;
