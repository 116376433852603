<template>
    <TemplateTable
      :title="$t('menu.recom_orders')"
      :text="$t('recom_orders.description')"
      name="orders"
      :url="$listRoutes.recomOrders"
      :table="orders"
      :filters="ordersFilters"
      :fields="fields"
      scope="filters.tradeInArticles"
    />
  </template>
  
  <script>
  import { mapState } from "vuex";
  import TemplateTable from "@/components/templates/TemplateTable";
  import currencyRouteMixin from "@/mixins/currencyRouteMixin";
  
  export default {
    name: "RecomOrdersPage",
    mixins: [currencyRouteMixin],
    components: { TemplateTable },
    data() {
        return {
            fields: [
                {key: 'id', type: 'eye', route: 'recom_order', size: 'xs'},
                {key: 'reference', translationKey: 'id', size: 'm'},
                {key: 'brand.name', type: 'client', size: 'l', translationKey: 'client'},
                {key: 'paidAt', translationKey: 'orderDate', type: 'date'},
                {key: 'customer.id', type: 'CmsCustomer', size: 'xl', translationKey: "email"},
                {key: 'customer.profile', type: 'tagType', size: 'm'},
                {key: 'channel', type: 'tagType', translationKey: 'canal'},
                {key: 'total', type: 'formatPrice', translationKey: 'totalttc'},
                {key: 'paymentState', type: 'tagTypePayments', size: 'm', translationKey: 'payments'},
                {key: 'state', translation: "global.orderStatus", type: 'tagType', size: 'm'},
                {key: 'shipment.state', translation: "global.shipmentStatus", type: 'tagType', size: 'm'},
                {key: 'shippingDate', type: 'date', size: 's'},
                {key: 'orderReturns', type: 'tagTypeReturns', size: 'm'},
                {key: 'returnDate', type: 'date', size: 's'},
                {key: 'itemsNb', translationKey: 'countArticles'},
                {key: 'shippingAddress.countryCode', translation: 'global.saleCountry'}
            ]
        }
    },
    computed: {
      ...mapState(["orders", "ordersFilters"])
    }
  };
  </script>