import Clients from '@/components/pages/admin/config/client/Clients';
import Client from '@/components/pages/admin/config/client/Client';
import ClientDetails from '@/components/pages/admin/config/client/ClientDetails';
import ClientEdit from '@/components/pages/admin/config/client/ClientEdit';
import Database from '@/components/pages/admin/config/Database';
import ClientTranslations from '@/components/pages/admin/config/client/ClientTranslations';
import Store from '@/components/pages/admin/config/client/store/Store';
import Stores from '@/components/pages/admin/config/client/store/Stores';
import StoreCreate from '@/components/pages/admin/config/client/store/StoreCreate';
import StoreEdit from '@/components/pages/admin/config/client/store/StoreEdit';
import ClientRecommerceEmails from '@/components/pages/admin/config/client/ClientRecommerceEmails';
import ClientEmails from '@/components/pages/admin/config/client/ClientEmails';
import ClientEditEmails from '@/components/pages/admin/config/client/ClientEditEmails';
import ClientMailbuilderEmails from '@/components/pages/admin/config/client/ClientMailbuilderEmails';
import ClientMailbuilderEdit from '@/components/pages/admin/config/client/ClientMailbuilderEdit';
import ClientMailbuilderParameters from '@/components/pages/admin/config/client/ClientMailbuilderParameters';
import StoreStock from '@/components/pages/admin/config/client/store/StoreStock';
import StoreTransfers from '@/components/pages/admin/config/client/store/transfer/StoreTransfers';
import StoreTransfer from '@/components/pages/admin/config/client/store/transfer/StoreTransfer';
import StoreTransferCreate from '@/components/pages/admin/config/client/store/transfer/StoreTransferCreate';
import StoreTransferCreateConfirmation from '@/components/pages/admin/config/client/store/transfer/StoreTransferCreateConfirmation'
import ClientCatalog from '@/components/pages/admin/config/client/ClientCatalog';
import ConfigWebsiteMarkets from '@/components/pages/admin/config/website/ConfigWebsiteMarkets';
import ConfigWebsiteMarketCountry from '@/components/pages/admin/config/website/ConfigWebsiteMarketCountry';
import ConfigWebsiteMarketRegions from '@/components/pages/admin/config/website/ConfigWebsiteMarketRegions';
import ClientSEO from '@/components/pages/admin/config/client/ClientSEO';
import ClientPayment from '@/components/pages/admin/config/client/ClientPayment';
import ClientShippingReturn from '@/components/pages/admin/config/client/ClientShippingReturn';
import ClientImages from '@/components/pages/admin/config/client/ClientImages';
import ClientRecommerce from '@/components/pages/admin/config/client/ClientRecommerce';
import ClientGiftCard from '@/components/pages/admin/config/client/ClientGiftCard';
import ClientLanguages from '@/components/pages/admin/config/client/ClientLanguages';

const configRoutes = [
    {
        path: '/admin/config/clients',
        name: 'clients',
        component: Clients,
        meta: {
            title: 'configuration-websites',
            roles: ['ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_FINANCE', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE','ROLE_BRAND_SAV','ROLE_STORE_ADMIN','ROLE_STORE_USER'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/clients/:id',
        name: 'client',
        component: Client,
        meta: {
            roles: ['ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_FINANCE', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE','ROLE_BRAND_SAV','ROLE_BRAND_STORE','ROLE_STORE_ADMIN','ROLE_STORE_USER','ROLE_SUPER_STORE','ROLE_STORE'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/clients/:id/details',
        name: 'client-details',
        component: ClientDetails,
        meta: {
            roles: ['ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_FINANCE'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/clients/:id/traductions',
        name: 'client-translations',
        component: ClientTranslations,
        meta: {
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/clients/:id/recommerce-emails',
        name: 'client-recommerce-emails',
        component: ClientRecommerceEmails,
        meta: {
            roles: ['ROLE_ADMIN'],
            cms: ['SHOPIFY']
        }
    },
    {
        path: '/admin/config/clients/:id/emails',
        name: 'client-emails',
        component: ClientEmails,
        meta: {
            roles: ['ROLE_ADMIN'],
            cms: ['SHOPIFY']
        }
    },
    {
        path: '/admin/config/clients/:id/edit-emails',
        name: 'client-edit-emails',
        component: ClientEditEmails,
        meta: {
            roles: ['ROLE_ADMIN'],
            cms: ['SHOPIFY']
        }
    },
    {
        path: '/admin/config/clients/:id/new-recommerce-emails',
        name: 'client-new-recommerce-emails',
        component: ClientMailbuilderEmails,
        meta: {
            roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV', 'ROLE_CLIENT', 'ROLE_FINANCE'],
            cms: ['FAUME']
        }
    },
    {
        path: '/admin/config/clients/:id/new-recommerce-emails-edit/:mailKey',
        name: 'client-new-recommerce-emails-edit',
        component: ClientMailbuilderEdit,
        meta: {
            roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV', 'ROLE_CLIENT', 'ROLE_FINANCE'],
            cms: ['FAUME']
        }
    },
    {
        path: '/admin/config/clients/:id/new-recommerce-emails-parameters',
        name: 'client-new-recommerce-emails-parameters',
        component: ClientMailbuilderParameters,
        meta: {
            roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE', 'ROLE_BRAND_SAV', 'ROLE_CLIENT', 'ROLE_FINANCE'],
            cms: ['FAUME']
        }
    },
    {
        path: '/admin/config/sites/:id/markets',
        name: 'config-website-markets',
        component: ConfigWebsiteMarkets,
        meta: {
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/sites/:id/markets/:marketName',
        name: 'config-website-markets-country',
        component: ConfigWebsiteMarketCountry,
        meta: {
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/sites/:id/markets/:marketName/region&locals',
        name: 'config-website-markets-regions',
        component: ConfigWebsiteMarketRegions,
        meta: {
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/sites/:id/markets/region&locals',
        name: 'config-website-markets-regions-add',
        component: ConfigWebsiteMarketRegions,
        meta: {
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/base-de-donnees',
        name: 'database',
        component: Database,
        meta: {
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/clients/:id/edit',
        name: 'client-edit',
        component: ClientEdit,
        meta: {
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/clients/:id/boutiques/:storeId/transfers/nouveau',
        name: 'client-store-transfer-create',
        component: StoreTransferCreate,
        meta: {
            roles: ['ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_FINANCE'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/clients/:id/boutiques/:storeId/transfers/selection',
        name: 'client-store-stock-selected',
        component: StoreTransferCreateConfirmation,
        meta: {
            roles: ['ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_FINANCE'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/clients/:id/boutiques/:storeId/transfers/:transferId',
        name: 'client-store-transfer',
        component: StoreTransfer,
        meta: {
            roles: ['ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_FINANCE'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/clients/:id/boutiques/:storeId/transfers',
        name: 'client-store-transfers',
        component: StoreTransfers,
        meta: {
            roles: ['ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_FINANCE'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/clients/:id/boutiques/:storeId/stock',
        name: 'client-store-stock',
        component: StoreStock,
        meta: {
            roles: ['ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_FINANCE'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/clients/:id/boutiques',
        name: 'client-stores',
        component: Stores,
        meta: {
            roles: ['ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_FINANCE', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE','ROLE_BRAND_SAV','ROLE_BRAND_STORE','ROLE_STORE_ADMIN','ROLE_STORE_USER','ROLE_SUPER_STORE','ROLE_STORE'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/clients/:id/boutiques/nouveau',
        name: 'client-store-create',
        component: StoreCreate,
        meta: {
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/clients/:id/boutiques/:storeId/modifier',
        name: 'client-store-edit',
        component: StoreEdit,
        meta: {
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/clients/:id/boutiques/:storeId',
        name: 'client-store',
        component: Store,
        meta: {
            roles: ['ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_FINANCE', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE','ROLE_BRAND_SAV','ROLE_BRAND_STORE','ROLE_STORE_ADMIN','ROLE_STORE_USER','ROLE_SUPER_STORE','ROLE_STORE'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/clients/:id/seo',
        name: 'client-seo',
        component: ClientSEO,
        meta: {
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/clients/:id/methodes-de-paiement',
        name: 'client-payment',
        component: ClientPayment,
        meta: {
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/clients/:id/contenu-visuel',
        name: 'client-images',
        component: ClientImages,
        meta: {
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/clients/:id/livraison-et-retours',
        name: 'client-shipping-return',
        component: ClientShippingReturn,
        meta: {
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/clients/:id/configuration-des-langues',
        name: 'client-configuration-languages',
        component: ClientLanguages,
        meta: {
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/clients/:id/recommerce',
        name: 'client-recommerce',
        component: ClientRecommerce,
        meta: {
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/clients/:id/cartes-cadeaux',
        name: 'client-gift-card',
        component: ClientGiftCard,
        meta: {
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config',
        name: 'config',
        meta: {
            title: 'configuration',
            icon: 'settings',
            roles: ['ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_FINANCE', 'ROLE_BRAND_ADMIN', 'ROLE_BRAND_FINANCE','ROLE_BRAND_SAV','ROLE_BRAND_STORE','ROLE_STORE_ADMIN','ROLE_STORE_USER','ROLE_SUPER_STORE','ROLE_STORE'],
            cms: ['FAUME', 'SHOPIFY']
        },
        redirect: {
            name: 'clients',
        }
    },
    {
        path: '/admin/config/catalogue/:id/',
        name: 'client-catalog',
        component: ClientCatalog,
        meta: {
            roles: ['ROLE_ADMIN'],
            cms: ['FAUME', 'SHOPIFY']
        }
    },
    {
        path: '/admin/config/*',
        redirect: {
            name: 'clients',
        }
    },
];

export default configRoutes;