import Resales from "@/components/pages/admin/resales/Resales";
import RecomResales from "@/components/pages/admin/resales/RecomResales";
import ResalesWaitingPublication from "@/components/pages/admin/resales/_old_ResalesWaitingPublication";
import ResalesWaitingShooting from "@/components/pages/admin/resales/_old_ResalesWaitingShooting";
import ResalesWaitingReview from "@/components/pages/admin/resales/_old_ResalesWaitingReview";
import ResalesPhotos from "@/components/pages/admin/resales/ResalesPhotos";
import Resale from "@/components/pages/admin/resales/Resale";
import RecomResale from "@/components/pages/admin/resales/RecomResale";
import ResalesShipping from "@/components/pages/admin/resales/ResalesShipping";
import ResalesBookings from "@/components/pages/admin/resales/ResalesBookings";
import ResalesShippingCreate from "@/components/pages/admin/resales/ResalesShippingCreate";
import ResalesStocks from "@/components/pages/admin/resales/ResalesStocks";
import RecomResalesStocks from "@/components/pages/admin/resales/RecomResalesStocks";
import RecomTradeinArticles from "@/components/pages/admin/resales/RecomTradeinArticles";
import RecomTradeinAndSellArticle from "@/components/pages/admin/resales/RecomTradeinAndSellArticle";
import ResalesCredited from "@/components/pages/admin/resales/ResalesCredited";
import ConfigWebsiteCatalogueArticleDetailsBis from '@/components/pages/admin/config/website/catalogues/ConfigWebsiteCatalogueArticleDetailsBis'
import ConfigWebsiteCatalogueArticleListingAllBis from '@/components/pages/admin/config/website/catalogues/ConfigWebsiteCatalogueArticleListingAllBis'


const resalesRoutes = [
  {
    path: "/admin/reprises",
    name: "resales",
    redirect: {
      name: "resales-all",
    },
    meta: {
      title: "resales",
      icon: "resale",
      roles: [
        "ROLE_ADMIN",
        "ROLE_CLIENT",
        "ROLE_SUPER_STORE",
        "ROLE_STORE",
        "ROLE_BRAND_ADMIN",
        "ROLE_BRAND_FINANCE",
        "ROLE_BRAND_SAV",
        "ROLE_BRAND_STORE",
        "ROLE_LOGISTICIAN",
        "ROLE_WAREHOUSE_ADMIN",
      ],
      cms: ["FAUME", "SHOPIFY"],
    },
  },
  {
    path: "/admin/reprises",
    name: "resales-all",
    component: Resales,
    meta: {
      title: "resales-all",
      roles: [
        "ROLE_ADMIN",
        "ROLE_CLIENT",
        "ROLE_SUPER_STORE",
        "ROLE_STORE",
        "ROLE_BRAND_ADMIN",
        "ROLE_BRAND_FINANCE",
        "ROLE_BRAND_SAV",
        "ROLE_BRAND_STORE",
        "ROLE_LOGISTICIAN",
        "ROLE_WAREHOUSE_ADMIN",
      ],
      cms: ["SHOPIFY"],
    },
  },
  {
    path: "/admin/reprises-faume",
    name: "recom_resales-all",
    component: RecomResales,
    meta: {
      title: "recom_resales-all",
      roles: [
        "ROLE_ADMIN",
        "ROLE_CLIENT",
        "ROLE_SUPER_STORE",
        "ROLE_STORE",
        "ROLE_BRAND_ADMIN",
        "ROLE_BRAND_FINANCE",
        "ROLE_BRAND_SAV",
        "ROLE_BRAND_STORE",
        "ROLE_LOGISTICIAN",
        "ROLE_WAREHOUSE_ADMIN",
      ],
      cms: ["FAUME"],
    },
  },
  {
    path: "/admin/reprises/creditees",
    name: "resales-credited",
    component: ResalesCredited,
    meta: {
      title: "resales-credited",
      roles: [
        "ROLE_ADMIN",
        "ROLE_FINANCE",
        "ROLE_CLIENT",
        "ROLE_BRAND_ADMIN",
        "ROLE_BRAND_FINANCE",
      ],
      cms: ["SHOPIFY"],
    },
  },
  {
    path: "/admin/reprises/attente-de-traitement",
    name: "resales-waiting-review",
    component: ResalesWaitingReview,
    meta: {
      title: "resales-not-review",
      roles: [
        "ROLE_ADMIN",
        "ROLE_LOGISTICIAN",
        "ROLE_BRAND_SAV",
        "ROLE_WAREHOUSE_ADMIN",
      ],
      cms: ["FAUME", "SHOPIFY"],
    },
  },
  {
    path: "/admin/reprises/attente-de-shooting",
    name: "resales-waiting-shooting",
    component: ResalesWaitingShooting,
    meta: {
      title: "resales-not-shooting",
      roles: ["ROLE_ADMIN", "ROLE_LOGISTICIAN", "ROLE_WAREHOUSE_ADMIN"],
      cms: ["FAUME", "SHOPIFY"],
    },
  },
  {
    path: "/admin/reprises/attente-de-publication",
    name: "resales-waiting-publication",
    component: ResalesWaitingPublication,
    meta: {
      title: "resales-not-published",
      roles: ["ROLE_ADMIN"],
      cms: ["FAUME", "SHOPIFY"],
    },
  },
  {
    path: "/admin/reprises/photos",
    name: "resales-photos",
    component: ResalesPhotos,
    meta: {
      title: "photos",
      roles: [
        "ROLE_ADMIN",
        "ROLE_BRAND_ADMIN",
        "ROLE_LOGISTICIAN",
        "ROLE_BRAND_SAV",
        "ROLE_WAREHOUSE_ADMIN",
      ],
      cms: ["FAUME", "SHOPIFY"],
    },
  },
  {
    path: "/admin/reprises/transport",
    name: "resales-shipping",
    component: ResalesShipping,
    meta: {
      title: "shipping",
      roles: [
        "ROLE_ADMIN",
        "ROLE_BRAND_ADMIN",
        "ROLE_LOGISTICIAN",
        "ROLE_BRAND_SAV",
        "ROLE_WAREHOUSE_ADMIN",
      ],
      cms: ["FAUME", "SHOPIFY"],
    },
  },
  {
    path: "/admin/reprises/transport/nouveau",
    name: "resales-shipping-create",
    component: ResalesShippingCreate,
    meta: {
      title: "shipping",
      roles: [
        "ROLE_ADMIN",
        "ROLE_LOGISTICIAN",
        "ROLE_BRAND_SAV",
        "ROLE_WAREHOUSE_ADMIN",
      ],
      cms: ["FAUME", "SHOPIFY"],
    },
  },
  {
    path: "/admin/liste-des-articles/article-en-vente/:id",
    name: "config-website-catalogues-articles-details-bis",
    component: ConfigWebsiteCatalogueArticleDetailsBis,
    meta: {
      title: "sold_articles",
      roles: [
        "ROLE_ADMIN",
        "ROLE_BRAND_ADMIN",
        "ROLE_BRAND_FINANCE",
        "ROLE_BRAND_SAV",
      ],
      cms: ["FAUME"],
    },
  },
  {
    path: "/admin/liste-des-articles/tous-les-articles-en-vente",
    name: "config-website-catalogues-articles-listing-all-bis",
    component: ConfigWebsiteCatalogueArticleListingAllBis,
    meta: {
      title: "all_articles-on-sale",
      roles: ["ROLE_ADMIN", "ROLE_BRAND_ADMIN"],
      cms: ["FAUME"],
    },
  },

  {
    path: "/admin/reprises/rendez-vous",
    name: "resales-bookings",
    component: ResalesBookings,
    meta: {
      title: "bookings",
      roles: [
        "ROLE_ADMIN",
        "ROLE_ADMIN_BRAND_STORE",
        "ROLE_SUPER_STORE",
        "ROLE_STORE",
        "ROLE_BRAND_ADMIN",
      ],
      cms: ["FAUME", "SHOPIFY"],
    },
  },
  {
    path: "/admin/reprises/stocks",
    name: "resales-stocks",
    component: ResalesStocks,
    meta: {
      title: "stock-detailed",
      roles: [
        "ROLE_ADMIN",
        "ROLE_FINANCE",
        "ROLE_CLIENT",
        "ROLE_BRAND_ADMIN",
        "ROLE_BRAND_FINANCE",
      ],
      cms: ["SHOPIFY"],
    },
  },
  {
    path: "/admin/reprises/stocks-faume",
    name: "recom_resales-stocks",
    component: RecomResalesStocks,
    meta: {
      title: "recom_resales-stocks",
      roles: [
        "ROLE_ADMIN",
        "ROLE_FINANCE",
        "ROLE_CLIENT",
        "ROLE_BRAND_ADMIN",
        "ROLE_BRAND_FINANCE",
      ],
      cms: ["FAUME"],
    },
  },
  {
    path: "/admin/utilisateurs-faume/articles-repris-faume",
    name: "recom_tradein-articles",
    component: RecomTradeinArticles,
    meta: {
      title: "recom_tradein-articles",
      roles: [
        "ROLE_ADMIN",
        "ROLE_FINANCE",
        "ROLE_CLIENT",
        "ROLE_BRAND_ADMIN",
        "ROLE_BRAND_FINANCE",
        "ROLE_LOGISTICIAN",
        "ROLE_WAREHOUSE_ADMIN",
      ],
      cms: ["FAUME"],
    },
  },
  {
    path: "/admin/articles-repris-vendus-faume/:id",
    name: "recom_tradein-n-sell-article",
    component: RecomTradeinAndSellArticle,
    meta: {
      title: "title-to-def",
      roles: [
        "ROLE_ADMIN",
        "ROLE_FINANCE",
        "ROLE_CLIENT",
        "ROLE_BRAND_ADMIN",
        "ROLE_BRAND_FINANCE",
        "ROLE_LOGISTICIAN",
        "ROLE_WAREHOUSE_ADMIN",
      ],
      cms: ["FAUME"],
    },
  },
  {
    path: "/admin/utilisateurs-faume/articles-repris-faume/:id",
    name: "recom_tradein-article",
    redirect: {
      name: "config-website-catalogues-articles-details",
    },
  },
  {
    path: "/admin/reprises/:id",
    name: "resale",
    component: Resale,
    meta: {
      roles: [
        "ROLE_ADMIN",
        "ROLE_CLIENT",
        "ROLE_SUPER_STORE",
        "ROLE_STORE",
        "ROLE_BRAND_ADMIN",
        "ROLE_BRAND_FINANCE",
        "ROLE_BRAND_SAV",
        "ROLE_BRAND_STORE",
        "ROLE_LOGISTICIAN",
        "ROLE_WAREHOUSE_ADMIN",
      ],
      cms: ["SHOPIFY"],
    },
  },
  {
    path: "/admin/reprises-faume/:id",
    name: "recom_resale",
    component: RecomResale,
    meta: {
      roles: [
        "ROLE_ADMIN",
        "ROLE_CLIENT",
        "ROLE_SUPER_STORE",
        "ROLE_STORE",
        "ROLE_BRAND_ADMIN",
        "ROLE_BRAND_FINANCE",
        "ROLE_BRAND_SAV",
        "ROLE_BRAND_STORE",
        "ROLE_LOGISTICIAN",
        "ROLE_WAREHOUSE_ADMIN",
      ],
      cms: ["FAUME"],
    },
  },
  {
    path: "/admin/reprises/*",
    redirect: {
      name: "resales",
    },
  },
];

export default resalesRoutes;
