<template>
  <div class="table-wrapper">
    <!-- Conteneur de table -->
    <div class="table-container">
      <table>
        <thead>
          <tr>
            <!-- Checkbox header -->
            <th class="checkbox-column">
              <div class="skeleton-checkbox"></div>
            </th>
            <!-- Image header -->
            <th class="image-column">
              <div class=""></div>
            </th>
            <!-- ID header -->
            <th class="id-column">
              <div class="skeleton-header"></div>
            </th>
            <!-- Nom produit header -->
            <th class="name-column">
              <div class="skeleton-header"></div>
            </th>
            <!-- Marque header -->
            <th class="brand-column">
              <div class="skeleton-header"></div>
            </th>
            <!-- Date stock header -->
            <th class="date-column">
              <div class="skeleton-header"></div>
            </th>
            <!-- Date publication header -->
            <th class="date-column">
              <div class="skeleton-header"></div>
            </th>
            <!-- Statut header -->
            <th class="status-column">
              <div class="skeleton-header"></div>
            </th>
            <!-- Canaux header -->
            <th class="channels-column">
              <div class="skeleton-header"></div>
            </th>
            <!-- Erreurs header -->
            <th class="errors-column">
              <div class="skeleton-header"></div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="n in 10" :key="`row-${n}`">
            <td class="checkbox-column">
              <div class="skeleton-checkbox"></div>
            </td>
            <td class="image-column">
              <div class="skeleton-image"></div>
            </td>
            <td class="id-column">
              <div class="skeleton-text"></div>
            </td>
            <td class="name-column">
              <div class="skeleton-text"></div>
            </td>
            <td class="brand-column">
              <div class="skeleton-text"></div>
            </td>
            <td class="date-column">
              <div class="skeleton-text"></div>
            </td>
            <td class="date-column">
              <div class="skeleton-text"></div>
            </td>
            <td class="status-column">
              <div class="skeleton-status"></div>
            </td>
            <td class="channels-column">
              <div class="skeleton-channels">
                <div class="skeleton-status"></div>
              </div>
            </td>
            <td class="errors-column">
              <div class="skeleton-status"></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableSkeleton",
};
</script>

<style scoped>
.table-wrapper {
  width: 100%;
  background: #f5f5f5;
}
.tabs-wrapper {
  background-color: #fff;
  padding: 2px;
  border-radius: 8px;
}

/* Styles des actions */
.actions-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.skeleton-tabs {
  display: flex;
  gap: 16px;
}

.skeleton-tab-item {
  width: 92px;
  height: 41px;
  background: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s infinite;
}

.skeleton-search-bar {
  display: flex;
  gap: 16px;
  align-items: center;
}

.skeleton-input {
  flex: 1;
  height: 40px;
  background: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s infinite;
  width: 200px;
  max-width: 300px;
}

.skeleton-button {
  width: 100px;
  height: 40px;
  background: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s infinite;
}

/* Styles de la table */
.table-container {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px 16px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
  height: 48px;
}

/* Largeurs des colonnes */
.checkbox-column {
  width: 40px;
}
.image-column {
  width: 60px;
}
.id-column {
  width: 150px;
}
.name-column {
  width: 25%;
}
.brand-column {
  width: 15%;
}
.date-column {
  width: 120px;
}
.status-column {
  width: 120px;
}
.channels-column {
  width: 150px;
}
.errors-column {
  width: 155px;
}

/* Éléments skeleton */
.skeleton-checkbox {
  width: 16px;
  height: 16px;
  background: #e0e0e0;
  border-radius: 2px;
  animation: pulse 1.5s infinite;
}

.skeleton-image {
  width: 40px;
  height: 40px;
  background: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s infinite;
}

.skeleton-header {
  height: 16px;
  width: 70%;
  background: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s infinite;
}

.skeleton-text {
  height: 16px;
  width: 80%;
  background: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s infinite;
}

.skeleton-status {
  height: 24px;
  width: 80px;
  background: #e0e0e0;
  border-radius: 12px;
  animation: pulse 1.5s infinite;
}

.skeleton-channels {
  display: flex;
  gap: 8px;
}

/* Pagination */
.skeleton-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: white;
  border-top: 1px solid #e0e0e0;
  border-radius: 0 0 8px 8px;
}

.skeleton-pagination-info {
  width: 150px;
  height: 32px;
  background: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s infinite;
}

.skeleton-pagination-controls {
  display: flex;
  gap: 8px;
}

.skeleton-page {
  width: 32px;
  height: 32px;
  background: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}

/* Responsive */
@media (max-width: 768px) {
  .skeleton-search-bar {
    flex-direction: column;
  }

  .skeleton-input {
    max-width: 100%;
  }

  .skeleton-button {
    width: 100%;
  }

  .table-container {
    margin: 0 -16px;
    border-radius: 0;
  }
}
</style>
