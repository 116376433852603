<template>
    <label 
        :for="name"
        class="field__file" 
        :class="errorMessage ? 'field__error' : ''"
    >
        <div v-if="showImagePreview" class="field__preview">
            <img :src="previewImageSrc" alt="Preview" class="field__preview-image"/>
        </div>
        <span v-else v-html="text"></span>
        <input required="true" type="file" :name="name" :id="name" :accept="allowedExtensions" @change="onFileSelected" />
        <span class="field__label is-up">{{ label }}</span>
        <div class="error" v-if="errorMessage"><strong>{{ errorMessage }}</strong></div>
    </label>
</template>

<script>
export default {
    props: {
        allowedExtensions: Array,
        maxSizeFile: Number,
        label: String,
        text: String,
        name: String,
        model: [String, File, null]
    },
    data() {
        return {
            errorFileFormat: false,
            errorFileSize: false,
            errorMessage: null,
            uploadedImagePreview: null,
        }
    },
    computed: {
        isImageFile() {
            const imageExtensions = ['.jpg', '.jpeg', '.png', '.webp', '.gif'];
            return imageExtensions.some(ext => 
                typeof this.model === 'string' && this.model.toLowerCase().endsWith(ext)
            );
        },
        showImagePreview() {
            return !!(this.uploadedImagePreview || (this.model && this.isImageFile));
        },
        previewImageSrc() {
            return this.uploadedImagePreview || this.model;
        }
    },
    watch: {
        model: {
            immediate: true,
            async handler(newValue) {
                if (newValue && typeof newValue === 'string' && newValue.startsWith('http') ) {
                    await this.setDefaultFile(newValue);
                }
            }
        }
    },
    methods: {
        async setDefaultFile(url) {
            try {
                const response = await fetch(url);
                const blob = await response.blob();
                const fileName = url.split('/').pop();
                const file = new File([blob], fileName, { type: blob.type });
                const dataTransfer = new DataTransfer();
                dataTransfer.items.add(file);
                
                // Sélectionner l'input dans le composant
                const fileInput = this.$el.querySelector('input[type="file"]');
                fileInput.files = dataTransfer.files;
            } catch (error) {
                console.error('Erreur lors du chargement du fichier:', error);
            }
        },
        onFileSelected(event) {
            const that = this;
            const file = event.target.files[0];
            if (!file) return;

            const fileName = file.name.split('.');
            const fileExt = `.${fileName[fileName.length-1]}`;
            const isFileFormatValid = this.allowedExtensions.includes(fileExt);
            let isFileSizeValid = !this.maxSizeFile || this.maxSizeFile >= file.size;
            
            const imageExtensions = ['.jpg', '.jpeg', '.png', '.webp', '.gif'];
            const isImageFile = imageExtensions.some(ext => fileExt.toLowerCase() === ext);
            
            if(isImageFile){
                const reader = new FileReader();
                reader.onload = function(event) {
                    that.uploadedImagePreview = event.target.result;
                    
                    const img = new Image();
                    img.src = event.target.result;
                    img.onload = function() {
                        const width = img.width;
                        const height = img.height;

                        if(width >= 1100 && height >= 600){
                            isFileSizeValid = false;
                        }
                        that.errorFileFormat = !isFileFormatValid;
                        that.errorFileSize = !isFileSizeValid;

                        if (that.errorFileFormat && !that.errorFileSize) {
                            that.errorMessage = that.$t('catalogue.form_filetype');
                        } else if (!that.errorFileFormat && that.errorFileSize) {
                            that.errorMessage = that.$t('catalogue.form_filesize');
                        } else if (that.errorFileFormat && that.errorFileSize) {
                            that.errorMessage = that.$t('catalogue.form_files');
                        } else {
                            that.$emit('change', isFileFormatValid && isFileSizeValid ? file : null);
                            that.errorMessage = null;
                        }
                    };
                };
                reader.readAsDataURL(file);
            } else {
                this.uploadedImagePreview = null;
                this.errorFileFormat = !isFileFormatValid;
                this.errorFileSize = !isFileSizeValid;
                if (this.errorFileFormat && !this.errorFileSize) {
                    this.errorMessage = this.$t('catalogue.form_filetype');
                } else if (!this.errorFileFormat && this.errorFileSize) {
                    this.errorMessage = this.$t('catalogue.form_filesize');
                } else if (this.errorFileFormat && this.errorFileSize) {
                    this.errorMessage = this.$t('catalogue.form_files');
                } else {
                    this.$emit('change', isFileFormatValid && isFileSizeValid ? file : null);
                    this.errorMessage = null;
                }
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.field {
    &__file {
        position: relative;
        margin-top: 20px;
        display: flex;
        gap: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 200px;
        padding: 20px;
        border-radius: calc(var(--field-height)/2);
        background: #fff;
        color: var(--color-text-black);
        cursor: pointer;
        transition: background .2s ease-in-out, border .2s ease-in-out;

        [type=file] {
            width: 350px;
            max-width: 100%;
            color: var(--color-text-black);
            padding: 5px;
            background: #fff;
            border-radius: var(--border-radius-2);
            border: var(--border-2);

            &::file-selector-button {
                margin-right: 20px;
                border: none;
                background: var(--color-text-black);
                padding: 10px 20px;
                border-radius: var(--border-radius-2);
                color: #fff;
                cursor: pointer;
                transition: background .2s ease-in-out;
            }

            &::file-selector-button:hover {
                background: var(--color-text-grey);
            }

        }


        &:hover {
            background: #eee;
        }
        &:hover .span {
            color: #222;
        }

        span {
            color: var(--color-text-black);
            font-weight: normal;
            text-align: center;
            transition: color .2s ease-in-out;
        }

        .field__label {
            pointer-events: none;
            transition: top ease-out 0.2s, font-size ease-out 0.2s, color ease-out 0.2s;
            font-size: 1.1rem;
            color: #999;
            font-weight: 400;
        }
    }

    &__preview {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &-image {
            max-width: 500px;
            max-height: 300px;
            object-fit: contain;
        }

    }
    &__error {
        background: var(--color-red-light);

        .error{
            color: var(--color-text-red);
        }
    }
}
</style>