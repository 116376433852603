<template>
  <div class="refund-commercial-gesture">
    <CardRefund 
      v-if="validatedAmount" 
      :text="$t('order.refund.commercial_gesture')" 
      :amount="validatedAmount" 
      @close="handleClose"
    />
    <div v-else class="refund-commercial-gesture__form">
      <Field 
        size="l" 
        placeholder="0" 
        :min="0"
        :model="amount" 
        type="number" 
        :step="1"
        :border="true" 
        pattern="\d*"
        @change="handleAmountChange"
      />
      <Button 
        size="l" 
        color="grey" 
        @click.native.prevent="handleValidate"
      >
        {{ $t('global.apply') }}
      </Button>
    </div>
  </div>
</template>

<script>
import Field from '@/components/ui/form/fields/Field.vue';
import Button from '@/components/ui/button/Button';
import CardRefund from '@/components/ui/card/CardRefund';

export default {
  name: 'RefundCommercialGesture',
  components: {
    Field,
    Button,
    CardRefund
  },
  props: {
    validatedAmount: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      amount: 0,
    };
  },
  methods: {
    handleAmountChange(value) {
      this.amount = value;
    },
    handleValidate() {
      if( this.amount == 0 ) return;
      this.$emit('update', this.amount * 100);
    },
    handleClose() {
      this.$emit('update', null);
    }
  }
};
</script>

<style lang="scss">
.refund-commercial-gesture {
  &__form {
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    align-items: center;
    width: 100%;
    
    .field {
      margin-top: 0;
      width: 100%;
    }
    
    .button {
      min-width: 100px;
    }
  }
}
</style>