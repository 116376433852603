<template>
  <div class="status-popover">
    <div class="status-popover__list">
      <button
        v-for="option in filteredOptions"
        :key="option.id"
        class="status-button"
        @click="selectOption(option)"
      >
        <StatusCell :status="option.id" />
      </button>
    </div>
  </div>
</template>

<script>
import StatusCell from "@/components/ui/dataTable/StatusCell.vue";

export default {
  name: "StatusPopover",
  components: { StatusCell },
  props: {
    options: {
      type: Array,
      required: true,
    },
    currentTab: {
      type: String,
      required: true,
    },
  },
  computed: {
    filteredOptions() {
      switch (this.currentTab) {
        case "on_sale":
          // Pour "En vente", on ne montre que l'option "dépublier"
          return this.options.filter((option) => option.id === "unpublished");

        case "to_correct":
          // Pour "À corriger", on ne montre que l'option "à publier"
          return this.options.filter((option) => option.id === "to_publish");

        case "all":
        case "to_publish":
        default:
          // Pour "Tous", on ne montre aucune option
          return [];
      }
    },
  },
  methods: {
    selectOption(option) {
      this.$emit("select", option);
    },
  },
};
</script>

<style scoped>
.status-popover {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 8px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  min-width: 200px;
  z-index: 1000;
}

.status-popover__list {
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.status-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  text-align: left;

  .status-badge {
    width: 100%;
    text-align: center;
  }
  .status-badge--to-publish {
    &:hover {
      background: #ffe4d1;
      box-shadow: 0 1px 3px rgba(254, 163, 111, 0.2);
    }
  }

  .status-badge--on-sale {
    &:hover {
      background: #e8e8ff;
      box-shadow: 0 1px 3px rgba(111, 113, 230, 0.2);
    }
  }

  .status-badge--to-correct {
    &:hover {
      background: #ffe4e3;
      box-shadow: 0 1px 3px rgba(254, 55, 55, 0.2);
    }
  }

  .status-badge--draft {
    &:hover {
      background: #f8f8f8;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    }
  }

  .status-badge--unpublished {
    &:hover {
      background: #f0f0f0;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
