// PaginationTable.vue
<template>
  <div class="pagination-container">
    <div class="pagination-info">
      <div class="items-per-page">
        <select
          v-model="localItemsPerPage"
          @change="handleItemsPerPageChange"
          class="select-items"
        >
          <option v-for="n in [10, 25, 50, 100]" :key="n" :value="n">
            {{ $t("article_put_online.pagination.itemsPerPage", { n: n }) }}
          </option>
        </select>
      </div>
    </div>

    <div class="pagination-controls">
      <button
        class="pagination-btn"
        :disabled="currentPage === 1"
        @click="changePage(1)"
        :class="{ 'hidden-mobile': currentPage > 4 }"
      >
        <span class="icon">««</span>
      </button>

      <div class="pagination-pages">
        <template v-for="(page, index) in pageGroups">
          <span
            v-if="page === '...'"
            :key="'ellipsis' + index"
            class="pagination-ellipsis"
          >
            ...
          </span>
          <button
            v-else
            :key="page"
            @click="changePage(page)"
            :class="['pagination-page', { active: page === currentPage }]"
          >
            {{ page }}
          </button>
        </template>
      </div>

      <button
        class="pagination-btn"
        :disabled="currentPage === totalPages"
        @click="changePage(totalPages)"
        :class="{ 'hidden-mobile': currentPage < totalPages - 3 }"
      >
        <span class="icon">»»</span>
      </button>
    </div>
    <div>
      {{
        $t("article_put_online.pagination.info", {
          start: startItem,
          end: endItem,
          total: totalItems,
        })
      }}
    </div>
  </div>
</template>

<script>
export default {
  name: "PaginationTable",
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      localItemsPerPage: this.itemsPerPage,
    };
  },

  computed: {
    startItem() {
      return Math.min(
        (this.currentPage - 1) * this.itemsPerPage + 1,
        this.totalItems
      );
    },

    endItem() {
      return Math.min(this.currentPage * this.itemsPerPage, this.totalItems);
    },

    pageGroups() {
      if (this.totalPages <= 7) {
        return Array.from({ length: this.totalPages }, (_, i) => i + 1);
      }

      const groups = [1];

      // Gestion des pages autour de la page courante
      let start = Math.max(2, this.currentPage - 1);
      let end = Math.min(this.totalPages - 1, this.currentPage + 1);

      // Ajuste pour toujours avoir 3 pages dans le groupe central
      if (this.currentPage <= 3) {
        end = Math.min(5, this.totalPages - 1);
      } else if (this.currentPage >= this.totalPages - 2) {
        start = Math.max(2, this.totalPages - 4);
      }

      // Ajoute les ellipsis et les pages
      if (start > 2) {
        groups.push("...");
      }
      for (let i = start; i <= end; i++) {
        groups.push(i);
      }
      if (end < this.totalPages - 1) {
        groups.push("...");
      }

      groups.push(this.totalPages); // Toujours afficher la dernière page

      return groups;
    },
  },

  methods: {
    changePage(page) {
      if (page === this.currentPage) return;
      this.$emit("page-change", page);
    },

    handleItemsPerPageChange() {
      this.$emit("items-per-page-change", this.localItemsPerPage);
    },
  },
};
</script>

<style scoped>
.pagination-container {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  border-top: 1px solid #e0e0e0;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 16px;
}

.pagination-info {
  color: #666;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.select-items {
  padding: 6px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background: white;
  color: #333;
  font-size: 14px;
  cursor: pointer;
}

.pagination-controls {
  display: flex;
  align-items: center;
  gap: 8px;
}

.pagination-btn {
  padding: 8px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background: white;
  color: #333;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 36px;
}

.pagination-btn:hover:not(:disabled) {
  background: #f5f5f5;
  border-color: #d0d0d0;
}

.pagination-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: #f9f9f9;
}

.pagination-pages {
  display: flex;
  gap: 4px;
  align-items: center;
}

.pagination-page {
  min-width: 36px;
  height: 36px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-page:hover:not(.active) {
  background: #f5f5f5;
  border-color: #d0d0d0;
}

.pagination-page.active {
  background: black;
  color: white;
  border-color: black;
  cursor: default;
}

.pagination-ellipsis {
  color: #666;
  padding: 0 4px;
}

@media (max-width: 768px) {
  .pagination-container {
    flex-direction: column;
    align-items: stretch;
  }

  .pagination-info {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .items-per-page {
    margin-left: 0;
    width: 100%;
  }

  .select-items {
    width: 100%;
  }

  .pagination-controls {
    justify-content: center;
  }

  .hidden-mobile {
    display: none;
  }

  .pagination-page {
    min-width: 32px;
    height: 32px;
  }
}
</style>
