<template>
  <form ref="reportForm" class="report-form" @submit.prevent="handleSubmit">
    <div v-if="loading" class="loading">
      <span class="loading-spinner"></span>
      {{ $t("article_put_online_details.report_modal.loading") }}
    </div>

    <template v-else>
      <div class="categories-wrapper">
        <div
          v-for="(errors, category) in list"
          :key="category"
          class="category-section"
        >
          <div class="category-errors">
            <div v-for="error in errors" :key="error.order" class="error-card">
              <label class="checkbox-wrapper">
                <input
                  type="checkbox"
                  :id="error.name"
                  :value="error"
                  v-model="selectedErrors"
                />
                <span class="custom-checkbox"></span>
                <div class="error-content">
                  <span class="error-name">
                    {{ getTranslatedName(category, error.name) }}
                  </span>
                  <span class="error-description">
                    {{ getTranslatedDescription(category, error.name) }}
                  </span>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </template>
  </form>
</template>

<script>
export default {
  name: "ReportProblemForm",
  props: {
    articleId: {
      type: String,
      required: true,
    },
    existingErrors: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedErrors: [],
      initialSelectedErrors: [],
      list: {},
      loading: true,
    };
  },

  computed: {
    newSelectedErrors() {
      return this.selectedErrors.filter(
        (error) =>
          !this.initialSelectedErrors.some(
            (initial) => initial.name === error.name
          )
      );
    },

    isSubmitDisabled() {
      return this.newSelectedErrors.length === 0;
    },
  },

  methods: {
    getCategoryKey(category) {
      const categoryMapping = {
        PHOTO: "photo",
        "INFORMATION PRODUIT": "product",
        AUTRE: "other",
      };
      return categoryMapping[category] || category.toLowerCase();
    },

    getErrorKey(errorName) {
      const errorMapping = {
        "Packshot manquant": "packshot_missing",
        "Photo qui ne s’affiche pas correctement": "broken_photo",
        "Mauvais cadrage photo": "bad_framing",
        "Mauvaise photo": "bad_photo",
        "Mauvaise description": "bad_description",
        "Mauvais titre": "bad_title",
        "Collection manquante": "missing_collection",
        "À ne pas publier": "do_not_publish",
        Autre: "other",
      };
      return errorMapping[errorName] || errorName.toLowerCase();
    },

    getTranslatedName(category, errorName) {
      const categoryKey = this.getCategoryKey(category);
      const errorKey = this.getErrorKey(errorName);
      const translationKey = `article_put_online_details.report_modal.errors.${categoryKey}.${errorKey}.name`;
      return this.$te(translationKey) ? this.$t(translationKey) : errorName;
    },

    getTranslatedDescription(category, errorName) {
      const categoryKey = this.getCategoryKey(category);
      const errorKey = this.getErrorKey(errorName);
      const translationKey = `article_put_online_details.report_modal.errors.${categoryKey}.${errorKey}.description`;
      return this.$te(translationKey) ? this.$t(translationKey) : errorName;
    },

    handleSubmit() {
      const errors = this.newSelectedErrors.map((error) => ({
        reason: error.name,
        description: error.description,
      }));

      this.$emit("submit", { errors });
    },

    initializeSelectedErrors() {
      if (this.existingErrors?.length && Object.keys(this.list).length) {
        const allErrors = Object.values(this.list).flat();
        const initialErrors = allErrors.filter((error) =>
          this.existingErrors.some((existing) => existing.reason === error.name)
        );
        this.selectedErrors = [...initialErrors];
        this.initialSelectedErrors = [...initialErrors];
      }
    },
  },

  watch: {
    selectedErrors: {
      handler(newVal) {
        if (newVal?.length > 0) {
          console.log("Selected errors changed:", newVal);
        }
      },
      deep: true,
    },
    existingErrors: {
      immediate: true,
      handler() {
        this.initializeSelectedErrors();
      },
    },
    list: {
      handler() {
        this.initializeSelectedErrors();
      },
    },
  },

  async created() {
    try {
      const response = await this.$store.dispatch("getReportingList");
      this.list = response;
      this.initializeSelectedErrors();
    } catch (error) {
      console.error("Erreur lors du chargement de la liste:", error);
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style scoped>
.categories-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.category-errors {
  flex-direction: column;
  gap: 4px;
  border-radius: 0 0 8px 8px;
  /* padding: 16px 0; */
  display: flex;
}

.select {
  width: 100%;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 14px;
  outline: none;
  transition: all 0.2s;
  cursor: pointer;
  background: white;
}

.select:hover {
  border-color: #000;
}

.errors-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.error-card {
  border-radius: 8px;
  transition: all 0.2s;
}

.error-card:hover {
  background: #f9f9f9;
}

.checkbox-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 16px;
  cursor: pointer;
}

.custom-checkbox {
  width: 20px;
  height: 20px;
  border: 2px solid #000;
  border-radius: 4px;
  position: relative;
  flex-shrink: 0;
  transition: all 0.2s;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"]:checked + .custom-checkbox {
  background: #000;
}

input[type="checkbox"]:checked + .custom-checkbox:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.error-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.error-name {
  font-weight: 500;
  font-size: 14px;
}

.error-description {
  font-size: 13px;
  color: #666;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 20px;
}

.loading-spinner {
  width: 20px;
  height: 20px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #000;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
