<template>
  <div v-if="selectedCount > 0" class="fixed-action-bar">
    <div class="fixed-action-bar__content">
      <button class="fixed-action-bar__close" @click="$emit('clear')">×</button>
      <div class="fixed-action-bar__selection">
        {{ selectedCount }}
        {{ $tc("article_put_online.fix_action_bar.item_selected", selectedCount) }}
      </div>
      <div class="fixed-action-bar__actions">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FixedActionBar",
  props: {
    selectedCount: {
      type: Number,
      required: true,
      validator: (value) => value >= 0,
    },
  },
  emits: ["clear"],
};
</script>

<style lang="scss" scoped>
.fixed-action-bar {
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  background: #000;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: auto;
  max-width: calc(100% - 48px);
  backdrop-filter: blur(8px);

  &__content {
    padding: 16px 24px;
    padding-left: 48px;
    display: flex;
    align-items: center;
    gap: 24px;
  }

  &__selection {
    font-size: 14px;
    color: #fff;
    white-space: nowrap;
  }

  &__actions {
    display: flex;
    gap: 12px;
  }

  &__close {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    padding: 4px;
    line-height: 1;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.7;
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }
  }

  @media (max-width: 768px) {
    bottom: 16px;
    width: calc(100% - 32px);
    margin: 0 16px;

    &__content {
      padding: 12px 16px;
      flex-direction: column;
      gap: 12px;
    }

    &__actions {
      width: 100;

      :deep(.btn-action) {
        flex: 1;
        text-align: center;
        justify-content: center;
        display: flex;
      }
    }
  }
}

:deep(.btn-action) {
  background: #fff;
  border: 1px solid #e0e0e0;
  color: #000;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
  white-space: nowrap;
  line-height: 20px;

  &:hover {
    background: #f5f5f5;
    border-color: #d0d0d0;
  }
}
</style>
