<template>
  <div class="filter-container">
    <!-- Bouton de filtre -->
    <button @click="toggleSidebar" class="btn btn-filter">
      <SvgIcon name="filter-2" class="icon" />
      {{ $t("article_put_online.filter") }}
      <span v-if="activeFiltersCount" class="filter-count">
        {{ activeFiltersCount }}
      </span>
    </button>

    <!-- Sidebar -->
    <div v-if="isOpen" class="filter-overlay" @click="closeSidebar">
      <div class="filter-sidebar" @click.stop>
        <!-- Header -->
        <div class="sidebar-header">
          <h3>{{ $t("article_put_online.filter") }}</h3>
          <div class="header-actions">
            <button
              v-if="hasActiveFilters"
              @click="resetAllFilters"
              class="btn-reset"
            >
              {{ $t("article_put_online.reste_filter") }}
            </button>
            <button @click="closeSidebar" class="btn-close">×</button>
          </div>
        </div>

        <!-- Contenu -->
        <div class="sidebar-content">
          <!-- Utilisation des slots pour injecter des filtres de manière dynamique -->
          <slot 
            name="filters" 
            :filters="filters" 
            :update-filter="updateFilterValue"
            :operators="operators"
            :table-filters="tableFilters"
            :date-filters="dateFilters"
            :update-date-filter="updateDateFilter"
            :clear-date="clearDate"
          >
            <!-- Contenu par défaut si aucun filtre n'est injecté -->
            <div class="no-filters">
              {{ $t("article_put_online.no_filters_available") }}
            </div>
          </slot>
        </div>

        <!-- Footer -->
        <div class="sidebar-footer">
          <Button @click.native.prevent="applyFilters" class="btn-apply">
            {{ $t("article_put_online.apply_filters") }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Button from "@/components/ui/button/Button";
import SvgIcon from "../svg/SvgIcon.vue";

export default {
  name: "FilterSidebarTable",
  components: { Button, SvgIcon },
  props: {
    initialFilters: {
      type: Object,
      default: () => ({}),
    },
    currentTab: {
      type: String,
      default: "all",
    },
    // Nouvelle prop pour recevoir les configuration des filtres disponibles
    availableFilters: {
      type: Array,
      default: () => [],
    },
    // Prop pour les composants de filtres personnalisés
    customFilterComponents: {
      type: Object,
      default: () => ({}),
    },
    // Prop pour les libellés personnalisés des filtres
    customLabels: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isOpen: false,
      filters: {
        channelOperator: "inclus",
        selectedChannels: [],
        errorOperator: "inclus",
        selectedErrors: [],
        brandOperator: "inclus",
        publishStateOperator: "inclus",
        selectedPublishStates: [],
        selectedBrands: [],
      },
      defaultOperators: {
        publishState: "inclus",
        brand: "inclus",
        channel: "inclus",
        error: "inclus",
      },
      dateOperators: [
        { value: "supérieur à", label: "Supérieur à" },
        { value: "inférieur à", label: "Inférieur à" },
      ],
      dateConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
      },
      dateMasks: {
        input: "YYYY-MM-DD",
        model: "YYYY-MM-DD",
      },
      dateFilters: {
        stockDate: {
          label: "Date de mise en stock",
          operator: "",
          value1: "",
          value2: "",
        },
        publicationDate: {
          label: "Date de publication",
          operator: "",
          value1: "",
          value2: "",
        },
      },
      operators: [
        { value: "inclus", label: "Inclus" },
        { value: "exclus", label: "Exclus" },
      ],
    };
  },

  computed: {
    ...mapState({
      tableFilters: (state) => state.articlesFilters,
    }),
    ...mapGetters(["isClient"]),

    activeFiltersCount() {
      let count = 0;
      
      // Compter les filtres non-date activés
      Object.keys(this.filters).forEach(key => {
        if (key.startsWith('selected') && Array.isArray(this.filters[key]) && this.filters[key].length > 0) {
          count++;
        }
      });
      
      // Compter les filtres de date activés
      Object.values(this.dateFilters).forEach((date) => {
        if (date.value1 || date.value2) count++;
      });
      
      return count;
    },

    hasActiveFilters() {
      return this.activeFiltersCount > 0;
    },

    dateFormatPlaceholder() {
      return this.$i18n.locale === "en" ? "YYYY-MM-DD" : "JJ/MM/AAAA";
    },
  },

  methods: {
    toggleSidebar() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        document.body.style.overflow = "hidden";
      }
    },

    closeSidebar() {
      this.isOpen = false;
      document.body.style.overflow = "auto";
    },

    resetAllFilters() {
      // Réinitialiser tous les filtres
      Object.keys(this.filters).forEach(key => {
        if (key.endsWith('Operator')) {
          const filterType = key.replace('Operator', '');
          this.filters[key] = this.defaultOperators[filterType] || "inclus";
        } else if (key.startsWith('selected')) {
          this.filters[key] = [];
        }
      });

      // Réinitialiser les filtres de date
      Object.keys(this.dateFilters).forEach(key => {
        this.dateFilters[key].value1 = "";
        this.dateFilters[key].value2 = "";
        this.dateFilters[key].operator = "";
      });

      this.$emit("filters-change", {});
    },

    // Méthode pour mettre à jour les valeurs de filtre
    updateFilterValue(filterType, value, operator) {
      const operatorKey = `${filterType}Operator`;
      const valuesKey = `selected${filterType.charAt(0).toUpperCase() + filterType.slice(1)}s`;
      
      if (operator) {
        this.filters[operatorKey] = operator;
      }
      
      if (value !== undefined) {
        this.filters[valuesKey] = value;
      }
    },

    // Méthode pour mettre à jour les filtres de date
    updateDateFilter(dateKey, field, value) {
      if (this.dateFilters[dateKey]) {
        this.dateFilters[dateKey][field] = value;
      }
    },

    clearDate(dateKey, inputNumber) {
      if (inputNumber === 1) {
        this.dateFilters[dateKey].value1 = "";
      } else if (inputNumber === 2) {
        this.dateFilters[dateKey].value2 = "";
      }
    },

    formatDateForDisplay(date) {
      if (!date) return "";

      // Si la date est déjà au format YYYY-MM-DD, on la convertit en objet Date
      if (typeof date === "string") {
        const [year, month, day] = date.split("-");

        // Pour l'anglais, garder le format YYYY-MM-DD
        if (this.$i18n.locale === "en") {
          return date;
        }

        // Pour les autres locales (comme le français), format JJ/MM/AAAA
        return `${day}/${month}/${year}`;
      }

      return "";
    },

    applyFilters() {
      const appliedFilters = {};

      // Traiter les filtres de type liste (marques, erreurs, états, etc.)
      const filterTypes = [
        { key: 'brand', operatorKey: 'brandOperator', valuesKey: 'selectedBrands' },
        { key: 'error', operatorKey: 'errorOperator', valuesKey: 'selectedErrors' },
        { key: 'publishState', operatorKey: 'publishStateOperator', valuesKey: 'selectedPublishStates' },
        { key: 'channel', operatorKey: 'channelOperator', valuesKey: 'selectedChannels' }
      ];

      filterTypes.forEach(({ key, operatorKey, valuesKey }) => {
        if (this.filters[valuesKey] && this.filters[valuesKey].length > 0) {
          appliedFilters[`${key}s`] = {
            operator: this.filters[operatorKey] || 'inclus',
            values: this.filters[valuesKey]
          };
        }
      });

      // Traiter les filtres de date
      Object.entries(this.dateFilters).forEach(([key, date]) => {
        if (date.value1 || date.value2) {
          let operator = "";
          if (date.value1 && date.value2) {
            operator = "Entre";
          } else if (date.value1) {
            operator = "supérieur à";
          } else if (date.value2) {
            operator = "inférieur à";
          }
          appliedFilters[key] = {
            operator,
            value1: date.value1 || "",
            value2: date.value2 || "",
          };
        }
      });

      // Vérifier si les filtres contiennent draft/unpublished et l'onglet courant
      const hasDraftOrUnpublished = appliedFilters.publishStates?.values?.some(
        state => state === "draft" || state === "unpublished"
      );
      
      if (hasDraftOrUnpublished && this.currentTab !== "all") {
        appliedFilters._targetTab = "all";
      }

      this.$emit("filters-change", appliedFilters);
      this.closeSidebar();
    },
  },
  created() {
    // Restaurer les filtres depuis les props
    if (Object.keys(this.initialFilters).length) {
      // Restaurer les filtres de liste
      const filterTypes = [
        { apiKey: 'brands', stateKey: 'selectedBrands', operatorKey: 'brandOperator' },
        { apiKey: 'channels', stateKey: 'selectedChannels', operatorKey: 'channelOperator' },
        { apiKey: 'publishStates', stateKey: 'selectedPublishStates', operatorKey: 'publishStateOperator' },
        { apiKey: 'errors', stateKey: 'selectedErrors', operatorKey: 'errorOperator' }
      ];
      
      filterTypes.forEach(({ apiKey, stateKey, operatorKey }) => {
        if (this.initialFilters[apiKey]) {
          this.filters[operatorKey] = this.initialFilters[apiKey].operator;
          this.filters[stateKey] = this.initialFilters[apiKey].values;
        }
      });

      // Restaurer les filtres de date
      ['stockDate', 'publicationDate'].forEach(dateKey => {
        if (this.initialFilters[dateKey]) {
          this.dateFilters[dateKey].value1 = this.initialFilters[dateKey].value1 || '';
          this.dateFilters[dateKey].value2 = this.initialFilters[dateKey].value2 || '';
        }
      });
    }
  },

  watch: {
    initialFilters: {
      handler(newFilters) {
        if (!Object.keys(newFilters).length) {
          this.resetAllFilters();
          return;
        }

        // Mettre à jour les filtres lorsque initialFilters change
        const filterTypes = [
          { apiKey: 'brands', stateKey: 'selectedBrands', operatorKey: 'brandOperator' },
          { apiKey: 'channels', stateKey: 'selectedChannels', operatorKey: 'channelOperator' },
          { apiKey: 'publishStates', stateKey: 'selectedPublishStates', operatorKey: 'publishStateOperator' },
          { apiKey: 'errors', stateKey: 'selectedErrors', operatorKey: 'errorOperator' }
        ];
        
        filterTypes.forEach(({ apiKey, stateKey, operatorKey }) => {
          if (newFilters[apiKey]) {
            this.filters[operatorKey] = newFilters[apiKey].operator;
            this.filters[stateKey] = newFilters[apiKey].values;
          } else {
            this.filters[operatorKey] = this.defaultOperators[operatorKey.replace('Operator', '')] || 'inclus';
            this.filters[stateKey] = [];
          }
        });

        // Mettre à jour les filtres de date
        ['stockDate', 'publicationDate'].forEach(dateKey => {
          if (newFilters[dateKey]) {
            this.dateFilters[dateKey].value1 = newFilters[dateKey].value1 || '';
            this.dateFilters[dateKey].value2 = newFilters[dateKey].value2 || '';
          } else {
            this.dateFilters[dateKey].value1 = '';
            this.dateFilters[dateKey].value2 = '';
          }
        });
      },
      deep: true,
    },
    
    currentTab(newVal) {
      if (newVal !== "all") {
        // Réinitialiser les filtres de publishState si on n'est pas sur 'all'
        this.filters.selectedPublishStates = [];
        this.filters.publishStateOperator = "inclus";
        // Appliquer les filtres pour mettre à jour
        this.applyFilters();
      }
    },
  },
  beforeDestroy() {
    document.body.style.overflow = "auto";
  },
};
</script>

<style scoped>
.filter-container {
  position: relative;
}

.btn {
  border: none;
  background: none;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.btn-filter {
  border: 1px solid #e0e0e0;
  background: white;
  padding: 10px 16px;
  transition: all 0.2s ease;
  position: relative;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.btn-filter:hover {
  background: #f8f8f8;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.filter-count {
  background: #000;
  color: white;
  padding: 2px 6px;
  border-radius: 99px;
  font-size: 10px;
  font-weight: 500;
}

.filter-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
}

.filter-sidebar {
  width: 400px;
  background: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  animation: slideIn 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: -4px 0 16px rgba(0, 0, 0, 0.1);
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.sidebar-header {
  padding: 20px;
  border-bottom: 1px solid #e9ecef;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidebar-header h3 {
  font-size: 18px;
  font-weight: 600;
  color: #1a1a1a;
}

.header-actions {
  display: flex;
  gap: 16px;
}

.btn-reset {
  color: #dc3545;
  font-weight: 500;
  padding: 6px 12px;
  border-radius: 4px;
  transition: background 0.2s;
  cursor: pointer;
}

.btn-reset:hover {
  background: rgba(220, 53, 69, 0.1);
}

.btn-close {
  font-size: 24px;
  color: #666;
}

.sidebar-content {
  flex: 1;
  overflow-y: auto;
}

.no-filters {
  padding: 20px;
  text-align: center;
  color: #666;
}

.sidebar-footer {
  padding: 20px;
  border-top: 1px solid #e9ecef;
}

.btn-apply {
  width: 100%;
  transition: all 0.2s;
  border-radius: 8px;
}

/* Personnalisation des scrollbars */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #999;
}

/* Styles responsives */
@media (max-width: 480px) {
  .filter-sidebar {
    width: 100%;
  }
}
</style>