<template>
  <TemplateTable
    :title="$t('menu.soldArticles')"
    :text="$t('soldArticles.description')"
    name="soldArticles"
    :url="$listRoutes.ordersArticles"
    :table="soldArticles"
    :filters="soldArticlesFilters"
    :fields="fields"
  />
</template>

<script>
import { mapState } from "vuex";
import TemplateTable from "@/components/templates/TemplateTable";
export default {
  name: "OrdersArticles",
  components: { TemplateTable },
  data() {
    return {
      fields: [
        { key: "shopifyId", type: "shopifyProduct", size: "m" },
        { key: "shopifyTitle", size: "xxl" },
        { key: "wear.sku", size: "m", type: "copy" },
        { key: "shopifyOrderId", type: "shopifyOrder", size: "m" },
        { key: "client", type: "client", size: "l" },
        { key: "sellDate", type: "date" },
        { key: "user", type: "userEmail", size: "xl" },
        //{key: 'shopifyPriceNoTax', type: 'formatPrice'},
        { key: "shopifyPriceNoTax", type: "formatPrice" },
        { key: "shopifyPriceTax", type: "formatPrice" },
        { key: "shopifyPriceWithTax", type: "formatPrice" },
        { key: "amountCredit", type: "formatPrice" },
        { key: "sourceAchat", type: "tagType" },
        { key: "sourceVente", type: "tagType" },
        {
          key: "order.source",
          type: "tagType",
          translationKey: "canalVente",
          size: "m",
        },
        { key: "tradeinCountry" },
        { key: "warehouseCountry" },
        { key: "saleCountry" },
        {
          key: "condition.name",
          translationKey: "condition",
          type: "tagType",
          size: "m",
        },
      ],
    };
  },
  computed: {
    ...mapState(["soldArticles", "soldArticlesFilters"]),
  },
};
</script>
