<template>
  <section>
    <PageBack />
    <PageHeader :title="$t('resale_shipping.title')" />
    <Form
      :init-on-success="true"
      :fields="fields"
      ref="form"
      :action="createResaleShipping"
      @success="submit"
    />
    <Popin :title="$t('shippingLabel.creation.success')" ref="popin">
      <template v-slot:content>
        <ButtonDownload
          @click.native="$refs.popin.close()"
          size="m"
          color="grey"
          :url="shippingLabel?.url"
          filename="shipping_label.pdf"
        >
          {{ $t("global.download") }}
        </ButtonDownload>
      </template>
    </Popin>
  </section>
</template>

<script>
import PageHeader from "@/components/ui/page/PageHeader";
import Popin from "@/components/ui/popin/Popin";
import Form from "@/components/ui/form/Form";
import PageBack from "@/components/ui/page/PageBack";
import { mapActions, mapGetters, mapState } from "vuex";
import ButtonDownload from "@/components/ui/button/ButtonDownloadPdf";

export default {
  name: "ResalesShippingCreate",
  components: { ButtonDownload, PageBack, Form, PageHeader, Popin },
  data() {
    return {
      user: null,
      loading: false,
      shippingLabel: null,
      // Définir les pays par devise
      countriesByZone: {
        euro: [
          { name: this.$t("countries_code.FR"), value: "FR" },
          { name: this.$t("countries_code.DE"), value: "DE" },
          { name: this.$t("countries_code.NL"), value: "NL" },
          { name: this.$t("countries_code.ES"), value: "ES" },
          { name: this.$t("countries_code.BE"), value: "BE" },
          { name: this.$t("countries_code.PT"), value: "PT" },
          { name: this.$t("countries_code.AT"), value: "AT" },
          { name: this.$t("countries_code.LU"), value: "LU" },
          { name: this.$t("countries_code.IT"), value: "IT" },
          { name: this.$t("countries_code.CH"), value: "CH" },
        ],
        pound: [{ name: this.$t("countries_code.GB"), value: "GB" }],
      },
      fields: [
        { name: "client", type: "select", required: true, options: [] },
        { name: "firstName", type: "text", required: true },
        { name: "lastName", type: "text", required: true },
        { name: "phone", type: "text", required: true },
        { name: "email", type: "email", required: true },
        { name: "address1", type: "text", required: true },
        { name: "address2", type: "text", required: false },
        { name: "postalCode", type: "text", required: true, label: "zip" },
        { name: "city", type: "text", required: true },
        {
          name: "country",
          type: "select",
          required: true,
          options: [], // Les options seront mises à jour en fonction de la devise
        },
        { name: "isReturn", type: "checkbox" },
      ],
      dataInitialized: false,
    };
  },
  computed: {
    ...mapGetters(["userClients", "currentCurrency", "getEconomicZoneClients"]),
    ...mapState(["clients", "loadingEconomicZoneClients"]),
  },
  methods: {
    ...mapActions(["createResaleShipping"]),

    updateFormOptions() {
      // Mise à jour des clients
      if (this.getEconomicZoneClients && this.getEconomicZoneClients.length) {
        this.fields[0].options = this.getEconomicZoneClients;
      } else if (this.userClients && this.userClients.length) {
        this.fields[0].options = this.userClients;
      } else {
        this.fields[0].options = this.clients;
      }

      // Mise à jour des pays
      const currencyKey = this.currentCurrency === "GBP" ? "pound" : "euro";
      this.fields[9].options = this.countriesByZone[currencyKey] || [];
    },

    submit(data) {
      this.shippingLabel = data;
      this.$refs.popin.open();
    },
  },
  watch: {
    // Surveiller les données qui peuvent affecter les options du formulaire
    getEconomicZoneClients() {
      this.updateFormOptions();
    },
    currentCurrency() {
      // La mise à jour des clients basée sur la currency est gérée par le store
      // Nous mettons simplement à jour les options de pays
      this.updateFormOptions();
    },
  },
  mounted() {
    // Mettre à jour les options du formulaire une fois que tout est chargé
    this.updateFormOptions();
  },
};
</script>

<style lang="scss">
.resale-shipping-create {
  display: flex;
  align-items: flex-start;

  & > form {
    width: 100%;
    max-width: 600px;
  }

  .grid-card {
    flex-shrink: 0;
    margin-left: 30px;
    position: sticky;
    top: 15px;
    margin-top: 20px;
  }

  .user-card {
    pointer-events: none;
    user-select: text;
  }

  .grid-content {
    & > svg {
      width: 80px;
      margin: auto;
      display: block;
      --svg-color: var(--color-text-grey);
    }
  }
}
</style>
