<template>
  <div class="article-details">
    <!-- Header avec navigation et actions -->
    <div class="header">
      <div class="header__left">
        <button class="btn-back" @click="goBack">
          <span class="icon">←</span>
          {{ article.reference }}
        </button>
        <StatusCell :status="article.status" />
      </div>
      <div class="header__right">
        <!-- Signaler un problème : disabled si statut === 'En vente' -->
        <button
          v-if="!isClient"
          class="btn btn-red"
          :disabled="isOnSale"
          @click="openReportModal"
        >
          {{ $t("article_put_online_details.buttons.error_reported") }}
        </button>

        <!-- Dépublier : disabled si statut === 'Dépublié' -->
        <button
          v-if="!isClient"
          class="btn btn-secondary"
          :disabled="isUnpublished || isDraft || isToPublish || isToCorrect"
          @click="handleUnpublish"
        >
          {{ $t("article_put_online_details.buttons.unpublished") }}
          <SvgIcon name="hide" />
        </button>

        <!-- Publier : disabled si statut === 'En vente' -->
        <button
          v-if="!isClient"
          class="btn btn-primary"
          :disabled="isOnSale"
          @click="handlePublish"
        >
          {{ $t("article_put_online_details.buttons.published") }}
        </button>

        <!-- Nouveau bouton -->
        <div class="btn-nav">
          <div class="btn-nav">
            <!-- Bouton précédent encapsulé dans le composant CustomTooltip -->
            <CustomTooltip
              :disabled="
                isClient &&
                !(savedFilters.stockDate || savedFilters.publicationDate)
              "
              :title="$t('article_put_online_details.tooltips.filters_apply')"
              position="top"
            >
              <!-- Bouton déclencheur -->
              <template #default>
                <button
                  class="btn btn-secondary"
                  @click="goToPreviousProduct"
                  :disabled="isFirstProduct"
                  ref="prevBtn"
                >
                  <SvgIcon name="left" />
                </button>
              </template>
              <!-- Contenu du tooltip -->
              <template #content>
                <div v-if="hasActiveFilters">
                  <div v-if="savedFilters.brands">
                    <!-- Affiche la marque -->
                    <StatusCell :status="getBrandsLabel" />
                  </div>
                  <div v-if="currentTab && currentTab !== 'all' && !isClient">
                    <!-- Affiche le statut ou le tab -->
                    <StatusCell :status="getStatusFromTab(currentTab)" />
                  </div>
                  <div v-if="savedFilters.stockDate">
                    <!-- Affiche la date de mise en stock -->
                    <StatusCell :status="stockDateDisplay" />
                  </div>
                  <div v-if="savedFilters.publicationDate">
                    <!-- Affiche la date de publication -->
                    <StatusCell :status="publicationDateDisplay" />
                  </div>
                  <div v-if="savedFilters.errors">
                    <!-- Affiche les erreurs -->
                    <StatusCell
                      :status="`Errors: ${savedFilters.errors.values.join(
                        ', '
                      )}`"
                    />
                  </div>
                </div>
                <div v-else>
                  {{
                    $t("article_put_online_details.tooltips.filters_no_apply")
                  }}
                </div>
              </template>
            </CustomTooltip>

            <!-- Bouton suivant encapsulé dans CustomTooltip -->
            <CustomTooltip
              :disabled="
                isClient &&
                !(savedFilters.stockDate || savedFilters.publicationDate)
              "
              :title="$t('article_put_online_details.tooltips.filters_apply')"
              position="top"
            >
              <template #default>
                <button
                  class="btn btn-secondary"
                  @click="goToNextProduct"
                  ref="nextBtn"
                >
                  <SvgIcon name="right" />
                </button>
              </template>
              <template #content>
                <div v-if="hasActiveFilters">
                  <div v-if="savedFilters.brands">
                    <StatusCell :status="getBrandsLabel" />
                  </div>
                  <div v-if="currentTab && currentTab !== 'all' && !isClient">
                    <StatusCell :status="getStatusFromTab(currentTab)" />
                  </div>
                  <div v-if="savedFilters.stockDate">
                    <StatusCell :status="stockDateDisplay" />
                  </div>
                  <div v-if="savedFilters.publicationDate">
                    <StatusCell :status="publicationDateDisplay" />
                  </div>
                  <div v-if="savedFilters.errors">
                    <StatusCell
                      :status="`Errors: ${savedFilters.errors.values.join(
                        ', '
                      )}`"
                    />
                  </div>
                </div>
                <div v-else>
                  {{
                    $t("article_put_online_details.tooltips.filters_no_apply")
                  }}
                </div>
              </template>
            </CustomTooltip>
          </div>
        </div>

        <!-- Modal de signalement -->
        <BaseModal
          v-if="isReportModalOpen"
          :title="$t('article_put_online_details.report_modal.title')"
          :subtitle="$t('article_put_online_details.report_modal.subtitle')"
          @close="handleReportClose"
          @submit="handleReportSubmit"
          :submitDisabled="$refs.reportForm?.isSubmitDisabled"
          :submitText="$t('article_put_online_details.report_modal.submit')"
          :cancelText="$t('article_put_online_details.report_modal.cancel')"
        >
          <template v-slot:content>
            <ReportProblemForm
              ref="reportForm"
              :article-id="articleData?.articleCard?.id || ''"
              :existing-errors="article.reportReasons"
              @submit="handleReportSubmit"
            />
          </template>
        </BaseModal>
      </div>
    </div>
    <div class="info-update">
      <div class="update-date">
        <span class="update-label"
          >{{
            $t("article_put_online_details.last_update") ||
            "Dernière mise à jour"
          }}:</span
        >
        <span class="update-value">{{
          articleData?.articleCard?.updatedAt
            ? formatRelativeTime(articleData.articleCard.updatedAt)
            : "-"
        }}</span>
      </div>
    </div>
    <!-- Language selection -->
    <div class="tabs-wrapper">
      <div class="tabs">
        <button
          v-for="lang in languages"
          :key="lang.code"
          @click="currentLang = lang.code"
          :class="['tab-button', { active: lang.code === currentLang }]"
        >
          <img :src="lang.flag" :alt="lang.name" class="lang-flag" />
          {{ lang.name }}
        </button>
      </div>
    </div>

    <!-- Main content -->
    <div class="content-wrapper">
      <!-- Left column -->
      <div class="main-content">
        <div class="section">
          <div class="section_header">
            <h2>
              {{ $t("article_put_online_details.section.controled.title") }}
            </h2>
          </div>
          <div class="section_containt">
            <div class="form-group">
              <div class="input-container">
                <label>{{
                  $t("article_put_online_details.section.controled.input.title")
                }}</label>
                <textarea
                  v-model="article.title"
                  @focus="handleFocus('title')"
                  @blur="handleBlur('title')"
                  ref="titleTextarea"
                ></textarea>
              </div>
            </div>

            <div class="form-group">
              <div class="input-container">
                <label>{{
                  $t(
                    "article_put_online_details.section.controled.input.description"
                  )
                }}</label>
                <vue-editor
                  v-model="article.description"
                  @focus="handleFocus('description')"
                  @blur="handleBlur('description')"
                  :editor-toolbar="customToolbar"
                />
              </div>
            </div>

            <div class="form-group">
              <div class="input-container">
                <label>{{
                  $t(
                    "article_put_online_details.section.controled.input.material"
                  )
                }}</label>
                <textarea
                  v-model="article.material"
                  @focus="handleFocus('material')"
                  @blur="handleBlur('material')"
                  ref="materialTextarea"
                ></textarea>
              </div>
            </div>

            <div class="form-group">
              <div class="input-container" :class="{ disabled: true }">
                <label>
                  {{
                    $t(
                      "article_put_online_details.section.controled.input.priceResale"
                    )
                  }}
                </label>
                <input type="text" :value="formattedPriceResale" disabled />
              </div>
            </div>

            <div class="form-group">
              <div class="input-container" :class="{ disabled: true }">
                <label>{{
                  $t("article_put_online_details.section.controled.input.color")
                }}</label>
                <input type="text" v-model="article.color" disabled />
              </div>
            </div>

            <div class="form-group">
              <div class="input-container" :class="{ disabled: true }">
                <label>{{
                  $t(
                    "article_put_online_details.section.controled.input.collection"
                  )
                }}</label>
                <input type="text" v-model="article.collection" disabled />
              </div>
            </div>

            <div class="section_containt-photos">
              <h2>
                {{
                  $t(
                    "article_put_online_details.section.controled.input.photos"
                  )
                }}
              </h2>
              <PhotosManager
                :photos="article.photos"
                :article-id="article.id"
                @update:photos="handlePhotosUpdate"
              />
            </div>
          </div>
        </div>

        <div class="section">
          <div class="section_header">
            <h2>
              {{
                $t(
                  "article_put_online_details.section.additional_informations.title"
                )
              }}
            </h2>
          </div>
          <div class="section_containt">
            <div class="info-list">
              <div v-if="article?.gender" class="info-row">
                <label>{{
                  $t(
                    "article_put_online_details.section.additional_informations.Genre"
                  )
                }}</label>
                <span>{{ article?.gender }}</span>
              </div>
              <div v-if="article?.category" class="info-row">
                <label>{{
                  $t(
                    "article_put_online_details.section.additional_informations.category"
                  )
                }}</label>
                <span>{{ article?.category }}</span>
              </div>
              <div v-if="article?.univers" class="info-row">
                <label>{{
                  $t(
                    "article_put_online_details.section.additional_informations.Univers"
                  )
                }}</label>
                <span>{{ article?.univers }}</span>
              </div>
              <div v-if="article?.type" class="info-row">
                <label>{{
                  $t(
                    "article_put_online_details.section.additional_informations.type"
                  )
                }}</label>
                <span>{{ article?.type }}</span>
              </div>
              <div v-if="article?.season" class="info-row">
                <label>{{
                  $t(
                    "article_put_online_details.section.additional_informations.Season"
                  )
                }}</label>
                <span>{{ article?.season }}</span>
              </div>
              <div v-if="article?.year" class="info-row">
                <label>{{
                  $t(
                    "article_put_online_details.section.additional_informations.year"
                  )
                }}</label>
                <span>{{ article?.year }}</span>
              </div>
              <div v-if="article?.colorSpecific" class="info-row">
                <label>{{
                  $t(
                    "article_put_online_details.section.additional_informations.specific_color"
                  )
                }}</label>
                <span>{{ article?.colorSpecific }}</span>
              </div>
              <div v-if="article?.color" class="info-row">
                <label>{{
                  $t(
                    "article_put_online_details.section.additional_informations.color_filters"
                  )
                }}</label>
                <span>{{ article?.color }}</span>
              </div>
              <div v-if="article?.size" class="info-row">
                <label>{{
                  $t(
                    "article_put_online_details.section.additional_informations.size"
                  )
                }}</label>
                <span>{{ article?.size }}</span>
              </div>
              <div v-if="article?.sizeFilter" class="info-row">
                <label>{{
                  $t(
                    "article_put_online_details.section.additional_informations.size_filters"
                  )
                }}</label>
                <span>{{ article?.sizeFilter }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Right column -->
      <div class="side-content">
        <div class="section">
          <div class="section_header">
            <h2>
              {{
                $t("article_put_online_details.section.item_informations.title")
              }}
            </h2>
          </div>
          <div class="section_containt">
            <div class="info-list">
              <div class="info-row">
                <label>ID</label>
                <span>{{ article.reference }}</span>
              </div>
              <div class="info-row">
                <label>{{
                  $t(
                    "article_put_online_details.section.item_informations.brand"
                  )
                }}</label>
                <span>{{ article.brand }}</span>
              </div>
              <div class="info-row">
                <label>EAN</label>
                <span>{{ article.ean }}</span>
              </div>
              <div class="info-row">
                <label>SKU</label>
                <span>{{ article.sku }}</span>
              </div>
              <div class="info-row">
                <label>{{
                  $t(
                    "article_put_online_details.section.item_informations.in-stock"
                  )
                }}</label>
                <span>{{ stockDate }}</span>
              </div>
              <div class="info-row">
                <label>{{
                  $t(
                    "article_put_online_details.section.item_informations.publication"
                  )
                }}</label>
                <span>{{ publishDate }}</span>
              </div>
              <div class="info-row">
                <label>{{
                  $t(
                    "article_put_online_details.section.item_informations.item_condition"
                  )
                }}</label>
                <span>{{ $t("global." + article.condition) }}</span>
              </div>
              <div class="info-row">
                <label>{{
                  $t(
                    "article_put_online_details.section.item_informations.item_status"
                  )
                }}</label>
                <StatusCell :status="article.status" />
              </div>
              <div class="info-row report-reasons">
                <label>
                  {{
                    $t(
                      "article_put_online_details.section.item_informations.report_reasons"
                    )
                  }}</label
                >
                <div class="error-tags">
                  <ErrorsCell :errors="uniqueReportReasons" />
                </div>
              </div>

              <div class="info-row">
                <label>{{
                  $t(
                    "article_put_online_details.section.item_informations.see_item"
                  )
                }}</label>
                <a
                  :href="
                    $router.resolve({
                      name: 'recom_tradein-n-sell-article',
                      params: { id: article.id },
                    }).href
                  "
                  target="_blank"
                  class="view-link"
                >
                  {{
                    $t(
                      "article_put_online_details.section.item_informations.see_item_link"
                    )
                  }}
                </a>
              </div>
              <div class="info-row">
                <label>{{
                  $t(
                    "article_put_online_details.section.item_informations.see_item_online"
                  )
                }}</label>
                <a
                  v-if="article.articleFrontUrl && article.status === 'on_sale'"
                  :href="formattedArticleFrontUrl"
                  target="_blank"
                  class="view-link"
                >
                  {{
                    $t(
                      "article_put_online_details.section.item_informations.see_item_online_link"
                    )
                  }}
                </a>
                <span v-else>-</span>
              </div>
              <!-- Add other info rows -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <PopinNotification ref="notification" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";
import currencyRouteMixin from "@/mixins/currencyRouteMixin";
import dateFormatMixin from "@/mixins/dateFormatMixin";

import PopinNotification from "@/components/ui/popin/PopinNotification.vue";
import BaseModal from "@/components/ui/modals/BaseModal.vue"; // Ajustez le chemin d'import selon votre structure
import ReportProblemForm from "@/components/ui/dataTable/ReportProblemForm.vue";
import StatusCell from "@/components/ui/dataTable/StatusCell.vue";
import PhotosManager from "@/components/photo/PhotosManager";
import SvgIcon from "@/components/ui/svg/SvgIcon.vue";
import ErrorsCell from "@/components/ui/dataTable/ErrorsCell.vue";
import CustomTooltip from "@/components/ui/tootip/CustomTooltip.vue";
export default {
  name: "ConfigWebsiteCatalogueArticleDetailsBis",

  components: {
    PhotosManager,
    VueEditor,
    PopinNotification,
    StatusCell,
    SvgIcon,
    BaseModal,
    ReportProblemForm,
    ErrorsCell,
    CustomTooltip,
  },
  mixins: [currencyRouteMixin, dateFormatMixin],

  data() {
    return {
      currentLang: null,
      customToolbar: [
        ["bold", "italic"],
        [{ list: "bullet" }, { list: "ordered" }],
      ],
      originalValues: {
        title: "",
        description: "",
        material: "",
      },
      savedFilters: {},
      isReportModalOpen: false,
    };
  },

  computed: {
    ...mapState({
      loading: (state) => state.articleLoading,
      articleData: (state) => state.article,
      allProducts: (state) => state.articles?.items || [],
      tableFilters: (state) => state.articlesFilters || [],
    }),

    ...mapGetters(["isClient"]),

    articleId() {
      return this.$route.params.id;
    },

    article() {
      if (!this.articleData) {
        return {
          id: "",
          title: "",
          description: "",
          material: "",
          priceResale: "",
          color: "",
          collection: "",
          status: "",
          condition: "",
          brand: "",
          ean: "",
          photos: [],
          sku: "",
          gender: "",
          category: "",
          univers: "",
          type: "",
          season: "",
          year: "",
          colorSpecific: "",
          size: "",
          sizeFilter: "",
          articleFrontUrl: "",
          reportReasons: [],
        };
      }

      const currentMetadata = this.articleData.metadata?.[this.currentLang];

      return {
        id: this.articleData.id,
        reference: this.articleData.reference,
        title: currentMetadata?.title || "",
        description: currentMetadata?.description || "",
        material: currentMetadata?.composition || "",
        priceResale: this.articleData.priceResale || "",
        color: currentMetadata?.color_filters || "",
        colorSpecific: currentMetadata?.color || "",
        collection: currentMetadata?.collection || "",
        status: this.articleData.articleCard?.state || "",
        condition: this.articleData.condition || "",
        brand: this.articleData.brand?.name || "",
        ean: this.articleData.ean || "",
        sku: this.articleData.sku || "",
        gender: currentMetadata?.gender || "",
        category: currentMetadata?.category || "",
        type: currentMetadata.type || "",
        univers: currentMetadata?.univers || "",
        season: currentMetadata?.season || "",
        year: currentMetadata?.year || "",
        size: currentMetadata?.size || "",
        sizeFilter: currentMetadata?.size_filters || "",
        articleFrontUrl: this.articleData.articleFrontUrl
          ? `${this.articleData.articleFrontUrl}${
              currentMetadata?.color
                ? `-${currentMetadata.color
                    .toLowerCase()
                    .split(",")
                    .map((color) => color.trim().replace(/\s+/g, "-"))
                    .join("-")}`
                : ""
            }`
          : "",
        photos:
          this.articleData?.photos?.map((photo) => ({
            url: photo.url,
            id: photo.id,
            isPrimary: false,
            deletedAt: photo.deletedAt,
          })) || [],
        reportReasons: this.articleData.reportReasons || [],
      };
    },

    uniqueReportReasons() {
      const reasons = this.article?.reportReasons || [];
      return Object.values(
        reasons.reduce((acc, curr) => {
          if (!acc[curr.reason]) {
            acc[curr.reason] = curr;
          }
          return acc;
        }, {})
      );
    },

    defaultLanguage() {
      return this.articleData?.brand?.defaultLocaleCode || "fr_FR";
    },

    languages() {
      if (!this.articleData?.metadata) return [];

      const langs = Object.keys(this.articleData.metadata)
        .filter((key) => key !== "photos")
        .map((code) => ({
          code,
          name: this.$t(`locales.${code.split("_")[0].toLowerCase()}`),
          flag: `https://flagcdn.com/${code.split("_")[1].toLowerCase()}.svg`,
        }));

      // Trier les langues pour mettre la langue par défaut en premier
      return langs.sort((a, b) => {
        if (a.code === this.defaultLanguage) return -1;
        if (b.code === this.defaultLanguage) return 1;
        return 0;
      });
    },

    formattedPriceResale() {
      if (!this.article.priceResale) return "";
      const priceInUnits = this.article.priceResale / 100;

      const currencyCode =
        this.currentCurrency && this.currentCurrency.toLowerCase() === "euro"
          ? "EUR"
          : this.currentCurrency.toUpperCase();


      const locale = this.$i18n.locale || "fr-FR";
      return new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currencyCode,
        currencyDisplay: "narrowSymbol",
      }).format(priceInUnits);
    },

    formattedArticleFrontUrl() {
      let url = this.article.articleFrontUrl || "";
      const defaultLocale = this.defaultLanguage;
      const selectedLocale = this.currentLang;


      // Fonction pour supprimer les accents
      const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      };

      // Supprimer les accents de l'URL
      url = removeAccents(url);

      try {
        // Analyser l'URL correctement
        const parsedUrl = new URL(url);

        // Obtenir le chemin de l'URL
        let path = parsedUrl.pathname;

        // Retirer le code de langue s'il existe
        path = path.replace(/\/[A-Za-z]{2}_[A-Za-z]{2}(\/|$)/, "/");

        // Si le chemin contient des segments après "products/"
        if (path.includes("/products/")) {
          // Diviser le chemin en segments
          const pathSegments = path.split("/");

          // Reconstruire le chemin avec les segments appropriés
          // 0: '', 1: 'products', 2+: autres segments
          let basePath = "/" + pathSegments[1] + "/"; // '/products/'

          // Joindre les autres segments avec des tirets uniquement s'ils contiennent des '/'
          const remainingSegments = pathSegments.slice(2).join("/");

          // Remplacer les '/' par des '-' uniquement dans les segments restants
          const formattedSegments = remainingSegments.replace(/\//g, "-");

          // Construire le nouveau chemin
          path = basePath + formattedSegments;
        }

        // Mettre à jour le chemin de l'URL
        parsedUrl.pathname = path;

        // Ajouter le code de langue si nécessaire
        if (selectedLocale !== defaultLocale) {
          parsedUrl.pathname = `/${selectedLocale}${parsedUrl.pathname}`;
        }

        return parsedUrl.toString();
      } catch (e) {
        console.error("Erreur lors du formatage de l'URL:", e);
        return url; // En cas d'erreur, retourner l'URL d'origine
      }
    },
    currentProductIndex() {
      return this.allProducts.findIndex(
        (product) => product.reference === this.articleId
      );
    },

    isFirstProduct() {
      const currentIndex = this.allProducts.findIndex(
        (item) => item.id === this.articleId
      );
      return currentIndex === 0;
    },

    stockDate() {
      if (!this.articleData?.activities?.length) return null;

      // On trouve la dernière activité 'stocked'
      const stockActivity = [...this.articleData.activities]
        .reverse()
        .find((activity) => activity.event === "stocked");

      return stockActivity
        ? this.formatDateForDisplay(stockActivity.createdAt)
        : "-";
    },

    stockDateDisplay() {
      const sd = this.savedFilters.stockDate;
      if (!sd || !sd.value1) return "";
      const date1 = this.formatDateForDisplay(sd.value1);
      const date2 = this.formatDateForDisplay(sd.value2);
      const label = this.$t("article_put_online.stocked");
      return date2 !== ""
        ? `${label}: ${date1} → ${date2}`
        : `${label}: ${date1}`;
    },
    publicationDateDisplay() {
      const pd = this.savedFilters.publicationDate;
      if (!pd || !pd.value1) return "";
      const date1 = this.formatDateForDisplay(pd.value1);
      const date2 = this.formatDateForDisplay(pd.value2);
      const label = this.$t("article_put_online.published");
      return date2 !== ""
        ? `${label}: ${date1} → ${date2}`
        : `${label}: ${date1}`;
    },
    publishDate() {
      if (!this.articleData?.activities?.length) return null;

      // On trouve la dernière activité 'published'
      const publishActivity = [...this.articleData.activities]
        .reverse()
        .find((activity) => activity.event === "published");

      return publishActivity
        ? this.formatDateForDisplay(publishActivity.createdAt)
        : "-";
    },
    hasActiveFilters() {
      return (
        (this.savedFilters.brands &&
          this.savedFilters.brands.values.length > 0) ||
        (this.currentTab && this.currentTab !== "all")
      );
    },

    getBrandsLabel() {
      if (!this.savedFilters.brands) return "";

      const brandFilter = (this.tableFilters || []).find(
        (filter) => filter.key === "brand"
      );

      const brandLabels = this.savedFilters.brands.values.map((brandId) => {
        const brand = brandFilter?.options?.find(
          (opt) => opt.id.toString() === brandId.toString()
        );
        return brand?.label || brandId;
      });

      return brandLabels.join(", ");
    },

    isOnSale() {
      return this.article.status === "on_sale";
    },
    isUnpublished() {
      return this.article.status === "unpublished";
    },
    isDraft() {
      return this.article.status === "draft";
    },
    isToPublish() {
      return this.article.status === "to_publish";
    },
    isToCorrect() {
      return this.article.status === "to_correct";
    },
  },

  methods: {
    ...mapActions([
      "fetchArticle",
      "fetchArticles",
      "updateArticleMetadata",
      "fetchArticleReport",
      "updateArticleState",
    ]),
    goBack() {
      const savedFilters = localStorage.getItem("savedTableFilters");
      if (savedFilters) {
        let { filters, tab, page, limit, selectedItems } =
          JSON.parse(savedFilters);

        if (tab === "unpublished" || tab === "draft") {
          tab = "all";
        }

        this.$router.push({
          name: "config-website-catalogues-articles-listing-all-bis",
          query: {
            filters,
            tab,
            page,
            limit,
            "currency:in": this.currentCurrency,
            selectedItems,
          },
        });

        // localStorage.removeItem("savedTableFilters");
      } else {
        this.$router.push({
          name: "config-website-catalogues-articles-listing-all-bis",
        });
      }
    },
    handleInput(value) {
      this.$emit("input", value);
    },

    handleFocus(field) {
      this.originalValues[field] = this.article[field];
    },

    getFieldLabel(field) {
      // Définis un mapping pour chaque champ avec le genre
      const labels = {
        title: { fr: { text: "Le titre", gender: "m" }, en: "The title" },
        description: {
          fr: { text: "La description", gender: "f" },
          en: "The description",
        },
        material: {
          fr: { text: "La composition", gender: "f" },
          en: "The composition",
        },
      };

      const locale = this.$i18n.locale.startsWith("fr") ? "fr" : "en";

      // Pour l'anglais on retourne juste le texte
      if (locale === "en") {
        return labels[field]?.en || field;
      }

      // Pour le français on retourne l'objet complet avec le genre
      return labels[field]?.fr || { text: field, gender: "m" };
    },
    handleBlur(field) {
      if (this.originalValues[field] === this.article[field]) return;

      const currentLangBackup = this.currentLang;
      const fieldMap = {
        title: "title",
        description: "description",
        material: "composition",
      };

      const metadata = {
        ...this.articleData.metadata,
        [this.currentLang]: {
          ...this.articleData.metadata[this.currentLang],
          [fieldMap[field]]: this.article[field],
        },
      };

      this.updateArticleMetadata({
        id: this.article.id,
        metadata,
        lang: this.currentLang,
      }).then(() => {
        this.currentLang = currentLangBackup;

        const fieldLabel = this.getFieldLabel(field);
        // Si on est en anglais, on utilise directement fieldLabel qui est une chaîne
        // Si on est en français, on utilise fieldLabel.text et on vérifie le genre
        const translationKey = this.$i18n.locale.startsWith("fr")
          ? fieldLabel.gender === "f"
            ? "article_put_online.notification_popin.update_metadata_feminine"
            : "article_put_online.notification_popin.update_metadata"
          : "article_put_online.notification_popin.update_metadata";

        this.$refs.notification.open({
          text: this.$t(translationKey, {
            field: this.$i18n.locale.startsWith("fr")
              ? fieldLabel.text
              : fieldLabel,
          }),
          color: "green",
          confirmation: false,
          duration: 3000,
        });
      });
    },
    getStatusFromTab(tab) {
      return tab;
    },

    transformFiltersForAPI(filters) {
      const apiFilters = {};
      if (!filters) return apiFilters;

      // Filtre pour la marque
      if (filters.brands) {
        const operator = filters.brands.operator === "inclus" ? "in" : "nin";
        apiFilters[`brand[${operator}]`] = filters.brands.values.join(",");
      }

      // Filtre pour l'état de publication (ou on utilise le tab actif)
      if (filters.publishStates) {
        const operator =
          filters.publishStates.operator === "inclus" ? "in" : "nin";
        apiFilters[`publishState[${operator}]`] =
          filters.publishStates.values.join(",");
      } else if (this.currentTab !== "all") {
        apiFilters["publishState[in]"] = this.currentTab;
      }

      // Filtre pour les canaux de vente
      if (filters.channels) {
        const operator = filters.channels.operator === "inclus" ? "in" : "nin";
        apiFilters[`channel[${operator}]`] = filters.channels.values.join(",");
      }

      // Filtre pour la date de mise en stock
      if (filters.stockDate) {
        const sd = filters.stockDate;
        if (sd.value1 && sd.value2) {
          // Si deux dates sont renseignées, on envoie gte et lte
          apiFilters["stockAt[gte]"] = sd.value1;
          apiFilters["stockAt[lte]"] = sd.value2;
        } else if (sd.value1) {
          // Si une seule date est renseignée, on se base sur l'opérateur
          if (sd.operator === "supérieur à") {
            apiFilters["stockAt[gte]"] = sd.value1;
          } else if (sd.operator === "inférieur à") {
            apiFilters["stockAt[lte]"] = sd.value1;
          }
        }
      }

      // Filtre pour la date de publication
      if (filters.publicationDate) {
        const pd = filters.publicationDate;
        if (pd.value1 && pd.value2) {
          apiFilters["publishedAt[gte]"] = pd.value1;
          apiFilters["publishedAt[lte]"] = pd.value2;
        } else if (pd.value1) {
          if (pd.operator === "supérieur à") {
            apiFilters["publishedAt[gte]"] = pd.value1;
          } else if (pd.operator === "inférieur à") {
            apiFilters["publishedAt[lte]"] = pd.value1;
          }
        }
      }

      // Filtre pour les erreurs
      if (filters.errors) {
        const operator = filters.errors.operator === "inclus" ? "in" : "nin";
        apiFilters[`reportingError[${operator}]`] =
          filters.errors.values.join(",");
      }

      return apiFilters;
    },

    async goToNextProduct() {
      try {
        const queryParams = {
          page: 1,
          limit: 10,
          "currency[in]": this.currentCurrency,
          ...this.transformFiltersForAPI(this.savedFilters),
        };

        const data = await this.fetchArticles(queryParams);
        if (data?.items?.length) {
          const currentIndex = data.items.findIndex(
            (item) => item.id === this.articleId
          );

          if (currentIndex === data.items.length - 1) {
            const nextProduct = data.items[0];

            this.$store.commit("SET_NOTIFICATION_MESSAGE", {
              text: "Retour au premier article",
              color: "blue",
              confirmation: false,
            });

            this.$router.push({
              name: "config-website-catalogues-articles-details-bis",
              params: { id: nextProduct.id },
            });
          } else {
            const nextProduct = data.items[currentIndex + 1];
            this.$router.push({
              name: "config-website-catalogues-articles-details-bis",
              params: { id: nextProduct.id },
            });
          }
        }
      } catch (error) {
        console.error("Erreur:", error);
      }
    },
    async goToPreviousProduct() {
      try {
        // Construi la requête avec les filtres actifs
        const queryParams = {
          page: 1,
          limit: 100,
          "currency[in]": this.currentCurrency,
          ...this.transformFiltersForAPI(this.savedFilters),
        };

        const data = await this.fetchArticles(queryParams);
        if (data?.items?.length) {
          // Si un filtre de statut est appliqué, nous le récupérons
          // Sinon, on utilise la liste complète.
          const expectedStatus =
            this.savedFilters?.publishStates?.values?.[0] || null;
          const filteredItems = expectedStatus
            ? data.items.filter((item) => item.status === expectedStatus)
            : data.items;

          if (!filteredItems.length) {
            // Aucun article ne correspond au filtre de statut actif.
            // Redirigee vers le premier article de la liste complète.
            this.$router.push({
              name: "config-website-catalogues-articles-details-bis",
              params: { id: data.items[0].id },
            });
            return;
          }

          // Trouve l'indice de l'article courant dans la liste filtrée
          const currentIndex = filteredItems.findIndex(
            (item) => item.id === this.articleId
          );

          let targetProduct;
          if (currentIndex === -1) {
            // Si l'article courant n'est pas trouvé (son statut a pu être modifié),
            // alors on choisit le premier article de la liste filtrée.
            targetProduct = filteredItems[0];
          } else if (currentIndex === 0) {
            // Si l'article courant est le premier, on peut choisir le dernier pour une navigation circulaire.
            targetProduct = filteredItems[filteredItems.length - 1];
          } else {
            // Sinon, on prend le produit précédent dans la liste filtrée.
            targetProduct = filteredItems[currentIndex - 1];
          }

          this.$router.push({
            name: "config-website-catalogues-articles-details-bis",
            params: { id: targetProduct.id },
          });
        }
      } catch (error) {
        console.error(
          "Erreur lors de la navigation vers le produit précédent:",
          error
        );
      }
    },
    getStatusFromState(state) {
      const stateMap = {
        to_publish: "À publier",
        on_sale: "En vente",
        unpublished: "Dépublié",
        to_correct: "À corriger",
        draft: "Brouillon",
      };
      return stateMap[state] || state;
    },

    async loadArticleDataId() {
      try {
        // Chargement de l'article
        const articleData = await this.fetchArticle(this.articleId);

        // Mettre à jour l'article dans le store
        this.$store.commit("SET_ARTICLE", articleData);

        // Si l'article est en état "to_correct", charger les raisons
        if (articleData?.articleCard?.state === "to_correct") {
          await this.$store.dispatch(
            "fetchArticleReport",
            articleData.articleCard.id
          );
        }
      } catch (error) {
        console.warn("Erreur lors du chargement de l'article:", error);
      }
    },

    handleFieldBlur(field) {
      const fieldMap = {
        title: "title",
        description: "description",
        material: "composition",
      };

      const metadata = {
        [fieldMap[field]]: this.article[field],
      };

      this.updateArticleMetadata({
        id: this.article.id,
        metadata,
        lang: this.currentLang,
      });
    },

    handlePhotosUpdate(updatedPhotos) {
      this.$store.commit("SET_ARTICLE_PHOTOS", updatedPhotos);
    },
    adjustHeight(element) {
      // Sauvegarde la valeur de scroll
      const scrollPos = window.pageYOffset;

      element.style.height = "24px";

      if (element.scrollHeight > element.clientHeight) {
        element.style.height = element.scrollHeight + "px";
      }

      // On ajoute un petit padding pour éviter les coupures de texte
      const computed = window.getComputedStyle(element);
      const padding =
        parseInt(computed.paddingTop) + parseInt(computed.paddingBottom);

      // On définit la hauteur en prenant en compte le padding
      element.style.height = element.scrollHeight + padding + "px";

      // Restaure la position de scroll
      window.scrollTo(0, scrollPos);
    },

    autoResize(event) {
      this.adjustHeight(event.target);
    },
    openReportModal() {
      console.log("Ouverture de la modal de signalement");
      this.isReportModalOpen = true;
    },

    async handleReportSubmit(formData) {
      try {
        if (!this.articleData?.articleCard?.id) {
          throw new Error("ID de l'article non disponible");
        }

        const articleCardId = this.articleData.articleCard.id;

        // On n'envoie que les nouvelles erreurs
        const requests = formData.errors.map((error) => {
          return this.$store.dispatch("submitReport", {
            id: articleCardId,
            reason: error.reason,
            description: error.description,
          });
        });

        await Promise.all(requests);

        // Fusionner les nouvelles erreurs avec les erreurs existantes
        const existingReasons = new Map(
          (this.articleData.reportReasons || []).map((reason) => [
            reason.reason,
            reason,
          ])
        );

        // Ajouter les nouvelles erreurs sans dupliquer
        formData.errors.forEach((error) => {
          existingReasons.set(error.reason, {
            reason: error.reason,
            description: error.description,
          });
        });

        // Créer le nouvel objet article avec toutes les erreurs
        const updatedArticleData = {
          ...this.articleData,
          articleCard: {
            ...this.articleData.articleCard,
            state: "to_correct",
             updatedAt: new Date().toISOString(),
          },
          reportReasons: Array.from(existingReasons.values()),
        };

        // Mettre à jour l'article dans le store
        this.$store.commit("SET_ARTICLE", updatedArticleData);

        this.isReportModalOpen = false;
      } catch (error) {
        console.error("Erreur lors du signalement:", error);
        this.$store.commit("SET_NOTIFICATION_MESSAGE", {
          text: this.$t(
            "article_put_online.notification_popin.error_reported_error"
          ),
          color: "red",
          confirmation: true,
          duration: 3000,
        });
      }
    },
    handleReportClose() {
      this.isReportModalOpen = false;
      if (this.$refs.reportForm) {
        this.$refs.reportForm.selectedErrors =
          this.$refs.reportForm.existingErrors;
      }
    },
    async handlePublish() {
      try {
        await this.updateArticleState({
          ids: [this.articleId],
          toStatus: "on_sale",
        });

        // Recharger l'article pour avoir le nouveau statut
        await this.loadArticleDataId();

        // Force la mise à jour de l'UI en modifiant l'article
        if (this.articleData && this.articleData.articleCard) {
          // Créer une copie pour déclencher la réactivité
          const updatedArticleData = { ...this.articleData };
          updatedArticleData.articleCard = {
            ...updatedArticleData.articleCard,
            updatedAt: new Date().toISOString(), // On force une mise à jour immédiate de l'UI
          };
          this.$store.commit("SET_ARTICLE", updatedArticleData);
        }

        // Mettre à jour les filtres sauvegardés
        const savedFilters = localStorage.getItem("savedTableFilters");
        if (savedFilters) {
          const parsedFilters = JSON.parse(savedFilters);
          this.savedFilters = parsedFilters.filters
            ? JSON.parse(parsedFilters.filters)
            : {};
          this.currentTab = parsedFilters.tab || "all";
        }

        // Si on est sur un filtre de statut spécifique, on met à jour la liste filtrée
        if (this.currentTab && this.currentTab !== "all") {
          const queryParams = {
            page: 1,
            limit: 100,
            "currency[in]": this.currentCurrency,
            "publishState[in]": this.currentTab,
          };

          if (this.savedFilters.brands) {
            const operator =
              this.savedFilters.brands.operator === "inclus" ? "in" : "nin";
            queryParams[`brand[${operator}]`] =
              this.savedFilters.brands.values.join(",");
          }

          await this.fetchArticles(queryParams);
        }

        this.$store.commit("SET_NOTIFICATION_MESSAGE", {
          text: this.$t("article_put_online.notification_popin.item_published"),
          color: "green",
          confirmation: true,
          duration: 3000,
        });
      } catch (error) {
        console.error("Erreur lors de la publication:", error);
        this.$store.commit("SET_NOTIFICATION_MESSAGE", {
          text: this.$t(
            "article_put_online.notification_popin.item_published_error"
          ),
          color: "red",
          confirmation: true,
          duration: 3000,
        });
      }
    },

    async handleUnpublish() {
      try {
        await this.updateArticleState({
          ids: [this.articleId],
          toStatus: "unpublished",
        });

        // Recharger l'article pour avoir le nouveau statut
        await this.loadArticleDataId();

        // Force la mise à jour de l'UI en modifiant l'article
        if (this.articleData && this.articleData.articleCard) {
          // Créer une copie pour déclencher la réactivité
          const updatedArticleData = { ...this.articleData };
          updatedArticleData.articleCard = {
            ...updatedArticleData.articleCard,
            updatedAt: new Date().toISOString(),
          };
          this.$store.commit("SET_ARTICLE", updatedArticleData);
        }

        // Même logique de mise à jour des filtres que pour handlePublish
        const savedFilters = localStorage.getItem("savedTableFilters");
        if (savedFilters) {
          const parsedFilters = JSON.parse(savedFilters);
          this.savedFilters = parsedFilters.filters
            ? JSON.parse(parsedFilters.filters)
            : {};
          this.currentTab = parsedFilters.tab || "all";
        }

        if (this.currentTab && this.currentTab !== "all") {
          const queryParams = {
            page: 1,
            limit: 100,
            "currency[in]": this.currentCurrency,
            "publishState[in]": this.currentTab,
          };

          if (this.savedFilters.brands) {
            const operator =
              this.savedFilters.brands.operator === "inclus" ? "in" : "nin";
            queryParams[`brand[${operator}]`] =
              this.savedFilters.brands.values.join(",");
          }

          await this.fetchArticles(queryParams);
        }
      } catch (error) {
        console.error("Erreur lors de la dépublication:", error);
        this.$store.commit("SET_NOTIFICATION_MESSAGE", {
          text: this.$t(
            "article_put_online.notification_popin.item_unpublished_error"
          ),
          color: "red",
          confirmation: true,
          duration: 3000,
        });
      }
    },
  },

  mounted() {
    // Initialiser la hauteur pour tous les textarea au chargement
    this.$nextTick(() => {
      const textareas = document.querySelectorAll("textarea");
      textareas.forEach(this.adjustHeight);
    });
  },

  created() {
    // Charger les filtres
    this.$store.dispatch("getTableFilters", {
      name: "articles",
      url: "new-articles",
    });

    // Récupérer les filtres sauvegardés
    const savedFilters = localStorage.getItem("savedTableFilters");
    if (savedFilters) {
      const { filters, tab } = JSON.parse(savedFilters);
      this.savedFilters = filters ? JSON.parse(filters) : {};
      this.currentTab = tab || "all";
    }
  },

  watch: {
    articleId: {
      immediate: true,
      async handler(newId) {
        if (newId) {
          await this.loadArticleDataId();
        }
      },
    },
    articleData: {
      immediate: true,
      deep: true,
      handler(newArticleData) {
        if (newArticleData?.brand?.defaultLocaleCode) {
          this.currentLang = newArticleData.brand.defaultLocaleCode;
        }
      },
    },
    "article.title"() {
      this.$nextTick(() => {
        if (this.$refs.titleTextarea) {
          this.adjustHeight(this.$refs.titleTextarea);
        }
      });
    },
    "article.description"() {
      this.$nextTick(() => {
        if (this.$refs.descriptionTextarea) {
          this.adjustHeight(this.$refs.descriptionTextarea);
        }
      });
    },
    "article.material"() {
      this.$nextTick(() => {
        if (this.$refs.materialTextarea) {
          this.adjustHeight(this.$refs.materialTextarea);
        }
      });
    },
  },
};
</script>

<style scoped>
.article-details {
  min-height: 100vh;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.header__left,
.header__right {
  display: flex;
  align-items: center;
  gap: 16px;
}

.btn-nav {
  display: flex;
  align-items: center;
  gap: 4px;
}

.btn:hover {
  opacity: 0.6;
}

.btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.btn-back {
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.btn-grey {
  color: #666;
  background-color: #f7f7f7;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.btn-red {
  color: #fe3737;
  background-color: #fff;
  border: none;
  padding: 8px 16px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    background-color: #ffeeed;
    color: #fe3737;
    border-color: #fe3737;
    opacity: 1;
  }
  &:disabled:hover {
    opacity: 0.5;
    color: #fe3737;
    border: 1px solid #e0e0e0;
    background-color: #fff;
  }
}

.btn-primary {
  background: #000;
  color: white;
  border: none;
  border: 1px solid #000;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.btn-secondary {
  background: #fff;
  color: #000;
  border: 1px solid #e0e0e0;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background-color: #f5f5f5;
  }
}
.tabs-wrapper {
  background-color: #f1efec;
  padding: 2px;
  padding-bottom: 4px;
  border-radius: 8px;
  margin-bottom: 24px;
  width: fit-content;
}

.tabs {
  display: flex;
}

.tab-button {
  padding: 12px 24px;
  border: none;
  background: none;
  cursor: pointer;
  position: relative;
  color: #666;
  display: flex;
  align-items: center;
  gap: 8px;
}

.tab-button.active {
  color: #000;
  margin-bottom: -1px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.05);
}

.lang-flag {
  width: 20px;
  height: 20px;
  object-fit: cover;
  border-radius: 4px;
}

.lang-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: 1px solid #e0e0e0;
  background: white;
  border-radius: 6px;
  cursor: pointer;
}

.lang-btn.active {
  background: #f5f5f5;
  border-color: #000;
}

.content-wrapper {
  display: grid;
  grid-template-columns: 1fr 400px;
  gap: 24px;
}

.section {
  background: white;
  border-radius: 8px;
  border: 1px solid #e1e1e1;
  margin-bottom: 24px;
}

.section_header {
  padding: 24px;
  background: #f7f7f7;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 1px solid #e1e1e1;
}

.section_containt {
  padding: 24px;
}

.section h2 {
  font-size: 18px;
}

.section_containt-photos {
  padding-top: 10px;
}

.form-group {
  margin-bottom: 16px;
}

.input-container {
  position: relative;
  background: white;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  padding: 8px 16px;
}

.input-container label {
  display: block;
  font-size: 12px;
  color: #757575;
  margin-bottom: 4px;
}

.input-container input,
.input-container textarea {
  width: 100%;
  border: none;
  font-size: 14px;
  color: #000;
  background: transparent;
  padding: 0;
}

.input-container input:focus,
.input-container textarea:focus {
  outline: none;
}

.input-container textarea {
  width: 100%;
  min-height: 24px;
  border: none;
  font-size: 14px;
  color: #000;
  background: transparent;
  padding: 0;
  resize: none;
  overflow-y: hidden; /* cache la barre de défilement */
  line-height: 1.5;
  display: block;
  box-sizing: border-box;
}

.input-container.disabled {
  background-color: #f5f5f5;
  border-color: #e0e0e0;
}

.input-container input:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
  color: #666;
}

.input-container :deep(.quillWrapper .ql-snow.ql-toolbar .ql-formats) {
  margin-bottom: 0;
}

.input-container :deep(.ql-toolbar.ql-snow) {
  display: flex;
  justify-content: flex-end;
  padding: 4px 0;
}

.input-container :deep(.ql-editor ul),
.input-container :deep(.ql-editor ol) {
  padding-left: 0;
}
.input-container input:disabled + label {
  color: #666;
}

.input-container :deep(.ql-container) {
  border: none;
  font-size: 14px;
}

.input-container :deep(.ql-toolbar) {
  border: none;
  border-bottom: 1px solid #e8e8e8;
  padding: 8px 0;
}

.input-container :deep(.ql-editor) {
  padding: 12px 0;
  font-size: 14px;
  min-height: fit-content;
  color: #000 !important;
}

.input-container :deep(.ql-editor p) {
  color: #000 !important;
}
.input-container :deep(.ql-editor p:not(.ql-direction-rtl)) {
  color: #000 !important;
}
.input-container :deep(.ql-toolbar.ql-snow) {
  padding: 4px 0;
}

.input-container :deep(.ql-toolbar .ql-picker-label) {
  font-size: 12px;
  padding: 0 4px;
}

.input-container :deep(.ql-toolbar button) {
  width: 24px;
  height: 24px;
  padding: 2px;
}

.input-container :deep(.quillWrapper .ql-snow.ql-toolbar button svg) {
  /* Ajuster la taille des icônes dans les boutons */
  width: 16px;
  height: 16px;
}

/* Pour enlever les flèches des inputs number */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Pour Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.photos-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
}

.photo-item {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}

.photo-item img {
  width: 100%;
  height: auto;
  display: block;
}

.photo-actions {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  gap: 8px;
}

.btn-icon {
  background: rgba(255, 255, 255, 0.9);
  border: none;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.info-card {
  background: white;
  border-radius: 8px;
  padding: 24px;
}

.info-card h3 {
  font-size: 16px;
  margin-bottom: 16px;
}

.info-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.info-row {
  display: grid;
  grid-template-columns: 120px 1fr;
  gap: 8px;
  align-items: center;

  .status-badge {
    width: fit-content;
  }
  &.report-reasons {
    align-items: flex-start;
  }
}

.info-row label {
  color: #666;
}

@media (max-width: 1024px) {
  .content-wrapper {
    grid-template-columns: 1fr;
  }
}
.side-content {
  position: sticky;
  top: 24px;
  max-height: calc(100vh - 48px); /* 2 * padding */
  overflow-y: auto;
  align-self: start;
}

.view-link {
  color: #2196f3;
  text-decoration: underline;
}

.tooltip-wrapper {
  position: relative;
}

.tooltip {
  position: absolute;
  display: none;
  background: white;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 12px;
  width: max-content;
  max-width: 300px;
  z-index: 1000;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 8px;
}

.tooltip::before {
  content: "";
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 12px;
  height: 12px;
  background: white;
  border-left: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
}

.tooltip-content {
  font-size: 12px;
  color: #666;
  line-height: 1.4;

  & > div {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-direction: column;
  }
}

.tooltip-content strong {
  color: #000;
  display: block;
  margin-bottom: 4px;
}

.tooltip-content div {
  margin-bottom: 4px;
}

.tooltip-wrapper:hover .tooltip {
  display: block;
}

.error-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 0;
}
.info-update {
  display: flex;
  justify-content: flex-end;
}
.update-date {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #666;
  background-color: #f7f7f7;
  padding: 4px 8px;
  border-radius: 4px;
  margin-left: 8px;
}

.update-label {
  margin-right: 4px;
  font-weight: 500;
}

.update-value {
  color: #333;
}
</style>
