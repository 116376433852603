<template>
    <TemplateDetails :get-function="getRecomOrder" :data="order" :loading="orderLoading">
        <PageBack/>

        <div v-if="order" class="order-cms-refund">
            <PageHeader :title="$t('order.recom_title', { id: order.reference })">
                <template v-slot:text>
                    <TextSimple color="grey">
                        {{ order.brand?.name }} - {{ order.paidAt | dateHourFromUTC }} - <router-link class="order-cms-refund__usermail" :to="{ name: 'recom_customer', params: { id: order?.customer?.id } }">{{ order?.customer?.email }}</router-link>
                    </TextSimple>
                </template>
                <template v-slot:actions>
                    <ButtonLink :to="{name: 'recom_order', params:{ id: $route.params.id}}" size="m" color="grey">{{ $t('global.cancel') }}</ButtonLink>
                    <Button size="m" @click.native.prevent="refundPreview">{{ $t('order.refund.validate') }}</Button>
                </template>
            </PageHeader>

            <GridContainer>
                <!-- RECAPITULATIF -->
                <GridCard>
                    <GridContent>
                        <GridHeader :title="$t('order.resume')"/>
                        <OrderSummary :order="order" mode="refund" />
                        
                        <!-- Sélection globale -->
                        <div class="order-cms-refund__row" v-if="hasRefundableItems">
                            <FieldCheckbox
                                class="order-cms-refund__all"
                                :model="isAllSelected"
                                :label="$t('global.selectAll')"
                                @change="handleSelectAll"
                            />
                            <FieldSelect
                                :disabled="!isAllSelected"
                                :options="formattedRefundReasons"
                                :selected="globalReason"
                                :label="$t('order.refund_reason')"
                                :border="true"
                                @input="handleGlobalReasonChange"
                            />
                        </div>

                        <!-- Sélection des articles -->
                        <RefundElementSelection
                            type="items"
                            :elements="refundability?.refundableItems"
                            :formatted-refund-reasons="formattedRefundReasons"
                            :is-all-selected="isAllSelected"
                            :global-reason="globalReason"
                            @selection-update="updateSelections"
                        />

                        <!-- Sélection des frais -->
                        <RefundElementSelection
                            type="fees"
                            :elements="refundability?.refundableFees"
                            :formatted-refund-reasons="formattedRefundReasons"
                            :is-all-selected="isAllSelected"
                            :global-reason="globalReason"
                            @selection-update="updateSelections"
                        />
                    </GridContent>
                </GridCard>

                <template v-slot:aside>
                    <!-- RÉCAP REMBOURSEMENT -->
                    <GridCard>
                        <GridContent>
                            <GridHeader :title="$t('order.refund.recap')"/>
                            <RefundSummary
                                :data="dryRunData"
                                :loading="dryRunLoading"
                            />
                        </GridContent>
                    </GridCard>
                    
                    <!-- GESTE COMMERCIAL-->
                    <GridCard>
                        <GridContent>
                            <GridHeader :title="$t('order.refund.commercial_gesture')" :text="$t('order.refund.wallet_credit')"/>
                            <RefundCommercialGesture
                                :validated-amount="commercialGestureValidated"
                                @update="updateCommercialGesture"
                            />
                        </GridContent>
                    </GridCard>
                </template>
            </GridContainer>
        </div>

        <!-- Popin de confirmation -->
        <RefundConfirmationPopin
            ref="confirmationPopin"
            :data="dryRunData"
            :loading="dryRunLoading || refundProcessing"
            @confirm="handleRefundConfirm"
            @cancel="handleRefundCancel"
        />
    </TemplateDetails>
</template>

<script>
import TemplateDetails from '@/components/templates/TemplateDetails';
import Button from '@/components/ui/button/Button';
import ButtonLink from '@/components/ui/button/ButtonLink';
import FieldCheckbox from '@/components/ui/form/fields/FieldCheckbox';
import FieldSelect from '@/components/ui/form/fields/FieldSelect';
import GridCard from '@/components/ui/grid/GridCard';
import GridContainer from '@/components/ui/grid/GridContainer';
import GridContent from '@/components/ui/grid/GridContent';
import GridHeader from '@/components/ui/grid/GridHeader';
import OrderSummary from '@/components/ui/order/OrderSummary';
import PageBack from '@/components/ui/page/PageBack';
import PageHeader from '@/components/ui/page/PageHeader';
import TextSimple from '@/components/ui/text/TextSimple';
import RefundElementSelection from '@/components/ui/refund/RefundElementSelection';
import RefundCommercialGesture from '@/components/ui/refund/RefundCommercialGesture';
import RefundSummary from '@/components/ui/refund/RefundSummary';
import RefundConfirmationPopin from '@/components/ui/refund/RefundConfirmationPopin';

import { mapActions, mapState, mapGetters } from 'vuex';

export default {
    name: "OrderRefundPage",
    components: {
        Button,
        ButtonLink,
        FieldCheckbox,
        FieldSelect,
        GridCard,
        GridContainer,
        GridContent,
        GridHeader,
        OrderSummary,
        PageBack,
        PageHeader,
        TextSimple,
        TemplateDetails,
        RefundElementSelection,
        RefundCommercialGesture,
        RefundSummary,
        RefundConfirmationPopin
    },
    data() {
        return {
            isAllSelected: false,
            commercialGesture: null,
            commercialGestureValidated: null,
            globalReason: null,
            selectedItems: {},
            itemReasons: {},
            selectedFees: {},
            feeReasons: {},
            refundProcessing: false
        }
    },
    created() {
        this.clearDryRunData();
        this.getRefundReasons();
        if (this.$route.params.id) {
            this.getRefundability(this.$route.params.id);
        }
    },
    computed: {
        ...mapState([
            'order',
            'orderLoading',
            'refundReasons',
            'refundReasonsLoading',
            'refundability',
            'refundabilityLoading',
            'dryRunData',
            'dryRunLoading',
        ]),
        ...mapGetters([
            'formattedRefundReasons'
        ]),

        hasRefundableItems() {
            return (this.refundability?.refundableItems?.length > 0 || this.refundability?.refundableFees?.length > 0);
        },

        payloadRefund() {
            // Préparation des items à rembourser
            const refundItems = this.refundability?.refundableItems
                ?.filter(item => 
                    this.selectedItems[item.id] && this.itemReasons[item.id]
                )
                .map(item => ({
                    id: item.id,
                    reason: this.itemReasons[item.id]
                })) || [];

            // Préparation des frais à rembourser
            const refundFees = this.refundability?.refundableFees
                ?.filter(fee => 
                    this.selectedFees[fee.type] && this.feeReasons[fee.type]
                )
                .map(fee => ({
                    id: fee.type,
                    reason: this.feeReasons[fee.type]
                })) || [];

            // Construction du payload final
            return {
                refundItems,
                refundFees,
                gesture_amount: this.commercialGestureValidated || 0
            };
        },

        // Pour déclencher le watcher sur changements du payload
        purePayloadRefund() {
            return JSON.parse(JSON.stringify(this.payloadRefund));
        },

        // Vérifier si on peut valider (au moins un élément sélectionné et avec une raison)
        canValidate() {
            // Vérifier qu'au moins un item ou un frais est sélectionné
            const hasItemSelected = Object.values(this.selectedItems).some(selected => selected);
            const hasFeeSelected = Object.values(this.selectedFees).some(selected => selected);
            
            // Vérifier que tous les items sélectionnés ont une raison
            const itemsValid = Object.entries(this.selectedItems).every(
                ([id, selected]) => !selected || this.itemReasons[id]
            );
            
            // Vérifier que tous les frais sélectionnés ont une raison
            const feesValid = Object.entries(this.selectedFees).every(
                ([type, selected]) => !selected || this.feeReasons[type]
            );
            
            return (hasItemSelected || hasFeeSelected) && itemsValid && feesValid;
        }
    },
    watch: {
        purePayloadRefund: {
            handler(newVal, oldVal) {
                if (JSON.stringify(newVal) === JSON.stringify(oldVal)) return;
                
                const hasItems = newVal.refundItems && newVal.refundItems.length > 0;
                const hasFees = newVal.refundFees && newVal.refundFees.length > 0;
                const hasCommercialGesture = this.commercialGestureValidated && this.commercialGestureValidated > 0;
                
                if (hasItems || hasFees || hasCommercialGesture) {
                    this.handleValidateRefund();
                } else {
                    this.$store.commit('SET_ORDER', { key: 'dryRunData', value: null });
                }
            }
        },
    },
    methods: {
        ...mapActions([
            'getRecomOrder',
            'getRefundReasons',
            'getRefundability',
            'setNotificationMessage',
            'performRefundDryRun',
            'performRefund',
            'clearDryRunData'
        ]),
        
        // Gestion de la sélection globale
        handleSelectAll(value) {
            this.isAllSelected = value;
            
            // Gestion des raisons : réinitialiser si on coche tout sans raison globale OU si on décoche tout
            const shouldResetReasons = (value && !this.globalReason) || !value;
            
            // Fonction générique pour traiter à la fois les items et les frais
            const updateRefundableItems = (elements, selectionsObj, reasonsObj, idKey = 'id') => {
                if (!elements || !elements.length) return;
                elements.forEach(element => {
                    const key = element[idKey];
                    this.$set(selectionsObj, key, value);
                    
                    // Gérer les raisons
                    if (shouldResetReasons) {
                        this.$delete(reasonsObj, key);
                    } else if (value && this.globalReason) {
                        this.$set(reasonsObj, key, this.globalReason);
                    }
                });
            };
            
            // Traiter les articles
            updateRefundableItems(
                this.refundability?.refundableItems, 
                this.selectedItems, 
                this.itemReasons
            );
            
            // Traiter les frais (avec une clé différente 'type' au lieu de 'id')
            updateRefundableItems(
                this.refundability?.refundableFees, 
                this.selectedFees, 
                this.feeReasons,
                'type'
            );
        },

        // Gestion de la raison globale
        handleGlobalReasonChange(reason) {
            this.globalReason = reason;
            
            // Appliquer la raison à tous les articles et frais sélectionnés
            if (this.isAllSelected) {
                if (this.refundability?.refundableItems) {
                    this.refundability.refundableItems.forEach(item => {
                        this.$set(this.itemReasons, item.id, reason);
                    });
                }
                
                if (this.refundability?.refundableFees) {
                    this.refundability.refundableFees.forEach(fee => {
                        this.$set(this.feeReasons, fee.type, reason);
                    });
                }
            }
        },

        updateSelections({ type, selections, reasons }) {
            if (type === 'items') {
                this.selectedItems = selections;
                this.itemReasons = reasons;
            } else if (type === 'fees') {
                this.selectedFees = selections;
                this.feeReasons = reasons;
            }
        },

        // Mise à jour du geste commercial
        updateCommercialGesture(amount) {
            if( amount == null){
                this.commercialGestureValidated = null;
                return;
            }
            if (amount <= 0) {
                this.setNotificationMessage({
                    text: this.$t('order.refund.requirement_positive_amount'),
                    color: "red"
                });
                this.commercialGestureValidated = null;
                return;
            }

            if ((amount / 100) % 1 !== 0) {
                this.setNotificationMessage({
                    text: this.$t('order.refund.requirement_integer_amount'),
                    color: "red"
                });
                this.commercialGestureValidated = null;
                return;
            }

            // Si toutes les validations sont passées
            this.commercialGestureValidated = amount;
        },

        // Validation du remboursement (prévisualisation)
        refundPreview() {
            if (this.canValidate) {
                this.$refs.confirmationPopin.open();
            } else {
                if (!Object.values(this.selectedItems).some(selected => selected) && 
                    !Object.values(this.selectedFees).some(selected => selected)) {
                    this.setNotificationMessage({
                        text: this.$t('order.refund.requirement_select_article'),
                        color: "red"
                    });
                } else {
                    this.setNotificationMessage({
                        text: this.$t('order.refund.requirement_select_reason'),
                        color: "red"
                    });
                }
            }
        },

        // Calcul du remboursement (dry run)
        async handleValidateRefund() {
            try {
                await this.performRefundDryRun({
                    orderId: this.$route.params.id,
                    payload: this.payloadRefund
                });
            } catch(error) {
                console.error('Dry run failed:', error);
            }
        },

        // Confirmation du remboursement
        async handleRefundConfirm() {
            try {
                this.refundProcessing = true
                const result = await this.performRefund({
                    orderId: this.$route.params.id,
                    payload: this.payloadRefund
                });
                
                if (result.success) {
                    this.refundProcessing = false;
                    this.setNotificationMessage({
                        text: this.$t('order.refund.refund_success'),
                        color: "green"
                    });
                    this.clearDryRunData();
                    
                    this.$router.push({
                        name: 'recom_order',
                        params: { id: this.$route.params.id }
                    });
                } else {
                    this.refundProcessing = false;
                    if (result.status === 422) {
                        this.setNotificationMessage({
                            text: result.message,
                            color: "red"
                        });
                    } else {
                        this.setNotificationMessage({
                            text: this.$t('order.refund.refund_error'),
                            color: "red"
                        });
                    }
                }
            } catch (error) {
                this.refundProcessing = false;
                console.error('Error during refund confirmation:', error);
                
                this.setNotificationMessage({
                    text: this.$t('order.refund.refund_error'),
                    color: "red"
                });
            }
        },

        // Annulation du remboursement
        handleRefundCancel() {
            this.$refs.confirmationPopin.close();
        }
    }
}
</script>

<style lang="scss">
.order-cms-refund {
  padding-bottom: 150px; // better for showing fieldSelect option
  &__usermail {
    display: inline;
    text-decoration: underline;
  }

  &__row {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 15px 0;

    .field-select {
      width: 300px;
      margin-left: auto;
    }
  }

  &__all {
    .field-checkbox__label {
      font-weight: 500;
    }
  }
  
  .field-checkbox{
    margin-top: 0;
  }
}
</style>