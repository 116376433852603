<template>
    <TemplateDetails :get-function="getRecomOrder" :data="order" :loading="orderLoading">
        <PageBack/>


        <div v-if="order" class="order-cms-detail">
            <PageHeader :title="$t('order.recom_title', { id: order.reference })">
                <template v-slot:text>
                    <TextSimple color="grey">
                        {{ order.brand?.name }} - {{ order.paidAt | dateHourFromUTC }} - <router-link class="order-cms-detail__usermail" :to="{ name: 'recom_customer', params: { id: order?.customer?.id } }">{{ order?.customer?.email }}</router-link>
                    </TextSimple>
                </template>
                <template v-slot:actions>
                    <ButtonLink v-if="order.invoice" :to="order.invoice" icon="download" size="m" color="grey">{{ $t('order.download_invoice') }}</ButtonLink>
                    <ButtonLink v-if="roleIsAllowed(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])" :to="{name: 'recom_order_refund', params:{ id: $route.params.id}}" size="m" color="black">{{ $t('order.refund.proceed_to_refund') }}</ButtonLink>
                </template>
            </PageHeader>

            <GridContainer>
                <!-- INFORMATIONS GENERALES -->
                <GridCard>
                    <GridContent>
                        <GridHeader :title="$t('order.general')"/>
                        <SectionTableInfos :fields="fieldsGeneral" :object="order"/>
                    </GridContent>
                </GridCard>

                <!-- RECAPITULATIF -->
                <GridCard>
                    <GridContent>
                        <GridHeader :title="$t('order.resume')"/>
                        <RecomOrderCardItem v-for="(item, i) in order.items" :key="i" :item="item" :to="{name: 'recom_tradein-n-sell-article', params: {id: item?.tradeinId}, query: { view: 'sell' } }" :return-item="returnObject?.orderReturnItems?.find(x => x?.orderItem?.id === item.id)" display-origin-price/>
                        <br>
                        <br>
                        <OrderSummary :order="order" mode="detail" />
                    </GridContent>
                </GridCard>

                <template v-slot:aside>

                    <!-- INFORMATIONS DE LIVRAISON -->
                    <GridCard>
                        <GridContent>
                            <GridHeader :title="$t('order.shipping_title')"/>
                            <SectionTableInfos :fields="fieldsShipping" :object="order"/>
                        </GridContent>
                    </GridCard>

                    <!-- INFORMATIONS DE RETOUR -->
                    <GridCard v-if="order.return">
                        <GridContent>
                            <GridHeader :title="$t('order.return_title')"/>
                            <SectionTableInfos :fields="fieldsReturn" :object="order"/>
                        </GridContent>
                    </GridCard>
                    
                    <!-- Historique suivi de la commande-->
                    <GridCard>
                        <GridContent>
                            <GridHeader :title="$t('order.track_order')"/>
                            <GridCalendar>
                                <GridCalendarDate
                                    v-for="(activity, i) in order.history"
                                    :key="i"
                                    :title="activity.event"
                                    :date="activity.date"
                                    :error="activity.event === 'exception'"
                                    :comment="activity.metadata?.message"
                                />
                            </GridCalendar>
                        </GridContent>
                    </GridCard>

                </template>

            </GridContainer>

        </div>

    </TemplateDetails>
</template>

<script>
import SectionTableInfos from '@/components/sections/SectionTableInfos';
import TemplateDetails from '@/components/templates/TemplateDetails';
import ButtonLink from '@/components/ui/button/ButtonLink';
import GridCalendar from '@/components/ui/grid/GridCalendar';
import GridCalendarDate from '@/components/ui/grid/GridCalendarDate';
import GridCard from '@/components/ui/grid/GridCard';
import GridContainer from '@/components/ui/grid/GridContainer';
import GridContent from '@/components/ui/grid/GridContent';
import GridHeader from '@/components/ui/grid/GridHeader';
import RecomOrderCardItem from '@/components/ui/order/RecomOrderCardItem';
import PageBack from '@/components/ui/page/PageBack';
import PageHeader from '@/components/ui/page/PageHeader';
import TextSimple from '@/components/ui/text/TextSimple';
import OrderSummary from '@/components/ui/order/OrderSummary';
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
    name: "OrderPage",
    components: {
        ButtonLink,
        GridCalendarDate,
        GridCalendar,
        GridHeader,
        GridContent,
        GridCard,
        GridContainer,
        OrderSummary,
        RecomOrderCardItem,
        TextSimple,
        SectionTableInfos,
        PageHeader,
        PageBack,
        TemplateDetails
    },
    data() {
        return {
            fieldsGeneral: [
                {key: 'brand.name', type: 'client', translationKey: 'client'},
                {key: 'reference', translationKey: 'id'},
                {key: 'paidAt', translationKey: 'orderCreateAt', type: 'dateHourFromUTC'},
                {key: 'customer.id', type: 'CmsCustomer', translationKey: "buyerEmail"},
                {key: 'channel', type: 'tagType', translationKey: 'canalVente'},
                {key: 'itemsNb', translationKey:"countArticles"},
                {key: 'paymentState', type: 'tagTypePayments', translationKey: 'payments'},
                {key: 'payments', type: 'cmsOrderTransactionId', translationKey: "transactionId"},
                {key: 'state', translationKey:"orderStatus", type: 'tagType'},
            ],
            fieldsShipping: [
                {key: 'shipment.state', translationKey: "shipmentStatus", type: 'tagType', size: 'm'},
                {key: 'shipment', translationKey: 'trackingId', type: 'cmsVoucherTracking'},
                {key: 'shipment.carrier', translationKey: 'carrierService'},
                {key: 'billingAddress', type: 'address'},
                {key: 'shippingAddress', type: 'address'},
                {key: 'shipment.shippingVoucher', type: 'pdf', translationKey: 'shippingVoucher'},
            ],
            fieldsReturn: [
                {key: 'return.state', type: 'tagType'},
                {key: 'return', translationKey: 'trackingId', type: 'cmsVoucherTracking'},
                {key: 'return.shippingAddress', type: 'address', translationKey: 'receptionAddress'},
                {key: 'return.shippingVoucher', type: 'pdf', translationKey: 'shippingVoucher'},
            ]
        }
    },
    computed: {
        ...mapState(['order', 'orderLoading', 'clients']),
        ...mapGetters(['roleIsAllowed']),
        returnObject() {
            return this.order?.orderReturns?.[0]
        }
    },
    methods: {
        ...mapActions(['getRecomOrder'])
    }
}
</script>

<style lang="scss">
.order-cms-detail {
    .grid-calendar ul {
        padding: 0;
        gap: 15px;
    }

    .grid-calendar-date > span {
        border-color: #fff;
    }

    .grid-calendar-date {
        align-items: flex-start;

        & > div {
            gap: 4px;
        }
    }

    .grid-calendar-date.is-error {
        p + p {
            text-transform: lowercase;
        }
    }

    &__usermail{
        display: inline;
        text-decoration: underline;
    }
}
</style>