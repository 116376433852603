// @/mixins/dateFormatMixin.js
import moment from "moment";

export default {
  computed: {
    dateFormatPlaceholder() {
      return this.$i18n.locale === "en" ? "YYYY-MM-DD" : "DD/MM/YY";
    },
  },
  methods: {
    formatDateForDisplay(date) {
      if (!date) return "";

      if (typeof date === "string") {
        // Pour l'anglais
        if (this.$i18n.locale === "en") {
          return moment(date).format("YYYY-MM-DD");
        }
        // Pour le français
        return moment(date).format("DD/MM/YY");
      }
      return "";
    },

    formatDateTimeForDisplay(date) {
      if (!date) return "";

      if (typeof date === "string") {
        // Pour l'anglais
        if (this.$i18n.locale === "en") {
          return moment(date).format("YYYY-MM-DD HH:mm");
        }
        // Pour le français
        return moment(date).format("DD/MM/YY [à] HH:mm");
      }
      return "";
    },
    formatRelativeTime(date) {
      if (!date) return "";

      const now = moment();
      const dateTime = moment(date);
      const diff = now.diff(dateTime, "minutes");

      // Moins d'une heure
      if (diff < 60) {
        return this.$i18n.locale === "en"
          ? `${diff} minute${diff !== 1 ? "s" : ""} ago`
          : `il y a ${diff} minute${diff > 1 ? "s" : ""}`;
      }

      // Moins d'un jour
      if (diff < 24 * 60) {
        const hours = Math.floor(diff / 60);
        return this.$i18n.locale === "en"
          ? `${hours} hour${hours !== 1 ? "s" : ""} ago`
          : `il y a ${hours} heure${hours > 1 ? "s" : ""}`;
      }

      // Moins d'une semaine
      if (diff < 7 * 24 * 60) {
        const days = Math.floor(diff / (24 * 60));
        return this.$i18n.locale === "en"
          ? `${days} day${days !== 1 ? "s" : ""} ago`
          : `il y a ${days} jour${days > 1 ? "s" : ""}`;
      }

      // Sinon, afficher la date complète
      return this.formatDateForDisplay(date);
    },

    formatDateRange(date1, date2) {
      if (date1 && date2) {
        return `${this.formatDateForDisplay(
          date1
        )} →  ${this.formatDateForDisplay(date2)}`;
      } else if (date1) {
        return this.formatDateForDisplay(date1);
      } else if (date2) {
        return this.formatDateForDisplay(date2);
      }
      return "";
    },

    parseDateFromDisplay(displayDate) {
      if (!displayDate) return null;
      const format = this.$i18n.locale === "en" ? "YYYY-MM-DD" : "DD/MM/YY";
      return moment(displayDate, format).format("YYYY-MM-DD");
    },
  },
};
