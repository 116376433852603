<template>
  <div v-if="showPublishStateFilter" class="filter-group">
    <div class="filter-group-header">
      <h4>{{ $t("article_put_online.specific_plublishing_status") }}</h4>
      <!-- <div class="filter-operator">
        <button
          v-for="op in operators"
          :key="op.value"
          :class="[
            'operator-btn',
            { active: currentOperator === op.value },
          ]"
          @click="updateOperator(op.value)"
        >
          {{ op.label }}
        </button>
      </div> -->
    </div>
    <div class="publish-state-options">
      <label
        v-for="state in availablePublishStates"
        :key="state"
        class="checkbox-label"
      >
        <input
          type="checkbox"
          :value="state"
          v-model="selectedStates"
          @change="emitUpdate"
        />
        <span>{{ getPublishStateLabel(state) }}</span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "PublishStateFilter",
  props: {
    // Valeurs initiales depuis le parent
    value: {
      type: Array,
      default: () => []
    },
    operator: {
      type: String,
      default: "inclus"
    },
    operators: {
      type: Array,
      default: () => [
        { value: "inclus", label: "Inclus" },
        { value: "exclus", label: "Exclus" }
      ]
    },
    tableFilters: {
      type: Array,
      default: () => []
    },
    currentTab: {
      type: String,
      default: "all"
    }
  },
  data() {
    return {
      selectedStates: [],
      currentOperator: "inclus"
    };
  },
  computed: {
    showPublishStateFilter() {
      return true; // Peut être conditionnel selon vos besoins
    },
    
    publishStateLabels() {
      return {
        unpublished: this.$t("article_put_online.tabs.unpublished"),
        draft: this.$t("article_put_online.tabs.draft"),
        on_sale: this.$t("article_put_online.tabs.on_sale"),
        to_publish: this.$t("article_put_online.tabs.to_publish"),
        to_correct: this.$t("article_put_online.tabs.to_correct")
      };
    },
    
    availablePublishStates() {
      if (!this.tableFilters) return [];

      const publishStateFilter = this.tableFilters.find(
        (filter) => filter.key === "publishState"
      );

      const allowedStates = ["unpublished", "draft"];

      return publishStateFilter
        ? publishStateFilter.options
            .filter((option) => allowedStates.includes(option.id))
            .map((option) => option.id)
        : [];
    }
  },
  created() {
    // Initialisation des valeurs depuis les props
    this.selectedStates = [...this.value];
    this.currentOperator = this.operator;
  },
  methods: {
    getPublishStateLabel(state) {
      return this.publishStateLabels[state] || state;
    },
    
    updateOperator(value) {
      this.currentOperator = value;
      this.emitUpdate();
    },
    
    emitUpdate() {
      this.$emit('update', 'publishState', this.selectedStates, this.currentOperator);
    }
  },
  watch: {
    value(newVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(this.selectedStates)) {
        this.selectedStates = [...newVal];
      }
    },
    operator(newVal) {
      if (newVal !== this.currentOperator) {
        this.currentOperator = newVal;
      }
    },
    currentTab(newVal) {
      if (newVal !== "all") {
        // Réinitialiser les filtres si on n'est pas sur l'onglet "all"
        this.selectedStates = [];
        this.emitUpdate();
      }
    }
  }
};
</script>

<style scoped>
.filter-group {
  background: white;
  padding: 20px;
  padding-bottom: 32px;
  border-bottom: 1px solid #e9ecef;
}

.filter-group-header {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter-group-header h4 {
  font-weight: 600;
  color: #1a1a1a;
  font-size: 14px;
}

.filter-operator {
  display: flex;
  gap: 1px;
  background: #e0e0e0;
  border-radius: 4px;
  padding: 1px;
}

.operator-btn {
  padding: 4px 12px;
  font-size: 12px;
  background: white;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
}

.filter-group .operator-btn:first-child {
  border-radius: 4px 0 0 4px;
}

.filter-group .operator-btn:last-child {
  border-radius: 0 4px 4px 0;
}

.filter-group .operator-btn.active {
  background: #000;
  color: white;
}

.publish-state-options {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.checkbox-label {
  padding: 8px 12px;
  border-radius: 6px;
  transition: background 0.2s;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  width: 98%;
}

.checkbox-label:hover {
  background: #f9f9f9;
}

.checkbox-label input[type="checkbox"] {
  position: relative;
  width: 18px;
  height: 18px;
  cursor: pointer;
  appearance: none;
  border: 2px solid #e0e0e0;
  border-radius: 4px;
  background: white;
  transition: all 0.2s ease;
}

.checkbox-label input[type="checkbox"]:hover {
  border-color: #000;
}

.checkbox-label input[type="checkbox"]:checked {
  background: #000;
  border-color: #000;
}

.checkbox-label input[type="checkbox"]:checked:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 2px;
  width: 2px;
  height: 7px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkbox-label input[type="checkbox"]:focus {
  outline: none;
}

.checkbox-label input[type="checkbox"]:checked ~ span {
  color: #000;
  font-weight: 500;
}
</style>