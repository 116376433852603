<template>
  <span :class="['status-badge', getStatusClass(status)]">
    {{ getStatusFromState(status) }}
  </span>
</template>

<script>
export default {
  name: "StatusCell",
  
  props: {
    // La prop 'status' doit être le code interne du statut (ex. "to_publish")
    status: {
      type: String,
      required: true
    }
  },
  
  methods: {
    getStatusFromState(status) {
      // Mappe les codes internes aux clés de traduction
      const statusMap = {
        to_publish: "article_put_online.tabs.to_publish",
        on_sale: "article_put_online.tabs.on_sale",
        to_correct: "article_put_online.tabs.to_correct",
        draft: "article_put_online.tabs.draft",
        unpublished: "article_put_online.tabs.unpublished"
      };
      const translationKey = statusMap[status] || status;
      return this.$t(translationKey);
    },
    getStatusClass(status) {
      // Mappe les codes internes aux classes CSS définies
      const classMap = {
        to_publish: "status-badge--to-publish",
        on_sale: "status-badge--on-sale",
        to_correct: "status-badge--to-correct",
        draft: "status-badge--draft",
        unpublished: "status-badge--unpublished"
      };
      return classMap[status] || "";
    }
  }
};
</script>

<style scoped>
.status-badge {
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 12px;
  display: inline-block;
  border: 1px solid;
}

.status-badge--to-publish {
  background: #fff4ed;
  color: #fea36f;
  border-color: #fea36f;
}

.status-badge--on-sale {
  background: #f1f1fe;
  color: #6f71e6;
  border-color: #6f71e6;
}

.status-badge--to-correct {
  background: #ffeeed;
  color: #fe3737;
  border-color: #fe3737;
}

.status-badge--draft {
  background: #fff;
  color: #000;
  border-color: #e0e0e0;
}

.status-badge--unpublished {
  background: #f7f7f7;
  color: #111;
  border-color: #d0d0d0;
}
</style>
