<template>
  <div class="badges-container">
    <template v-if="uniqueErrors.length">
      <!-- Traduction de la première erreur -->
      <span class="error-badge">
        {{ $t(`article_put_online.errors.${uniqueErrors[0].reason}`) }}
      </span>
      <custom-tooltip
        v-if="uniqueErrors.length > 1"
        position="top"
        :title="$t('article_put_online.errors.all_errors')"
      >
        <template #default>
          <span class="error-counter"> +{{ uniqueErrors.length - 1 }} </span>
        </template>
        <template #content>
          <div class="error-tooltip-list">
            <div
              v-for="error in uniqueErrors"
              :key="error.reason"
              class="error-item"
            >
              <span class="error-badge">
                {{ $t(`article_put_online.errors.${error.reason}`) }}
              </span>
            </div>
          </div>
        </template>
      </custom-tooltip>
    </template>
    <span v-else class="empty-value">-</span>
  </div>
</template>

<script>
import CustomTooltip from "@/components/ui/tootip/CustomTooltip";

export default {
  name: "ErrorsCell",
  components: {
    CustomTooltip,
  },
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    uniqueErrors() {
      // Utiliser un Map pour dédupliquer en se basant sur la raison
      const uniqueErrorsMap = new Map();
      this.errors.forEach((error) => {
        const reason = error.reason || error;
        if (!uniqueErrorsMap.has(reason)) {
          uniqueErrorsMap.set(reason, error);
        }
      });
      return Array.from(uniqueErrorsMap.values());
    },
    formattedErrors() {
      return this.errors.map((error) => error.reason || error);
    },
  },
  methods: {
    getErrorReason(error) {
      return error.reason || error;
    },
  },
};
</script>

<style scoped>
.badges-container {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  align-items: center;
}

.error-badge {
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  background: #fff;
  border: 1px solid #e6e6e6;
  color: #000;
}

.error-counter {
  padding: 2px 6px;
  border-radius: 12px;
  font-size: 11px;
  font-weight: 500;
  background: #fff;
  border: 1px solid #e6e6e6;
  color: #000;
  cursor: pointer;
}

.empty-value {
  font-size: 12px;
  color: #9e9e9e;
  margin: 0 auto;
}

.error-tooltip-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.error-item {
  margin-bottom: 4px;
}
</style>
