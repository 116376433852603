<template>
    <router-link :to="to" class="recom-order-card">
        <div class="recom-order-card__photo">
            <img v-if="photo" :src="photo" alt="photo">
            <SvgIcon v-else name="tshirt"/>
        </div>
        <div class="recom-order-card__text">
            <div class="recom-order-card__text-title">
                <TextTitle size="xs">{{ title }}</TextTitle>
            </div>
            <TextSimple color="grey">{{$t('global.size')}} {{ size }} - {{ color | normalizeCapitalize }} - {{ $t(`global.${condition}`) }}</TextSimple>
            <TextSimple color="grey">{{ $t("global.id") }}: {{ reference }}</TextSimple>
        </div>
        <div class="recom-order-card__right">
          <PopinTooltip v-if="item.refund">
            <!-- Contenu du bouton qui déclenche le tooltip -->
            <template #button>
              <Tag :text="item.state" color="orange" iconRight="info"/>
            </template>
            
            <!-- Contenu du tooltip qui s'affiche -->
            <template #content>
              <div class="recom-order-card__popin">
                <TextSimple weight="medium">{{ $t('global.payback') }} ({{ item?.refund?.createdAt|| null | dateHourFromUTC }})</TextSimple>
                <div class="recom-order-card__popin-line" v-if="item?.return?.reason">
                  <TextSimple size="m" color="grey">{{ $t("order.return_reason") }}:</TextSimple>
                  <TextSimple size="m" color="grey">{{ item.return.reason }}</TextSimple>
                </div>
                <div class="recom-order-card__popin-line" v-if="item?.refund?.reason">
                  <TextSimple size="m" color="grey">{{ $t("order.refund_reason") }}-:</TextSimple>
                  <TextSimple size="m" color="grey">{{ item.refund.reason }}</TextSimple>
                </div>
              </div>
            </template>
          </PopinTooltip>
          <TextSimple size="xs" v-if="displayOriginPrice && itemData?.priceOrigin" class="recom-order-card__origin-price">{{ itemData?.priceOrigin || null | formatPrice }}</TextSimple>
          <TextSimple size="l" weight="medium">{{ itemData.priceOffer || itemData.price || null | formatPrice }}</TextSimple>
        </div>
    </router-link>
</template>

<script>
import { mapState } from "vuex";
import TextTitle from "@/components/ui/text/TextTitle";
import TextSimple from "@/components/ui/text/TextSimple";
import SvgIcon from "@/components/ui/svg/SvgIcon";
import Tag from "@/components/ui/tag/Tag";
import PopinTooltip from '@/components/ui/popin/PopinTooltip';

export default {
  name: "RecomOrderCardItem",
  components: { TextSimple, TextTitle, SvgIcon, Tag, PopinTooltip },
  props: {
    item: Object,
    returnItem: Object,
    displayOriginPrice: {
      type: Boolean,
      default: false,
    },
    to: Object,
    type: {
        type: String,
        default: 'order',
        validator: value => ['order', 'resale'].includes(value)
    }
  },
  computed: {
    ...mapState(["clients"]),
    itemData() {
      // Si l'item a une propriété tradeIn, on utilise celle-ci
      // Sinon on utilise directement l'item
      return this.item?.tradeIn || this.item || {};
    },
    photo() {
      return this.itemData?.photo || this.itemData?.metadata?.photos?.[0] || null;
    },
    title() {
      return this.getMetadataValue("title") || this.itemData?.name;
    },
    color() {
      return this.getMetadataValue("color") || this.itemData?.color;
    },
    size() {
      return this.getMetadataValue("size") || this.itemData?.size;
    },
    condition() {
      return this.itemData?.condition || null;
    },
    ean() {
      return this.itemData?.ean || null;
    },
    sku() {
      return this.itemData?.sku || null;
    },
    reference() {
      return this.itemData?.reference || null;
    },
    idfaume() {
      return this.itemData?.id || null;
    },
  },
  methods: {
    getMetadataValue(key) {
      const metadata = this.itemData?.metadata;
      if (!metadata) return null;

      return (
        metadata.fr_FR?.[key] ||
        metadata.en_GB?.[key] ||
        metadata.de_DE?.[key] ||
        null
      );
    },
  },
};
</script>

<style lang="scss">
.recom-order-card {
  text-align: left;
  display: flex;
  align-items: center;
  gap: 15px;

  & ~ .recom-order-card {
    margin-top: 15px;
  }

  .tag {
    margin-left: 0;
    margin-top: 0px;
  }

  &__photo {
    width: 60px;
    aspect-ratio: 0.7;
    flex-shrink: 0;
    border: var(--border-1);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    transition: border ease 0.2s;
    --svg-color: var(--color-text-grey);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &:hover .recom-order-card__photo {
    border: var(--border-2);
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 3px;

    &-title {
      display: flex;
      align-items: flex-start;
      column-gap: 10px;
      @media screen and (max-width: 700px) {
        row-gap: 5px;
        flex-direction: column;
      }
    }
  }

  &__right{
    display: flex;
    flex-direction: row;
    margin-left: auto;
    align-items: center;
    gap: 10px;
    .tooltip__button{
      padding-top: 0;
    }
    .tooltip__content{
      width: 300px;
      max-width: 100vw;
    }
    .tag{
      white-space: nowrap;
    }
  }

  &__origin-price{
    text-decoration: line-through;
    color: var(--color-text-grey);
  }

  &__popin{
    display: flex;
    flex-direction: column;
    gap: 10px;
    &-line{
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      gap: 10px;
      >.text:first-child{
        width: 50%;
      }
      >.text:last-child{
        text-align: right;
      }
    }
  }

  // & > p {
  //   margin-left: auto;
  // }
}
</style>
