<!-- FilterTagPopup.vue -->
<template>
  <div class="filter-popup" v-click-outside="close" :data-type="type">
    <div class="popup-header">
      <!-- Affichage du titre traduit -->
      <h4>{{ translatedTitle }}</h4>
    </div>

    <!-- Contenu pour les filtres de date -->
    <template v-if="isDateType">
      <div class="date-inputs-container">
        <!-- Date de début -->
        <div class="date-group">
          <label class="date-label">
            {{ $t("article_put_online.operator.supérieur à") }}
          </label>
          <div class="date-input-container">
            <v-date-picker
              v-model="dateValues.value1"
              :max-date="currentDate"
              :masks="dateMasks"
              :locale="$i18n.locale"
              :model-config="dateConfig"
              color="gray"
            >
              <template v-slot="{ inputEvents }">
                <div class="date-input-wrapper">
                  <button class="date-picker-btn" v-on="inputEvents">
                    <SvgIcon name="calendar" class="icon" />
                  </button>
                  <input
                    :value="formatDateForDisplay(dateValues.value1)"
                    class="date-input"
                    :placeholder="dateFormatPlaceholder"
                    readonly
                  />
                  <button
                    v-if="dateValues.value1"
                    class="date-clear-btn"
                    @click.stop="clearDate('value1')"
                  >
                    ×
                  </button>
                </div>
              </template>
            </v-date-picker>
          </div>
        </div>

        <!-- Date de fin -->
        <div class="date-group">
          <label class="date-label">
            {{ $t("article_put_online.operator.inférieur à") }}
          </label>
          <div class="date-input-container">
            <v-date-picker
              v-model="dateValues.value2"
              :max-date="currentDate"
              :min-date="dateValues.value1"
              :masks="dateMasks"
              :locale="$i18n.locale"
              :model-config="dateConfig"
              color="gray"
            >
              <template v-slot="{ inputEvents }">
                <div class="date-input-wrapper">
                  <button class="date-picker-btn" v-on="inputEvents">
                    <SvgIcon name="calendar" class="icon" />
                  </button>
                  <input
                    :value="formatDateForDisplay(dateValues.value2)"
                    class="date-input"
                    :placeholder="dateFormatPlaceholder"
                    readonly
                  />
                  <button
                    v-if="dateValues.value2"
                    class="date-clear-btn"
                    @click.stop="clearDate('value2')"
                  >
                    ×
                  </button>
                </div>
              </template>
            </v-date-picker>
          </div>
        </div>
      </div>
    </template>

    <!-- Contenu pour les autres types de filtres -->
    <template v-else>
      <!-- Tags des valeurs sélectionnées -->
      <div v-if="selectedValues.length > 0" class="selected-tags">
        <div v-for="value in selectedValues" :key="value" class="selected-tag">
          <span class="tag-label">{{ getOptionLabel(value) }}</span>
          <button @click.stop="removeValue(value)" class="tag-remove">×</button>
        </div>
      </div>

      <!-- Barre de recherche conditionnelle -->
      <div v-if="shouldShowSearch" class="popup-search">
        <input
          type="text"
          v-model="searchQuery"
          :placeholder="`${$t('article_put_online.placeholder_search_brand')}`"
          class="search-input"
          @click.stop
        />
      </div>

      <!-- Liste des options -->
      <div class="popup-content">
        <div class="popup-options">
          <label
            v-for="option in filteredOptions"
            :key="option.id"
            class="popup-option"
            @click.stop
          >
            <input
              type="checkbox"
              :value="option.id"
              v-model="selectedValues"
              @click.stop
            />
            <span>{{ option.label }}</span>
          </label>
        </div>
      </div>
    </template>

    <!-- Footer -->
    <div class="popup-footer">
      <button class="apply-button" @click.stop="handleApply">
        {{ $t("article_put_online.apply_filters") }}
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import dateFormatMixin from "@/mixins/dateFormatMixin";

import { clickOutside } from "@/directives/click-outside";
import SvgIcon from "@/components/ui/svg/SvgIcon";

export default {
  name: "FilterTagPopup",
  mixins: [dateFormatMixin],
  directives: {
    clickOutside,
  },
  components: { SvgIcon },
  props: {
    type: {
      type: String,
      required: true,
    },
    // Pour le titre, on attend la clé de traduction
    title: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    currentValues: {
      type: Array,
      default: () => [],
    },
    currentValue: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      searchQuery: "",
      selectedValues: [],
      dateValues: {
        value1: "",
        value2: "",
      },
      dateMasks: {
        input: "YYYY-MM-DD",
        model: "YYYY-MM-DD",
      },
      dateConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
      },
    };
  },

  computed: {
    // Traduction du titre en fonction du type
    translatedTitle() {
      if (this.type === "brand") {
        return this.$t("article_put_online.table.brand");
      }
      if (this.type === "stockDate") {
        return this.$t("article_put_online.stockDate");
      }
      if (this.type === "publicationDate") {
        return this.$t("article_put_online.publicationDate");
      }
      if (this.type === "error") {
        return this.$t("article_put_online.error_to_fix");
      }
      if (this.type === "publishState") {
        return this.$t("article_put_online.specific_plublishing_status");
      }
      return this.$t(this.title);
    },
    dateFormatPlaceholder() {
      return this.$i18n.locale === "en" ? "YYYY-MM-DD" : "JJ/MM/AAAA";
    },
    shouldShowSearch() {
      return ["brand"].includes(this.type);
    },

    isDateType() {
      return ["stockDate", "publicationDate"].includes(this.type);
    },

    currentDate() {
      return new Date().toISOString().split("T")[0];
    },

    hasAnyDate() {
      return this.dateValues.value1 || this.dateValues.value2;
    },

    // Pour le filtre publishState, filtrer et traduire les options
    filteredOptions() {
      let opts = this.options;
      if (this.type === "publishState") {
        const allowedStates = ["unpublished", "draft"];
        opts = opts.filter((opt) => allowedStates.includes(opt.id));
        opts = opts.map((opt) => ({
          id: opt.id,
          label: this.publishStateLabels[opt.id] || opt.label,
        }));
      }
      // Pour le filtre error, traduire chaque option
      if (this.type === "error") {
        opts = opts.map((opt) => ({
          ...opt,
          label: this.$t(`article_put_online.errors.${opt.id}`),
        }));
      }

      if (this.type === "brand") {
        opts = [...opts].sort((a, b) =>
          a.label.localeCompare(b.label, undefined, { sensitivity: "base" })
        );
      }
      if (!this.searchQuery) return opts;
      const query = this.searchQuery.toLowerCase();
      return opts.filter((opt) => opt.label.toLowerCase().includes(query));
    },

    formattedDateRange() {
      const start = this.dateValues.value1
        ? this.formatDateForDisplay(this.dateValues.value1)
        : "";
      const end = this.dateValues.value2
        ? this.formatDateForDisplay(this.dateValues.value2)
        : "";
      return start && end ? `${start} - ${end}` : start || end;
    },

    // Traduction des libellés pour le filtre publishState
    publishStateLabels() {
      return {
        unpublished: this.$t("article_put_online.tabs.unpublished"),
        draft: this.$t("article_put_online.tabs.draft"),
      };
    },
  },

  watch: {
    currentValues: {
      handler(newVal) {
        this.selectedValues = [...newVal];
      },
      immediate: true,
    },
    currentValue: {
      handler(newVal) {
        if (this.isDateType && newVal) {
          const dates = newVal.split(/\s*[→]\s*/);
          // Utiliser moment pour parser en supportant les deux formats
          const date1 = moment(dates[0], ["DD/MM/YY", "YYYY-MM-DD"]);
          let date2 = null;

          if (dates.length === 2 && dates[1].trim() !== "") {
            date2 = moment(dates[1], ["DD/MM/YY", "YYYY-MM-DD"]);
          }

          this.dateValues = {
            value1: date1.isValid() ? date1.format("YYYY-MM-DD") : "",
            value2: date2 && date2.isValid() ? date2.format("YYYY-MM-DD") : "",
          };
        }
      },
      immediate: true,
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },

    handleApply() {
      if (this.isDateType) {
        const { value1, value2 } = this.dateValues;
        let operator = "";
        if (value1 && value2) {
          operator = "Entre";
        } else if (value1) {
          operator = "supérieur à";
        } else if (value2) {
          operator = "inférieur à";
        }

        // Si les deux dates sont vides, on émet une suppression
        if (!value1 && !value2) {
          this.$emit("change", {
            type: this.type,
            operator: "",
            value1: "",
            value2: "",
          });
        } else {
          this.$emit("change", {
            type: this.type,
            operator,
            value1: value1 || "",
            value2: value2 || "",
          });
        }
      } else {
        this.$emit("change", {
          type: this.type,
          values: this.selectedValues,
          operator: "inclus",
        });
      }

      this.close();
    },

    clearDate(dateKey) {
      this.dateValues[dateKey] = "";
    },

    formatDateForDisplay(date) {
      if (!date) return "";

      // Si la date est déjà au format YYYY-MM-DD, on la convertit en objet Date
      if (typeof date === "string") {
        const [year, month, day] = date.split("-");

        // Pour l'anglais, garder le format YYYY-MM-DD
        if (this.$i18n.locale === "en") {
          return date;
        }

        // Pour les autres locales (comme le français), format JJ/MM/AAAA
        return `${day}/${month}/${year}`;
      }

      return "";
    },

    // Pour le cas du filtre "error", traduire chaque libellé d'erreur
    getOptionLabel(value) {
      if (this.type === "error") {
        return this.$t(`article_put_online.errors.${value}`);
      }
      if (this.type === "publishState") {
        return this.publishStateLabels[value] || value;
      }
      const option = this.options.find((opt) => opt.id === value);
      return option ? option.label : value;
    },

    removeValue(value) {
      const index = this.selectedValues.indexOf(value);
      if (index > -1) {
        this.selectedValues.splice(index, 1);
        this.$emit("change", {
          type: this.type,
          values: this.selectedValues,
          operator: "inclus",
        });
      }
    },
  },
};
</script>

<style scoped>
.filter-popup {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  width: 300px;
  z-index: 1000;
  padding: 16px;
}
.filter-popup[data-type="stockDate"],
.filter-popup[data-type="publicationDate"] {
  width: 350px;
}
.popup-header {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 16px;
}

.popup-header h4 {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}

/* Les autres styles restent inchangés */
.date-inputs-container {
  display: flex;
  gap: 12px;
  margin-top: 12px;
}

.date-group {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.date-label {
  font-size: 12px;
  color: #666;
  font-weight: 500;
}

.date-input-container {
  position: relative;
  width: 100%;
}

.date-input {
  width: 100%;
  padding: 8px 12px;
  padding-left: 40px;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  font-size: 13px;
  background: white;
  transition: border-color 0.2s;
}

.date-input:focus {
  outline: none;
  border-color: #000;
}

.date-clear-btn {
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: none;
  color: #666;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
}

.date-clear-btn:hover {
  background: rgba(0, 0, 0, 0.1);
  color: #333;
}

.date-input-wrapper {
  position: relative;
  width: 100%;
}

.date-picker-btn {
  position: absolute;
  left: 1px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  padding: 8px;
  border-right: 1px solid #dee2e6;
  border-radius: 7px 0 0 7px;
  color: #495057;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 2px);
  cursor: pointer;
}
.date-picker-btn:hover {
  background: #f9f9f9;
}

.icon {
  width: 16px;
  height: 16px;
}

.selected-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 12px;
}

.selected-tag {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  background: #f8f9fa;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 12px;
}

.tag-remove {
  padding: 0;
  border: none;
  background: none;
  color: #666;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  transition: background-color 0.2s;
}

.tag-remove:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #333;
}

.popup-footer {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #e0e0e0;
  display: flex;
  justify-content: flex-end;
}

.apply-button {
  background: #000;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 13px;
  transition: background-color 0.2s;
  width:100%;
}

.apply-button:hover {
  background: #333;
}

.popup-content {
  max-height: 300px;
  overflow-y: auto;
}

.popup-options {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.popup-option {
  padding: 8px 12px;
  border-radius: 6px;
  transition: background 0.2s;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  width: 98%;
}

.popup-option:hover {
  background: #f9f9f9;
}

/* Ajoutez les styles de la checkbox moderne */
.popup-option input[type="checkbox"] {
  position: relative;
  width: 18px;
  height: 18px;
  cursor: pointer;
  appearance: none;
  border: 2px solid #e0e0e0;
  border-radius: 4px;
  background: white;
  transition: all 0.2s ease;
}

.popup-option input[type="checkbox"]:hover {
  border-color: #000;
}

.popup-option input[type="checkbox"]:checked {
  background: #000;
  border-color: #000;
}

.popup-option input[type="checkbox"]:checked:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 2px;
  width: 2px;
  height: 7px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.popup-option input[type="checkbox"]:focus {
  outline: none;
}

/* Style pour le label quand la checkbox est cochée */
.popup-option input[type="checkbox"]:checked ~ span {
  color: #000;
  font-weight: 500;
}
/* 
.popup-option:has(input:checked) {
  background: #f7f6f3;
} */

.popup-option:hover {
  background: #f8f9fa;
}

/* Personnalisation des scrollbars */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #999;
}

.search-input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  font-size: 13px;
  margin-bottom: 12px;
}

.popup-search {
  margin-bottom: 12px;
}
</style>
