<template>
  <div class="refund-summary">
    <SvgLoader class="refund-summary__loader" v-if="loading"/>
    <template v-else>
      <!-- Affichage des items -->
      <template v-if="data?.refundItems && data.refundItems.length > 0">
        <div v-for="item in data.refundItems" :key="item.id" class="refund-summary__item">
          <TextSimple color="grey">{{ item.name || $t('global.wear') }}</TextSimple>
          <TextSimple>{{ item.refund?.amount | formatPrice }}</TextSimple>
        </div>
      </template>

      <!-- Affichage des frais -->
      <template v-if="data?.refundFees && data.refundFees.length > 0">
        <div v-for="fee in data.refundFees" :key="fee.id" class="refund-summary__item">
          <TextSimple color="grey">{{ $t(`order.refund.${fee.id}_fee`) }}</TextSimple>
          <TextSimple>{{ fee.amount | formatPrice }}</TextSimple>
        </div>
      </template>

      <!-- Total -->
      <div class="refund-summary__total">
        <TextSimple size="l" weight="medium">{{ $t("global.refundTotalTtc") }}</TextSimple>
        <TextSimple v-if="data?.amount" size="xl" weight="medium">{{ data.amount | formatPrice }}</TextSimple>
        <TextSimple v-else size="xl" weight="medium">--</TextSimple>
      </div>

      <!-- Détails des méthodes de remboursement -->
      <template v-if="data?.transactions && data.transactions.length > 0">
        <br>
        <div v-for="transaction in data.transactions" :key="transaction.id" class="refund-summary__item">
          <TextSimple color="grey">{{ $t('global.refund_on') }} {{transaction.type}}</TextSimple>
          <TextSimple>{{ transaction.amount | formatPrice }}</TextSimple>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import TextSimple from '@/components/ui/text/TextSimple';
import SvgLoader from '@/components/ui/svg/SvgLoader';

export default {
  name: 'RefundSummary',
  components: {
    TextSimple,
    SvgLoader
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
.refund-summary {
  &__loader {
    width: 40px;
    margin: 0 auto;
    display: block;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }

  &__total {
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid var(--color-grey-light);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>