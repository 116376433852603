import CatalogueAPI from "@/api/api-catalogue";
import listRoutes from "@/api/list-routes";
import API from "@/api/api-admin";
import Vue from "vue";
import i18n from "@/i18n";

const state = {
  articles: null,
  articlesLoading: false,
  exportLoading: false,

  reportingList: [],
  reportingListLoading: false,

  article: {},
  articleLoading: false,

  customTags: null,
  customTagsLoading: null,

  customTagsFromRecommerceMerch: null,
  customTagsFromRecommerceMerchLoading: false,

  productsEan: null,

  articleFieldListValue: {},
  articleFieldListValueLoading: false,

  editArticlePayload: {},

  totalStatePublish: null,
  totalStatePublishLoading: false,
};
const mutations = {
  // ARTICLES
  SET_ARTICLES(state, data) {
    state.articles = data;
  },
  SET_ARTICLES_LOADING(state, data) {
    Vue.set(state, "articlesLoading", data);
  },

  // REPORTING
  SET_REPORTING_LIST(state, data) {
    state.reportingList = data;
  },
  SET_REPORTING_LIST_LOADING(state, loading) {
    state.reportingListLoading = loading;
  },

  SET_ARTICLE_REASONS(state, data) {
    if (state.article && Array.isArray(data)) {
      Vue.set(state.article, "reportReasons", data);
    }
  },

  //UPDATE ARTICLE STATUS
  UPDATE_ARTICLES_STATUS(state, { ids, newStatus }) {
    if (state.articles && state.articles.items) {
      state.articles.items = state.articles.items.map((article) => {
        if (ids.includes(article.id)) {
          return {
            ...article,
            articleCard: {
              ...article.articleCard,
              state: newStatus,
            },
          };
        }
        return article;
      });
    }
  },

  SET_EXPORT_LOADING(state, val) {
    state.exportLoading = val;
  },
  SET_ARTICLE(state, data) {
    Vue.set(state, "article", data);
  },
  UPDATE_ARTICLE_STATUS(state, data) {
    Vue.set(state.article, "items", data);
  },
  SET_ARTICLE_LOADING(state, loading) {
    state.articlesLoading = loading;
  },
  SET_TOTAL_STATE_PUBLISH(state, data) {
    Vue.set(state, "totalStatePublish", {
      ...data,
      draft: data.draft || 0,
      unpublished: data.unpublished || 0,
    });
  },
  SET_TOTAL_STATE_PUBLISH_LOADING(state, loading) {
    Vue.set(state, "totalStatePublishLoading", loading);
  },

  SET_TAB_COUNTERS(state, counters) {
    state.tabCounters = counters;
  },

  // CUSTOM TAGS
  SET_CUSTOM_TAGS(state, data) {
    state.customTags = data;
  },
  SET_CUSTOM_TAGS_LOADING(state, data) {
    state.customTagsLoading = data;
  },

  SET_CUSTOM_TAGS_FROM_RECOMMERCE_MERCH(state, data) {
    state.customTagsFromRecommerceMerch = data;
  },
  SET_CUSTOM_TAGS_FROM_RECOMMERCE_MERCH_LOADING(state, data) {
    state.customTagsFromRecommerceMerchLoading = data;
  },
  SET_PRODUCTS_EAN(state, data) {
    Vue.set(state, "productsEan", data);
  },
  // EDIT ARTICLE
  SET_ARTICLE_FIELD_LIST_VALUE(state, { lang, field, data }) {
    if (!state.articleFieldListValue[lang]) {
      state.articleFieldListValue[lang] = {};
    }
    if (state.articleFieldListValue[lang][field]) {
      state.articleFieldListValue[lang][field] =
        state.articleFieldListValue[lang][field].concat(data);
    } else {
      state.articleFieldListValue[lang][field] = data;
    }
  },
  RESET_ARTICLE_FIELD_LIST_VALUE(state, data) {
    Vue.set(state, "articleFieldListValue", data);
  },
  SET_ARTICLE_FIELD_LIST_VALUE_LOADING(state, isLoading) {
    Vue.set(state, "articleFieldListValueLoading", isLoading);
  },
  SET_EDIT_ARTICLE_PAYLOAD(state, payload) {
    Vue.set(state, "editArticlePayload", payload);
  },
  SET_EDIT_ARTICLE_PHOTOS_PAYLOAD(state, { lang, data, event }) {
    if (event?.length) Vue.set(state.editArticlePayload[lang], data, event);
    if (!event?.length) Vue.delete(state.editArticlePayload[lang], data);
  },
  SET_ARTICLE_PHOTOS(state, photos) {
    Vue.set(state.article, "photos", photos);
  },
};
const actions = {
  // ARTICLES

  exportArticles({ commit }, params) {
    commit("SET_EXPORT_LOADING", true);

    // Récupérer la locale depuis i18n
    const currentLocale = i18n.locale || "fr";

    // Créer un mapping pour convertir les locales
    const localeMap = {
      en: "en_GB",
      fr: "fr_FR",
      // ajoute d'autres si besoin ("es": "es_ES", etc.)
    };

    // Convertir la locale en fonction du mapping
    const locale = localeMap[currentLocale] || "fr_FR";

    // Ajout de la locale dans les paramètres
    const paramsWithLocale = {
      ...params,
      adminLocale: locale,
    };

    return API.get(
      `${listRoutes.newArticles}/export?${new URLSearchParams(
        paramsWithLocale
      ).toString()}`
    ).finally(() => commit("SET_EXPORT_LOADING", false));
  },

  fetchArticles({ commit }, payload) {
    const allValuesNotUndefined = Object.values(payload).every(
      (elem) => elem !== undefined
    );

    if (allValuesNotUndefined) {
      const queryString = Object.keys(payload)
        .filter((key) => {
          return payload[key] !== "";
        })
        .map((key) => {
          return `${key}=${payload[key]}`;
        })
        .join("&");

      commit("SET_ARTICLES_LOADING", true);
      return API.get(`${listRoutes.newArticles}?${queryString}`)
        .then(async (res) => {
          // Récupérer les reportReasons pour chaque article en statut to_correct
          const articlesWithReasons = await Promise.all(
            res.data.items.map(async (item) => {
              if (item.articleCard?.state === "to_correct") {
                try {
                  const reportResponse = await API.get(
                    listRoutes.newArticlesReportingArticle.replace(
                      ":id",
                      item.articleCard.id
                    )
                  );
                  return {
                    ...item,
                    reportReasons: reportResponse.data,
                  };
                } catch (error) {
                  console.error("Error fetching report reasons:", error);
                  return item;
                }
              }
              return item;
            })
          );

          commit("SET_ARTICLES", {
            ...res.data,
            items: articlesWithReasons,
          });
          return {
            ...res.data,
            items: articlesWithReasons,
          };
        })
        .catch((error) => {
          console.error("Erreur lors de la récupération des articles:", error);
          throw error;
        })
        .finally(() => {
          commit("SET_ARTICLES_LOADING", false);
        });
    } else {
      return Promise.reject(new Error("Invalid parameters"));
    }
  },
  fetchArticlesSingle({ commit }, payload) {
    commit("SET_ARTICLE_LOADING", true);
    commit("SET_ARTICLE", null);

    const queryString = Object.keys(payload)
      .filter((key) => {
        return payload[key] !== "" && payload[key] !== undefined;
        // && payload[key] !== '' && payload[key] !== undefined && !isNaN(payload[key]);
      })
      .map((key) => {
        return `${key}=${payload[key]}`;
      })
      .join("&");

    if (isNaN(payload.page)) return;

    API.get(`${listRoutes.newArticles}?${queryString}`)
      .then((res) => {
        commit("SET_ARTICLE", res.data);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des articles:", error);
      })
      .finally(() => {
        commit("SET_ARTICLE_LOADING", false);
      });
  },

  fetchNavIds(_, payload) {
    const queryString = Object.keys(payload)
      .map((key) => `${key}=${payload[key]}`)
      .join("&");

    if (payload.page === "undefined" || payload.limit === "") return;
    return API.get(`${listRoutes.newArticles}?${queryString}`).then((res) => {
      const allIDS = res.data.items.map((elem) => elem.id);
      localStorage.setItem("navFrom", JSON.stringify(payload));
      localStorage.setItem("articlesIDS", JSON.stringify(allIDS));
      return allIDS;
    });
  },

  fetchTotalStatePublish({ commit }, currency) {
    commit("SET_TOTAL_STATE_PUBLISH_LOADING", true);
    let url = listRoutes.totalStatepublishArticles;

    const searchParams = new URLSearchParams();

    // Gérer le format correct du currency
    if (typeof currency === "object" && currency !== null) {
      // Si c'est un objet avec les paramètres de filtre
      Object.entries(currency).forEach(([key, value]) => {
        searchParams.append(key, value);
      });
    } else if (currency) {
      // Si c'est une simple valeur de currency
      searchParams.append("currency[in]", currency);
    }

    if (searchParams.toString()) {
      url += `?${searchParams.toString()}`;
    }

    return API.get(url)
      .then((res) => {
        commit("SET_TOTAL_STATE_PUBLISH", res.data);
        return res.data;
      })
      .finally(() => {
        commit("SET_TOTAL_STATE_PUBLISH_LOADING", false);
      });
  },

  resetArticles({ commit }) {
    commit("SET_ARTICLE", null);
    commit("SET_ARTICLE_LOADING", true);
  },
  fetchArticle({ commit }, id) {
    commit("SET_ARTICLE_LOADING", true);
    commit("SET_ARTICLE", null);

    // Retourner la promesse API
    return API.get(listRoutes.newArticle.replace(":id", id))
      .then((res) => {
        commit("SET_ARTICLE", res.data);
        return res.data; // Optionnel, pour faciliter le chainage
      })
      .catch((error) => {
        console.error("Error fetching articles:", error);
        throw error;
      })
      .finally(() => {
        commit("SET_ARTICLE_LOADING", false);
      });
  },

  updateArticleMetadata({ commit }, { id, metadata }) {
    return API.put(`${listRoutes.putNewArticle.replace(":id", id)}`, {
      metadata,
    }).then((res) => {
      commit("SET_ARTICLE", res.data);
      return res.data;
    });
  },

  async updateArticlePhotos({ commit }, { id, photos }) {
    try {
      const res = await API.put(
        `${listRoutes.putNewArticle.replace(":id", id)}`,
        { photos: photos }
      );
      commit("SET_NOTIFICATION_MESSAGE", {
        text: i18n.t("articles.photos_updated"),
        color: "green",
        confirmation: false,
        duration: 3000,
      });
      commit("SET_ARTICLE", res.data);
      return res.data;
    } catch {
      commit("SET_NOTIFICATION_MESSAGE", {
        text: i18n.t("articles.error_while_updating_photos"),
        color: "red",
        confirmation: true,
        duration: 3000,
      });
    }
  },

  // CUSTOM TAGS
  getAllCustomTags({ commit }, id) {
    commit("SET_CUSTOM_TAGS_LOADING", true);
    CatalogueAPI.get(`${listRoutes.allCustomTagsByBrand.replace(":id", id)}`)
      .then((res) => {
        commit("SET_CUSTOM_TAGS", res.data);
      })
      .finally(() => {
        commit("SET_CUSTOM_TAGS_LOADING", false);
      });
  },
  postCustomTag({ dispatch }, payload) {
    const { brandID, ...sendValues } = payload;

    CatalogueAPI.post(
      `${listRoutes.postCustomTags.replace(":brandID", brandID)}`,
      sendValues
    ).then((res) => {
      if (res.status === 200) dispatch("getAllCustomTags", brandID);
    });
  },
  deleteCustomTag({ dispatch }, payload) {
    const { id, tagToDelete } = payload;

    CatalogueAPI.delete(
      `${listRoutes.deleteCustomTags
        .replace(":id", parseInt(id))
        .replace(":customTag", tagToDelete)}`
    ).then((res) => {
      if (res.status === 204) dispatch("getAllCustomTags", id);
    });
  },

  async deleteCustomTagsByNameAndBrand({ dispatch }, payload) {
    const { brandID, customTag } = payload;

    try {
      const response = await CatalogueAPI.get(
        `${listRoutes.allCustomTagsByNameAndBrand
          .replace(":brandID", parseInt(brandID))
          .replace(":customTag", customTag)}`
      );
      const ids = response.data.map((elem) => ({
        id: brandID,
        tagToDelete: elem.id,
      }));

      // Crée un tableau de promesses pour chaque suppression
      const deletePromises = ids.map((id) =>
        dispatch("deleteCustomTag", { id: id.id, tagToDelete: id.tagToDelete })
      );

      // Attend la résolution de toutes les promesses avant de continuer
      await Promise.all(deletePromises);

      // Retourne une valeur ou un message indiquant le succès de l'opération
      return true;
    } catch (error) {
      console.error(
        "Erreur lors de la récupération ou suppression des tags personnalisés :",
        error
      );
    }
  },

  getProductsEansFromRecommerceMerchandising({ commit }, data) {
    commit("SET_CUSTOM_TAGS_FROM_RECOMMERCE_MERCH_LOADING", true);
    const { brandID, ean, state } = data;
    return CatalogueAPI.get(
      `${listRoutes.productsEan
        .replace(":brandID", brandID)
        .replace(":ean", ean)
        .replace(":state", state)}`
    )
      .then((res) => {
        commit("SET_CUSTOM_TAGS_FROM_RECOMMERCE_MERCH", res.data);
      })
      .catch((err) => {
        commit("SET_NOTIFICATION_MESSAGE", {
          text: i18n.t(err.response.data.message),
          color: "red",
          confirmation: true,
        });
      })
      .finally(() => {
        commit("SET_CUSTOM_TAGS_FROM_RECOMMERCE_MERCH_LOADING", false);
      });
  },

  updateArticleStatus({ commit }, payload) {
    const { id, ...rest } = payload;
    commit("SET_ARTICLE_LOADING", true);
    API.put(`${listRoutes.putNewArticle.replace(":id", id)}`, rest)
      .then((res) => {
        commit("UPDATE_ARTICLE_STATUS", [res.data]);
      })
      .finally(() => {
        commit("SET_ARTICLE_LOADING", false);
      });
  },
  updateArticleStatusAlt({ commit }, payload) {
    const { id, ...rest } = payload;
    commit("SET_ARTICLE_LOADING", true);
    API.put(`${listRoutes.putNewArticle.replace(":id", id)}`, rest)
      .then((res) => {
        commit("SET_ARTICLE", res.data);
        commit("SET_ARTICLE_LOADING", false);
      })
      .catch((error) => {
        commit("SET_ARTICLE_LOADING", false);
        commit("SET_NOTIFICATION_MESSAGE", {
          text: error,
          color: "red",
          confirmation: true,
        });
      });
  },

  // EDIT ARTICLE

  getArticleFieldsListValue({ commit }, payload) {
    const { locale, brand_id, field } = payload;

    if (field.length && locale.length) {
      return CatalogueAPI.get(
        `${listRoutes.catalogueFieldListValue
          .replace(":lang", locale)
          .replace(":id", brand_id)
          .replace(":field", field)}`
      )
        .then((res) => {
          commit("SET_ARTICLE_FIELD_LIST_VALUE", {
            lang: locale,
            field: field,
            data: res.data,
          });
        })
        .catch((err) => {
          commit("SET_NOTIFICATION_MESSAGE", {
            text: i18n.t(err.response.data.message),
            color: "red",
            confirmation: true,
          });
        });
    }
  },
  async submitFieldList({ commit, dispatch }, payload) {
    const { brandID, lang, fields } = payload;

    commit("RESET_ARTICLE_FIELD_LIST_VALUE", {});
    commit("SET_ARTICLE_FIELD_LIST_VALUE_LOADING", true);

    try {
      await dispatch("catalogueGetAllFields", brandID);

      for (const language of lang) {
        for (const field of fields) {
          await dispatch("getArticleFieldsListValue", {
            locale: language,
            brand_id: brandID,
            field: field,
          });
        }
      }
    } catch (error) {
      commit("SET_NOTIFICATION_MESSAGE", {
        text: i18n.t(error.response.data.message),
        color: "red",
        confirmation: true,
      });
    } finally {
      commit("SET_ARTICLE_FIELD_LIST_VALUE_LOADING", false);
    }
  },

  appendEditArticlePayload({ commit }, payload) {
    commit("SET_EDIT_ARTICLE_PAYLOAD", payload);
  },
  appendPhotosArticlePayload({ commit }, payload) {
    commit("SET_EDIT_ARTICLE_PHOTOS_PAYLOAD", payload);
  },

  checkSubmitedPayload({ commit, dispatch, state }, payload) {
    const stateEditArticlePayload = state.editArticlePayload;

    const languagesWithValues = {};
    let allLanguagesHaveValues = true;

    // Vérifie que chaque langue n'est pas vide
    for (const language in stateEditArticlePayload) {
      if (
        Object.prototype.toString.call(stateEditArticlePayload[language]) ===
          "[object Object]" &&
        Object.keys(stateEditArticlePayload[language]).length > 0
      ) {
        // Si la langue n'est pas vide, l'ajoute à l'objet des langues avec valeurs
        languagesWithValues[language] = stateEditArticlePayload[language];
      } else {
        allLanguagesHaveValues = false;
      }
    }

    // Ajoute une vérification pour s'assurer que languagesWithValues n'est pas un objet vide
    const hasAnyLanguageWithValue = Object.keys(languagesWithValues).length > 0;

    // Vérifie si toutes les langues ont des valeurs et si au moins une langue a des valeurs
    if (allLanguagesHaveValues || hasAnyLanguageWithValue) {
      return dispatch("editArticle", {
        datas: allLanguagesHaveValues
          ? stateEditArticlePayload
          : languagesWithValues,
        identification: payload,
      });
    } else {
      // Si aucune langue n'a de valeurs, gère ce cas
      commit("SET_NOTIFICATION_MESSAGE", {
        text: i18n.t("articles.no_value_in_payload"),
        color: "red",
        confirmation: false,
      });
    }
  },
  editArticle({ commit }, payload) {
    const { datas, identification } = payload;
    const { brandID, ean } = identification;

    return CatalogueAPI.post(
      `${listRoutes.editArticle
        .replace(":brandID", brandID)
        .replace(":ean", ean)}`,
      datas
    )
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          return true;
        }
      })
      .catch((err) => {
        console.log(err);
        commit("SET_NOTIFICATION_MESSAGE", {
          text: i18n.t("articles.error_while_edit_article_submission"),
          color: "red",
          confirmation: false,
        });
      });
  },

  //REPORT PROBLEM

  async getReportingList({ commit }) {
    commit("SET_REPORTING_LIST_LOADING", true);
    try {
      const response = await API.get(listRoutes.newArticlesReporting);
      commit("SET_REPORTING_LIST", response.data);
      return response.data;
    } catch (error) {
      console.error("Erreur lors de la récupération de la liste:", error);
      throw error;
    } finally {
      commit("SET_REPORTING_LIST_LOADING", false);
    }
  },
  async fetchArticleReport({ commit }, id) {
    if (!id) return;

    try {
      const url = listRoutes.newArticlesReportingArticle.replace(":id", id);
      const res = await API.get(url);
      if (res.data) {
        commit("SET_ARTICLE_REASONS", res.data);
        return res.data;
      }
      return [];
    } catch (error) {
      console.warn("Pas de raisons de report trouvées");
      commit("SET_ARTICLE_REASONS", []);
      return [];
    }
  },
  submitReport({ commit }, { id, reason, description }) {
    return API.post(listRoutes.newArticlesReportingArticle.replace(":id", id), {
      reason,
      description,
    }).then(() => {
      commit("SET_NOTIFICATION_MESSAGE", {
        text: i18n.t("article_put_online.notification_popin.error_reported"),
        color: "green",
        confirmation: false,
        duration: 3000,
      });
    });
  },

  //update status of articles
  async updateArticleState({ commit }, { ids, toStatus }) {
    commit("SET_ARTICLE_LOADING", true);
    try {
      // Faire la requête pour récupérer les articles concernés
      const articlePromises = ids.map((id) =>
        API.get(`${listRoutes.newArticles}/${id}`)
          .then((response) => response.data)
          .catch((error) => {
            console.error(
              `Erreur lors de la récupération de l'article ${id}:`,
              error
            );
            return null;
          })
      );

      const articles = (await Promise.all(articlePromises)).filter(
        (article) => article !== null
      );

      // Mettre à jour le statut de chaque article
      const updatePromises = articles.map((article) => {
        if (!article?.articleCard?.id) {
          console.error(`Article card ID not found for article ${article.id}`);
          return null;
        }

        return API.put(
          listRoutes.newArticlesChangeState
            .replace(":id", article.articleCard.id)
            .replace(":state", toStatus)
        );
      });

      await Promise.all(updatePromises.filter((promise) => promise !== null));

      // Mise à jour optimiste du state local
      commit("UPDATE_ARTICLES_STATUS", { ids, newStatus: toStatus });

      // Déterminer le message de notification en fonction du statut
      let notificationKey;
      let notificationColor = "green";

      switch (toStatus) {
        case "on_sale":
          notificationKey = "item_published";
          break;
        case "unpublished":
          notificationKey = "item_unpublished";
          break;
        case "to_correct":
          notificationKey = "error_reported";
          break;
        case "to_publish":
          notificationKey = "item_to_publish";
          break;
        default:
          notificationKey = "item_published"; // Cas par défaut
      }

      // Afficher la notification avec la bonne traduction
      commit("SET_NOTIFICATION_MESSAGE", {
        text: i18n.t(
          `article_put_online.notification_popin.${notificationKey}`
        ),
        color: notificationColor,
        confirmation: false,
        duration: 3000,
      });

      return true;
    } catch (error) {
      console.error("Erreur lors de la mise à jour des statuts:", error);

      // Déterminer le message d'erreur en fonction du statut
      let errorKey;
      switch (toStatus) {
        case "on_sale":
          errorKey = "item_published_error";
          break;
        case "unpublished":
          errorKey = "item_unpublished_error";
          break;
        case "to_correct":
          errorKey = "error_reported_error";
          break;
        case "to_publish":
          errorKey = "item_to_publish_error";
          break;
        default:
          errorKey = "item_published_error"; // Cas par défaut
      }

      commit("SET_NOTIFICATION_MESSAGE", {
        text: i18n.t(`article_put_online.notification_popin.${errorKey}`),
        color: "red",
        confirmation: false,
        duration: 3000,
      });

      throw error;
    } finally {
      commit("SET_ARTICLE_LOADING", false);
    }
  },
};
const getters = {};

const articles = {
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
};

export default articles;
