<template>
    <div class="CardRefund">
        <div class="CardRefund__content">
            <SvgIcon name="gift"></SvgIcon>
            <div>
                <TextSimple>{{ text}}</TextSimple>
                <TextSimple weight="medium">{{ amount+'' | formatPrice }}</TextSimple>
            </div>
        </div>
        <SvgIcon name="cross" @click.native="$emit('close')"></SvgIcon>
    </div>
</template>

<script>
import SvgIcon from '@/components/ui/svg/SvgIcon';
import TextSimple from "@/components/ui/text/TextSimple";
export default {
    components: { SvgIcon, TextSimple},
    props: {
        text: [String],
        amount: [Number],
    },
    emits: ['close']
}
</script>

<style lang="scss">
.CardRefund {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-radius: 10px;
    color: var(--color-green);
    background-color: var(--color-green-light);
    margin-bottom: 10px;
    --svg-color: var(--color-green);
    &__content{
        gap: 12px;
        display: flex;
        flex-direction: row;
    }
}
</style>