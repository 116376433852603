import API from "@/api/api-admin";
import listRoutes from "@/api/list-routes";
import i18n from "@/i18n.js";

const get = (commit, shopifyId, isRecom) => {
  commit("SET_RESALE", null);
  commit("SET_RESALE_LOADING", true);
  return new Promise((resolve, reject) => {
    API.get(
      listRoutes[isRecom ? "recomResale" : "resale"].replace(":id", shopifyId)
    )
      .then((res) => {
        if (!res.data || !res.data.id) reject();
        else {
          commit("SET_RESALE", res.data);
          resolve();
        }
      })
      .catch(() => reject())
      .finally(() => commit("SET_RESALE_LOADING", false));
  });
};

const state = {
  resale: null,
  recom_tradeinArticle: {
    metadata: { title: "" },
    initialReview: {},
    controlReview: {},
    photos: [],
  },
  resaleLoading: false,
  recom_tradeinArticleLoading: true,
};

const mutations = {
  SET_RESALE(state, data) {
    state.resale = data;
  },
  SET_TRADEINARTICLE(state, data) {
    if (!data) {
      state.recom_tradeinArticle = {
        metadata: { title: "" },
        initialReview: {},
        controlReview: {},
        photos: [],
      };
    } else {
      // S'assurer que 'metadata' existe et contient 'title'
      const safeData = { ...data };
      if (!safeData.metadata) {
        safeData.metadata = { title: "" };
      } else if (!safeData.metadata.title) {
        safeData.metadata = {
          ...safeData.metadata,
          title: "",
        };
      }

      // S'assurer que d'autres propriétés requises existent
      if (!safeData.initialReview) safeData.initialReview = {};
      if (!safeData.controlReview) safeData.controlReview = {};
      if (!safeData.photos) safeData.photos = [];

      state.recom_tradeinArticle = safeData;
    }
  },
  SET_RESALE_LOADING(state, value) {
    state.resaleLoading = value;
  },
  SET_TRADEINARTICLE_LOADING(state, value) {
    state.recom_tradeinArticleLoading = value;
  },
  UNSET_RESALE_USER(state) {
    if (state.resale) delete state.resale.user;
  },
};

const actions = {
  getResale({ commit }, shopifyId) {
    return get(commit, shopifyId, false);
  },
  getRecomResale({ commit }, shopifyId) {
    return get(commit, shopifyId, true);
  },
  createResaleShipping(_, data) {
    return new Promise((resolve, reject) => {
      data = {
        ...data,
        client: data.client ? Number(data.client) : null,
        isReturn: data.isReturn || false,
      };
      API.post(listRoutes.resalesShipping, data)
        .then((res) => res.data)
        .then((data) => resolve(data))
        .catch((err) => reject(err.message || err));
    });
  },
  removeUserFromResale({ commit }, id) {
    return new Promise((resolve, reject) => {
      API.get(listRoutes.resaleRemoveUser.replace(":id", id))
        .then((res) => res.data)
        .then(() => {
          commit("UNSET_RESALE_USER");
          commit(
            "SET_NOTIFICATION_MESSAGE",
            i18n.t("resale.remove_user.confirmation_message")
          );
          resolve();
        })
        .catch(() => reject());
    });
  },
  getTradeinArticle({ commit }, id) {
    return new Promise((resolve, reject) => {
      API.get(listRoutes.recomTradeinArticle.replace(":id", id))
        .then((res) => {
          if (!res.data || !res.data.id) reject();
          else {
            commit("SET_TRADEINARTICLE", res.data);
            resolve();
          }
        })
        .catch(() => reject())
        .finally(() => commit("SET_TRADEINARTICLE_LOADING", false));
    });
  },
};

const getters = {};

const resales = {
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
};

export default resales;
