<template>
  <TemplateTable
    :title="$t('menu.recom_articles')"
    :text="$t('recom_articles.description')"
    name="recomSoldArticles"
    :url="$listRoutes.recomSoldArticles"
    :table="newRecomSoldArticles"
    :filters="recomSoldArticlesFilters"
    :fields="fields"
    scope="filters.soldArticles"
  />
</template>

<script>
import { mapState } from "vuex";
import TemplateTable from "@/components/templates/TemplateTable";
import currencyRouteMixin from "@/mixins/currencyRouteMixin";

export default {
  name: "RecomSoldArticles",
  mixins: [currencyRouteMixin],
  components: { TemplateTable },
  data() {
    return {
      loading: true,
      fields: [
        {
          key: "tradeIn.id",
          type: "eye",
          translationKey: "id",
          route: "recom_tradein-n-sell-article",
          size: "xs",
          query: { view: "sell" },
        },
        {
          key: "order.customer.email",
          type: "copy",
          size: "l",
          translationKey: "buyerEmail",
        },
        {
          key: "tradeIn.reference",
          translationKey: "articleId",
          size: "l",
          type: "tradeinItem",
          query: { view: "sell" },
        },
        { key: "tradeIn.ean", size: "l" },
        { key: "productName", size: "xxl" },
        { key: "tradeIn.sku", translationKey: "sku", size: "m" },
        { key: "state", type: "tagType", size: "m" },
        { key: "tradeIn.condition", type: "tagType", size: "m" },
        {
          key: "tradeIn.amountProposed",
          translationKey: "tradeInPrice",
          type: "formatPrice",
        },
        {
          key: "tradeIn.amountCredited",
          translationKey: "credit_price",
          size: "s",
          type: "formatPrice",
        },
        {
          key: "unitPrice",
          translationKey: "ttcSellPrice",
          type: "formatPrice",
        },
        {
          key: "order.customer.profile",
          type: "tagType",
          translationKey: "buyerProfil",
          size: "m",
        },
        {
          key: "order.reference",
          translationKey: "idOrder",
          size: "l",
          type: "recomOrderId",
        },
        {
          key: "order.paidAt",
          translationKey: "orderDate",
          type: "date",
          size: "m",
        },
        {
          key: "shippingDate",
          translationKey: "shippingDate",
          type: "date",
          size: "m",
        },
        {
          key: "returnDate",
          translationKey: "returnDate",
          type: "date",
          size: "m",
        },
        {
          key: "refundDate",
          translationKey: "refundDate",
          type: "date",
          size: "m",
        },
        {
          key: "order.channel",
          translationKey: "canalVente",
          type: "tagType",
          size: "m",
        },
        { key: "order.shippingAddress.countryCode", size: "m" },
      ],
    };
  },
  computed: {
    ...mapState(["recomSoldArticles", "recomSoldArticlesFilters"]),
    newRecomSoldArticles() {
      if (this.recomSoldArticles?.items.length) {
        return {
          ...this.recomSoldArticles,
          items: this.recomSoldArticles.items.map((item) => {
            return {
              ...item,
              sellingDate: item.order.payments?.[0]?.createdAt,
            };
          }),
        };
      } else {
        return null;
      }
    },
  },
  watch: {
    newRecomSoldArticles() {
      this.loading = false;
    },
  },
};
</script>
