<template>
  <FieldSwitch
    :model="$i18n.locale"
    :title_1="languages[0].toUpperCase()"
    :title_2="languages[1].toUpperCase()"
    :value_1="languages[0]"
    :value_2="languages[1]"
    ref="switch"
    @change="handleLocaleChange"
  />
</template>

<script>
import { mapActions } from "vuex";
import FieldSwitch from "@/components/ui/form/fields/FieldSwitch";
export default {
  name: "LayoutSwitchLang",
  components: { FieldSwitch },
  data() {
    return {
      languages: ["fr", "en"],
    };
  },
  watch: {
    "$i18n.locale": function (lang) {
      window.localStorage.setItem("lang", lang);
    },
  },
  methods: {
    ...mapActions(["updateUserLocale"]),

    async handleLocaleChange(locale) {
      const apiLocale = locale === "fr" ? "fr_FR" : "en_GB";

      try {
        await this.updateUserLocale(apiLocale);
        // Met à jour la langue de l'interface
        this.$i18n.locale = locale;

        this.$store.commit("SET_NOTIFICATION_MESSAGE", {
          text: this.$t("account.language.success"),
          color: "green",
          confirmation: false,
          duration: 3000,
        });
      } catch (error) {
        this.$store.commit("SET_NOTIFICATION_MESSAGE", {
          text: this.$t("account.language.error"),
          color: "red",
          confirmation: false,
          duration: 3000,
        });

        // En cas d'erreur, on revient à la langue précédente
        this.$refs.switch.model = this.$i18n.locale;
      }
    },
    resize() {
      const interval = setInterval(() => {
        if (this.$refs.switch) this.$refs.switch.resize();
      }, 100);
      setTimeout(() => clearInterval(interval), 1000);
    },
  },
};
</script>
