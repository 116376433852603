<template>
    <tr class="article-metadata-edit">
        <th>{{ getTableTh(field) }}</th>
        <td>
            <TextSimple>
                <span v-html="value"/>
            </TextSimple>
        </td>
        <td v-if="false">
            <SvgLoader v-if="loading" class="article-metadata-edit__loader"/>
            <TextSimple v-else-if="notEditableMetadata.includes(field)" color="grey">{{ value }}</TextSimple>
            <FieldSelect v-else-if="type === 'select'" :name="field" :selected="model" :border="true" :options="selectOptions" @input="x => handleChange(x)"/>
            <Field v-else :type="type" :name="field" :model="model" :border="true" @change="x => handleChange(x)"/>
        </td>
    </tr>
</template>

<script>
import TextSimple from '@/components/ui/text/TextSimple';
import Field from '@/components/ui/form/fields/Field';
import CatalogueAPI from '@/api/api-catalogue';
import listRoutes from '@/api/list-routes';
import SvgLoader from '@/components/ui/svg/SvgLoader';
import FieldSelect from '@/components/ui/form/fields/FieldSelect';

export default {
    name: "ArticleMetadataEdit",
    components: {FieldSelect, SvgLoader, Field, TextSimple},
    props: {
        field: String,
        value: String,
        brandId: [Number, String],
        locale: String,
    },
    data() {
        return {
            notEditableMetadata: ["state", "reference", "ean", "id"],
            model: null,
            fieldValues: null,
            valuesOrder: ['XXXS', '3XS', 'XXS', '2XS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', '2XL', 'XXXL', "3XL", 'XXXXL', '4XL', '5XL'],
        }
    },
    methods: {
        getTableTh(key) {
            let translationKeys = `articles.${key}`;
            let text = this.$t(translationKeys);
            if (text !== translationKeys) return text;
            translationKeys = `global.${key}`;
            text = this.$t(translationKeys);
            if (text !== translationKeys) return text;
            return this.$options.filters.capitalize(key.replaceAll('_', ' '));
        },
        getFieldValues() {
            this.fieldValues = null;
            if (!['description', 'composition', 'title'].includes(this.field)) {
                CatalogueAPI.get(this.catalogueRoute)
                        .then(res => this.fieldValues = res?.data)
            }
        },
        handleChange(newValue) {
            this.model = newValue;
        }
    },
    computed: {
        catalogueRoute() {
            return `${listRoutes.catalogueFieldListValue.replace(':lang', this.locale).replace(':id', this.brandId).replace(':field', this.field)}`
        },
        type() {
            if (["description", "composition"].includes(this.field)) return 'textarea';
            if (this.fieldValues?.length < 100 && this.field !== 'title') return 'select';
            return 'text';
        },
        loading() {
            return this.type !== 'textarea' && !['description', 'title'].includes(this.field) && !this.fieldValues
        },
        selectOptions() {
            if (!this.fieldValues || this.fieldValues.length > 100) return [];
            return [...this.fieldValues]
                    .sort()
                    .sort((a,b) => {
                        const x = this.valuesOrder.indexOf(a.toUpperCase());
                        const y = this.valuesOrder.indexOf(b.toUpperCase());
                        return x < y ? -1 : x > y ? 1 : 0
                    })
                    .map(x => ({
                        id: x,
                        label: x
                    }))
        },
    },
    watch: {
        value: {
            handler(value) {
                this.model = this.type === 'textarea' ? value.replaceAll('<br/>', '\n') : value;
            }, immediate: true
        },
        key: {
            handler() {
                this.getFieldValues();
            }, immediate: true
        }
    }
}
</script>

<style lang="scss">
.article-metadata-edit {
    &__loader {
        width: 35px;
        height: auto;
    }
}
</style>