import API from '@/api/api-admin';
import listRoutes from '@/api/list-routes';

const state = {
    order: null,
    orderLoading: false,
    orderRefunded: null,
    orderRefundedLoading: false,
    refundReasons: null,
    refundReasonsLoading: false,
    refundability: null,
    refundabilityLoading: false,
    dryRunData: null,
    dryRunLoading: false,
}

const mutations = {
    SET_ORDER(state, { key, value }) {
        state[key] = value;
    },
    SET_LOADING(state, { key, value }) {
        state[key] = value;
    },
    CLEAN_DRY_RUN_DATA(state) {
        state.dryRunData = null;
    }
}

const performApiCall = async (commit, route, dataKey, loadingKey, id) => {
    commit('SET_ORDER', { key: dataKey, value: null });
    commit('SET_LOADING', { key: loadingKey, value: true });
    try {
        const res = await API.get(route.replace(':id', id));
        if (!res.data || !res.data.id) throw new Error('Invalid response');
        commit('SET_ORDER', { key: dataKey, value: res.data });
    } finally {
        commit('SET_LOADING', { key: loadingKey, value: false });
    }
}

const actions = {
    async getRefundReasons({ commit }) {
        commit('SET_ORDER', { key: 'refundReasons', value: null });
        commit('SET_LOADING', { key: 'refundReasonsLoading', value: true });
        try {
            const res = await API.get(listRoutes.refundReasons);
            commit('SET_ORDER', { key: 'refundReasons', value: res.data });
        } finally {
            commit('SET_LOADING', { key: 'refundReasonsLoading', value: false });
        }
    },
    async getRefundability({ commit }, orderId) {
        commit('SET_ORDER', { key: 'refundability', value: null });
        commit('SET_LOADING', { key: 'refundabilityLoading', value: true });
        try {
            const res = await API.get(listRoutes.refundability.replace(':id', orderId));
            commit('SET_ORDER', { key: 'refundability', value: res.data });
        } finally {
            commit('SET_LOADING', { key: 'refundabilityLoading', value: false });
        }
    },
    async performRefundDryRun({ commit }, { orderId, payload }) {
        commit('SET_ORDER', { key: 'dryRunData', value: null });
        commit('SET_LOADING', { key: 'dryRunLoading', value: true });
        try {
            const res = await API.post(listRoutes.refundDryRun.replace(':id', orderId), payload);
            commit('SET_ORDER', { key: 'dryRunData', value: res.data });
            return res.data;
        } finally {
            commit('SET_LOADING', { key: 'dryRunLoading', value: false });
        }
    },
    async performRefund(_, { orderId, payload }) {
        try {
            const res = await API.post(listRoutes.refund.replace(':id', orderId), payload);
            return { success: true, data: res.data };
        } catch (error) {
            return { 
                success: false, 
                status: error.response?.status,
                message: error.response?.data?.message || 'Une erreur est survenue lors du remboursement.'
            };
        }
    },
    async getOrder({ commit }, shopifyId) {
        await performApiCall(commit, listRoutes.order, 'order', 'orderLoading', shopifyId);
    },
    async getRecomOrder({ commit }, shopifyId) {
        await performApiCall(commit, listRoutes.recomOrder, 'order', 'orderLoading', shopifyId);
    },
    async getOrderRefunded({ commit }, shopifyId) {
        await performApiCall(commit, listRoutes.orderRefunded, 'orderRefunded', 'orderRefundedLoading', shopifyId);
    },
    clearDryRunData({ commit }) {
        commit('CLEAN_DRY_RUN_DATA');
    }
}

const getters = {
    // Calcule le montant total des remboursements pour une commande
    totalRefund: state => {
        if (!state.order?.refunds?.length) return 0;
        return state.order.refunds.reduce((sum, refund) => sum + refund.amount, 0) || 0;
    },
    
    // Calcule le montant net payé par l'utilisateur (total - remboursements)
    totalPaidByUser: (state, getters) => {
        if (!state.order) return 0;
        return state.order.total - getters.totalRefund;
    },
    
    // Regroupe les remboursements par type de transaction
    refundsByType: state => {
        if (!state.order?.refunds) return [];
        
        // Créer un Map pour regrouper les montants par type de transaction
        const typeMap = new Map();
        
        state.order.refunds.forEach(refund => {
            refund.transactions.forEach(transaction => {
                const currentAmount = typeMap.get(transaction.type) || 0;
                typeMap.set(transaction.type, currentAmount + transaction.amount);
            });
        });
        
        // Convertir le Map en tableau pour l'affichage
        return Array.from(typeMap, ([type, amount]) => ({
            type,
            amount
        }));
    },
    
    // Calcule les frais de livraison déduits lors des remboursements
    deductedDelivery: state => {
        if (!state.order?.refunds) return null;
        return state.order.refunds.reduce((sum, refund) => sum + (refund.feeAmount || 0), 0);
    },
    
    // Formate les raisons de remboursement pour les menus déroulants
    formattedRefundReasons: state => {
        if (!state.refundReasons) return [];
        return Object.entries(state.refundReasons).map(([id, label]) => ({
            id,
            label
        }));
    }
}

const orders = {
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default orders