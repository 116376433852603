//TabsTable.vue
<template>
  <div class="tabs-container" v-if="displayTabs">
    <button
      v-for="tab in availableTabs"
      :key="tab.id"
      :class="['tab-button', { active: value === tab.id }]"
      @click="handleTabClick(tab.id)"
    >
      <template v-if="loadingTabs">
        <span class="skeleton-tab-block"></span>
      </template>

      <template v-else>
        {{ tab.label }}
        <span class="tab-counter">
          ({{ counters[tab.id] !== undefined ? counters[tab.id] : 0 }})
        </span>
      </template>
    </button>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "TabsTable",
  props: {
    value: {
      type: String,
      required: true,
    },
    tabs: {
      type: Array,
      required: true,
      default: () => [],
    },
    counters: {
      type: Object,
      default: () => ({}),
    },
    currentCurrency: String,
  },
  model: {
    prop: "counters",
    event: "update:counters",
  },

  computed: {
    ...mapState({
      totals: (state) => state.totalStatePublish,
      loading: (state) => state.totalStatePublishLoading,
    }),
    ...mapGetters(["isClient"]),
    
    displayTabs() {
      return !this.isClient || this.availableTabs.length > 0;
    },

    availableTabs() {
      if (this.isClient) {
        return [{ id: "on_sale", label: this.$t("article_put_online.tabs.on_sale") }];
      }
      return this.tabs;
    },

    loadingTabs() {
      return !this.totals || this.loading;
    },
  },

  watch: {
    currentCurrency: {
      handler(newCurrency) {
        if (newCurrency) {
          this.$store.dispatch("fetchTotalStatePublish", {
            "currency[in]": newCurrency,
          });
        }
      },
    },
    totals: {
      handler(newVal) {
        if (!newVal) return;
        
        if (this.isClient) {
          this.$emit("update:counters", {
            on_sale: newVal.on_sale || 0
          });
        } else {
          this.$emit("update:counters", {
            all: Object.values(newVal).reduce((sum, val) => sum + (val || 0), 0),
            on_sale: newVal.on_sale || 0,
            to_publish: newVal.to_publish || 0,
            to_correct: newVal.to_correct || 0,
          });
        }
      },
      immediate: true,
    },
  },

  methods: {
    handleTabClick(tabId) {
      this.$emit("input", tabId);
      this.$emit("tab-change", tabId);
    },
  },
};
</script>

<style scoped>
.tabs-container {
  display: inline-flex;
  background-color: #f1efec;
  padding: 2px;
  padding-bottom: 4px;
  border-radius: 8px;
}

.tab-button {
  padding: 12px 24px;
  border: none;
  background: none;
  cursor: pointer;
  color: #666;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
}
.tab-button.active {
  color: #000;
  margin-bottom: -1px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.05);
}

.tab-counter {
  margin-left: 2px;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.skeleton-tab-block {
  display: inline-block;
  width: 80px;
  height: 15px;
  background: #e0e0e0;
  border-radius: 99px;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}
</style>
