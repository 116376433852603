<template>
  <div class="listing-container">
    <!-- Header -->
    <div class="header-section">
      <div>
        <h1>{{ $t("article_put_online.title") }}</h1>
        <p>
          {{ $t("article_put_online.description") }}
        </p>
      </div>
      <button
        class="btn btn-export"
        @click="handleExport"
        :disabled="exportLoading"
      >
        <!-- Loader / Spinner -->
        <span v-if="exportLoading" class="loader-icon"></span>

        <!-- Le texte, qui peut avoir une opacité quand loading = true -->
        <span :class="{ 'opacity-50': exportLoading }">{{
          $t("table.export-data")
        }}</span>
      </button>
    </div>

    <!-- Table -->
    <BaseTable
      ref="baseTable"
      :loading="loading"
      :current-currency="currentCurrency"
      :items="filteredItems"
      :columns="dynamicColumns"
      :active-filter-tags="filterTags"
      :table-filters="tableFilters"
      :tab-counters="tabCounters"
      @update:tab-counters="tabCounters = $event"
      :publish-state-labels="publishStateLabels"
      @remove-filter-tag="handleRemoveFilterTag"
      @update-filter="handleFilterUpdate"
      :tab-value="currentTab"
      :tabs="availableTabs"
      :selectable="true"
      :row-clickable="true"
      :search-fields="['name', 'reference', 'id', 'status']"
      :current-page="pagination.currentPage"
      :total-pages="pagination.totalPages"
      :total-items="pagination.totalItems"
      :items-per-page="pagination.itemsPerPage"
      :search-placeholder="$t('article_put_online.search_placeholder')"
      @search="handleSearch"
      @row-click="goToDetails"
      @page-change="handlePageChange"
      @items-per-page-change="handleItemsPerPageChange"
      @selection-change="handleSelectionChange"
      @changeTab="handleTabChange"
      @status-selected="handleStatusSelected"
      @channel-selected="handleChannelSelected"
    >
      <template #cell-image="{ item }">
        <div class="product-image">
          <img :src="item.photo" alt="" />
        </div>
      </template>

      <template #cell-stockDate="{ item }">
        {{
          item.stockDate === "-" ? "-" : formatDateForDisplay(item.stockDate)
        }}
      </template>

      <template #cell-publicationDate="{ item }">
        {{
          item.publicationDate === "-"
            ? "-"
            : formatDateForDisplay(item.publicationDate)
        }}
      </template>

      <template #cell-status="{ item }">
        <StatusCell :status="item.status" />
      </template>

      <template #cell-channels="{ item }">
        <ChannelsCell :channels="item.channels" />
      </template>

      <template #cell-errors="{ item }">
        <ErrorsCell :errors="item.errors" />
      </template>

      <template #cell-updateDate="{ item }">
        {{
          item.updateDate === "-" ? "-" : formatDateForDisplay(item.updateDate)
        }}
      </template>

      <template #filters>
        <FilterSidebarTable
          @filters-change="handleFiltersChange"
          :initial-filters="activeFilters"
          :current-tab="currentTab"
          :table-filters="tableFilters"
        >
          <template
            #filters="{
              filters,
              updateFilter,
              operators,
              tableFilters,
              dateFilters,
              updateDateFilter,
            }"
          >
            <!-- Filtre de marques -->
            <BrandFilter
              v-if="!isClient"
              :value="filters.selectedBrands"
              :operator="filters.brandOperator"
              :operators="operators"
              :table-filters="tableFilters"
              @update="updateFilter"
            />

            <!-- Filtre d'états de publication spécifiques -->
            <PublishStateFilter
              :value="filters.selectedPublishStates"
              :operator="filters.publishStateOperator"
              :operators="operators"
              :table-filters="tableFilters"
              :current-tab="currentTab"
              @update="updateFilter"
            />

            <!-- Filtre de date de stock -->
            <DateFilter
              type="stockDate"
              :label="$t('article_put_online.stockDate')"
              :value1="dateFilters.stockDate.value1"
              :value2="dateFilters.stockDate.value2"
              @update="updateDateFilter"
            />

            <!-- Filtre de date de publication -->
            <DateFilter
              type="publicationDate"
              :label="$t('article_put_online.publicationDate')"
              :value1="dateFilters.publicationDate.value1"
              :value2="dateFilters.publicationDate.value2"
              @update="updateDateFilter"
            />

            <!-- Filtre d'erreurs -->
            <ErrorFilter
              v-if="!isClient"
              :value="filters.selectedErrors"
              :operator="filters.errorOperator"
              :operators="operators"
              :table-filters="tableFilters"
              @update="updateFilter"
            />
          </template>
        </FilterSidebarTable>
      </template>
    </BaseTable>

    <!-- Modals -->

    <BaseModal
      v-if="showStatusConfirmationModal && !isClient"
      :title="$t('article_put_online.modal_confirmation_status.title')"
      :subtitle="$t('article_put_online.modal_confirmation_status.subtitle')"
      :selected-items="selectedItems"
      :submit-text="
        $t('article_put_online.modal_confirmation_status.btn_confirm')
      "
      :cancel-text="
        $t('article_put_online.modal_confirmation_status.btn_cancel')
      "
      @close="showStatusConfirmationModal = false"
      @submit="confirmStatusChange"
    >
      <template #content>
        <div class="status-confirmation">
          <div class="action-section">
            <h4>
              {{
                $t(
                  "article_put_online.modal_confirmation_status.action_performed"
                )
              }}
            </h4>
            <div class="status-change">
              <StatusCell :status="fromStatus" />
              <span class="arrow">→</span>
              <StatusCell :status="toStatus" />
            </div>
          </div>
        </div>
      </template>
    </BaseModal>

    <PopinNotification ref="notification" />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import moment from "moment";
import dateFormatMixin from "@/mixins/dateFormatMixin";
import BaseTable from "@/components/ui/dataTable/BaseTable.vue";
import StatusCell from "@/components/ui/dataTable/StatusCell.vue";
import ChannelsCell from "@/components/ui/dataTable/ChannelsCell.vue";
import ErrorsCell from "@/components/ui/dataTable/ErrorsCell.vue";
import BaseModal from "@/components/ui/modals/BaseModal.vue";
import PopinNotification from "@/components/ui/popin/PopinNotification.vue"; // à adapter selon votre chemin

import FilterSidebarTable from "@/components/ui/dataTable/FilterSidebarTable.vue";
import BrandFilter from "@/components/ui/dataTable/filters/BrandFilter.vue";
import PublishStateFilter from "@/components/ui/dataTable/filters/PublishStateFilter.vue";
import DateFilter from "@/components/ui/dataTable/filters/DateFilter.vue";
import ErrorFilter from "@/components/ui/dataTable/filters/ErrorFilter.vue";

import currencyRouteMixin from "@/mixins/currencyRouteMixin";
import tableFilterMixin from "@/mixins/tableFilterMixin";

export default {
  name: "ConfigWebsiteCatalogueArticleListingAllBis",

  components: {
    BaseTable,
    StatusCell,
    ChannelsCell,
    PopinNotification,
    ErrorsCell,
    BaseModal,
    FilterSidebarTable,
    BrandFilter,
    PublishStateFilter,
    DateFilter,
    ErrorFilter,
  },

  mixins: [currencyRouteMixin, tableFilterMixin, dateFormatMixin],

  data() {
    return {
      isCase1: true,
      items: [],
      currentTab: "all",
      searchQuery: "",
      selectedItems: [],
      showStatusConfirmationModal: false,
      fromStatus: "",
      toStatus: "",
      selectedStatus: null,
      publishStateLabels: {
        unpublished: "Dépublié",
        draft: "Brouillon",
      },
      tabCounters: {},
      tabs: [
        { id: "all", label: "Tous" },
        { id: "on_sale", label: "En vente" },
        { id: "to_publish", label: "À publier" },
        { id: "to_correct", label: "À corriger" },
      ],

      availableStatuses: [
        { id: "on_sale", name: "En vente" },
        { id: "to_publish", name: "À publier" },
        { id: "to_correct", name: "À corriger" },
      ],

      availableChannels: [
        { id: "store", name: "Point de vente" },
        { id: "online", name: "boutique en ligne" },
        { id: "marketplace", name: "Marketplace" },
      ],
    };
  },

  computed: {
    ...mapState(
      {
        loading: (state) => state.articlesLoading,
        exportLoading: (state) => state.exportLoading,
        tableFilters: (state) => state.articlesFilters || [],
      },

      ["temporaryUser"]
    ),
    ...mapGetters(["isClient"]),

    filteredItems() {
      const items = this.items || [];
      if (!items.length) return [];

      let filtered = items;

      if (this.isClient) {
        // Pour un client, filtrer uniquement les produits dont le statut est "on_sale"
        filtered = filtered.filter((item) => item.status === "on_sale");
      } else if (this.currentTab !== "all") {
        // Pour un utilisateur non-client, filtrer par le code interne du statut sélectionné
        filtered = filtered.filter((item) => item.status === this.currentTab);
      }

      return filtered;
    },

    availableTabs() {
      if (this.isClient) {
        return [
          { id: "on_sale", label: this.$t("article_put_online.tabs.on_sale") },
        ];
      }
      return [
        { id: "all", label: this.$t("article_put_online.tabs.all") },
        { id: "on_sale", label: this.$t("article_put_online.tabs.on_sale") },
        {
          id: "to_publish",
          label: this.$t("article_put_online.tabs.to_publish"),
        },
        {
          id: "to_correct",
          label: this.$t("article_put_online.tabs.to_correct"),
        },
      ];
    },

    tabCounts() {
      // Pour la lecture seule
      const items = this.items || [];
      return {
        all: items.length,
        on_sale: items.filter((item) => item.status === "En vente").length,
        to_publish: items.filter((item) => item.status === "À publier").length,
        to_correct: items.filter((item) => item.status === "À corriger").length,
      };
    },

    dynamicColumns() {
      const columns = [
        { key: "image", label: "" },
        {
          key: "reference",
          label: this.$t("article_put_online.table.id"),
          sortable: true,
        },
        {
          key: "name",
          label: this.$t("article_put_online.table.name"),
          sortable: true,
        },
        {
          key: "stockDate",
          label: this.$t("article_put_online.table.stock"),
          sortable: true,
        },
        {
          key: "publicationDate",
          label: this.$t("article_put_online.table.publication"),
          sortable: true,
        },
        {
          key: "status",
          label: this.$t("article_put_online.table.status"),
          sortable: true,
        },
      ];

      if (!this.isClient) {
        // Insérer la colonne marque après "name"
        columns.splice(3, 0, {
          key: "brand",
          label: this.$t("article_put_online.table.brand"),
          sortable: true,
        });

        // Ajouter la colonne errors à la fin
        columns.push({
          key: "errors",
          label: this.$t("article_put_online.table.error"),
          sortable: true,
        });

        // Ajouter la nouvelle colonne updateDate à la fin pour tout le monde
        columns.push({
          key: "updateDate",
          label: this.$t("article_put_online.update") || "Mise à jour",
          sortable: true,
        });
      }

      return columns;
    },

    filterTags() {
      const tags = [];

      // Marques
      if (this.activeFilters.brands) {
        const values = this.activeFilters.brands.values;
        const brandFilter = this.tableFilters.find(
          (filter) => filter.key === "brand"
        );

        let label;
        if (values.length === 1) {
          const brand = brandFilter?.options.find(
            (brand) => Number(brand.id) === Number(values[0])
          );
          label = brand?.label || values[0];
        } else {
          label = `Marques (${values.length})`;
        }

        tags.push({
          id: "brands",
          type: "brand",
          operator: this.activeFilters.brands.operator,
          label,
          values: values,
        });
      }

      // PublishStates
      if (this.activeFilters.publishStates) {
        const values = this.activeFilters.publishStates.values;
        const label =
          values.length === 1
            ? this.publishStateLabels[values[0]]
            : `États de publication (${values.length})`;

        tags.push({
          id: "publishStates",
          type: "publishState",
          operator: this.activeFilters.publishStates.operator,
          label: label,
          values: values,
        });
      }

      // Canaux de vente
      if (this.activeFilters.channels) {
        const values = this.activeFilters.channels.values;
        const label =
          values.length === 1 ? values[0] : `Canaux (${values.length})`;

        tags.push({
          id: "channels",
          type: "channel",
          operator: this.activeFilters.channels.operator,
          label: label,
          values: values,
        });
      }

      // Date de mise en stock
      if (this.activeFilters.stockDate) {
        const sd = this.activeFilters.stockDate;
        let display = "";
        if (sd.value1 && sd.value2) {
          display = `${this.formatDateForDisplay(
            sd.value1
          )} - ${this.formatDateForDisplay(sd.value2)}`;
        } else if (sd.value1) {
          display = this.formatDateForDisplay(sd.value1);
        } else if (sd.value2) {
          display = this.formatDateForDisplay(sd.value2);
        }
        tags.push({
          id: "stockDate",
          type: "stockDate",
          operator: sd.operator,
          label: "Date de mise en stock",
          value: display,
        });
      }
      // Date de publication
      if (this.activeFilters.publicationDate) {
        const pd = this.activeFilters.publicationDate;
        let display = "";
        if (pd.value1 && pd.value2) {
          display = `${this.formatDateForDisplay(
            pd.value1
          )} - ${this.formatDateForDisplay(pd.value2)}`;
        } else if (pd.value1) {
          display = this.formatDateForDisplay(pd.value1);
        } else if (pd.value2) {
          display = this.formatDateForDisplay(pd.value2);
        }
        tags.push({
          id: "publicationDate",
          type: "publicationDate",
          operator: pd.operator,
          label: "Date de publication",
          value: display,
        });
      }

      // Erreurs
      if (this.activeFilters.errors) {
        const values = this.activeFilters.errors.values;
        const label =
          values.length === 1 ? values[0] : `Erreurs (${values.length})`;

        tags.push({
          id: "errors",
          type: "error",
          operator: this.activeFilters.errors.operator,
          label: label,
          values: values,
        });
      }

      return tags;
    },
  },

  methods: {
    ...mapActions([
      "fetchArticles",
      "updateArticleStatus",
      "updateArticleChannel",
      "fetchTotalStatePublish",
      "updateArticleState",
    ]),

    handleTabChange(tabId) {
      if (this.currentTab === tabId) return;
      const filterMixin = this.$options.mixins.find(
        (mixin) => mixin.methods && mixin.methods.handleTabChange
      );
      if (filterMixin && filterMixin.methods.handleTabChange) {
        filterMixin.methods.handleTabChange.call(this, tabId);
      } else {
        console.error(
          "La méthode handleTabChange n'a pas été trouvée dans les mixins"
        );
      }
    },

    handleSelectionChange(selectedItems) {
      this.selectedItems = selectedItems;
    },

    handleRemoveFilterTag(tag) {
      const newFilters = { ...this.activeFilters };
      if (tag.type === "stockDate" || tag.type === "publicationDate") {
        delete newFilters[tag.type];
      } else {
        delete newFilters[tag.id];
      }

      this.handleFiltersChange(newFilters);
    },

    goToDetails(item) {
      // Sauvegarder les filtres dans localStorage avant la navigation
      const currentFilters = {
        filters: this.$route.query.filters,
        tab: this.activeFilters?.publishStates?.values?.includes("draft")
          ? "draft"
          : this.activeFilters?.publishStates?.values?.includes("unpublished")
          ? "unpublished"
          : this.currentTab,
        page: this.$route.query.page,
        limit: this.$route.query.limit,
        selectedItems: this.selectedItems,
      };
      localStorage.setItem("savedTableFilters", JSON.stringify(currentFilters));

      this.$router.push({
        name: "config-website-catalogues-articles-details-bis",
        params: { id: item.id },
      });
    },

    handleStatusSelect(status) {
      this.selectedStatus = status;
      this.showStatusConfirmationModal = true;
      this.showStatusPopover = false;
    },

    async confirmStatusChange() {
      try {
        const toStatusCode = this.toStatus;

        if (!toStatusCode) {
          console.error("Statut cible non trouvé:", this.toStatus);
          return;
        }

        await this.updateArticleState({
          ids: this.selectedItems.map((item) => item.id),
          toStatus: toStatusCode,
        });

        // Fermer la modale
        this.showStatusConfirmationModal = false;

        // Rafraîchi les données avec les filtres actifs
        await this.loadTableData(this.activeFilters);

        // Construit les paramètres avec les filtres actifs
        const params = {
          "currency[in]": this.currentCurrency,
          ...this.transformFiltersForAPI(this.activeFilters), // Ajouter tous les filtres actifs
        };

        // Ajoute spécifiquement le filtre de marque s'il existe
        if (this.activeFilters.brands) {
          const operator =
            this.activeFilters.brands.operator === "inclus" ? "in" : "nin";
          params[`brand[${operator}]`] =
            this.activeFilters.brands.values.join(",");
        }

        // Met à jour les compteurs globaux avec les filtres
        await this.$store.dispatch("fetchTotalStatePublish", params);

        // Réinitialise la sélection
        if (this.$refs.baseTable) {
          this.$refs.baseTable.resetSelection();
        }
        this.selectedItems = [];
      } catch (error) {
        console.error("Erreur lors du changement de statut:", error);
      }
    },
    async applyStatusChange(statusId) {
      const selectedStatus = this.availableStatuses.find(
        (s) => s.id === statusId
      );
      if (selectedStatus) {
        try {
          await this.updateArticleStatus({
            ids: this.selectedItems,
            status: selectedStatus.name,
          });
          await this.loadTableData();
        } catch (error) {
          console.error("Erreur mise à jour statut:", error);
        }
      }
      this.closeStatusModal();
    },

    async applyChannelChange(channelId) {
      const selectedChannel = this.availableChannels.find(
        (c) => c.id === channelId
      );
      if (selectedChannel) {
        try {
          await this.updateArticleChannel({
            ids: this.selectedItems,
            channel: selectedChannel.name,
          });
          await this.loadTableData();
        } catch (error) {
          console.error("Erreur mise à jour canal:", error);
        }
      }
      this.closeChannelModal();
    },

    getLastActivityByEvent(activities, eventType) {
      if (!activities || !Array.isArray(activities)) return null;

      // Trouver la dernière activité du type spécifié
      const activity = [...activities]
        .reverse()
        .find((activity) => activity.event === eventType);

      return activity ? activity.createdAt : null;
    },

    async loadTableData(filters = {}) {
      try {
        const currentFilters = { ...this.activeFilters, ...filters };

        const queryParams = {
          page: this.pagination.currentPage,
          limit: this.pagination.itemsPerPage,
          "currency[in]":
            this.$route.query["currency:in"] || this.currentCurrency,
          ...this.transformFiltersForAPI(currentFilters),
        };

        // Pour les clients, forcer le filtre sur les articles en vente
        if (this.isClient) {
          queryParams["publishState[in]"] = "on_sale";
          // Pour les clients (en vente), trier par date de publication décroissante
          queryParams["sort[_publishedDate]"] = "desc";
        } else {
          // Ajoute publishState[in] seulement si nous ne sommes pas sur l'onglet "all"
          if (this.currentTab !== "all") {
            queryParams["publishState[in]"] = this.currentTab;
          }

          // Appliquer les règles de tri en fonction de l'onglet
          if (this.currentTab === "on_sale") {
            // En vente - trier par date de publication décroissante
            queryParams["sort[_publishedDate]"] = "desc";
          } else if (this.currentTab === "to_correct") {
            // À corriger - trier par date de modification décroissante
            queryParams["sort[ac.updatedAt]"] = "desc";
          } else {
            // Tous & À publier - trier par date de mise en stock décroissante
            queryParams["sort[_stockedDate]"] = "desc";
          }
        }

        const data = await this.fetchArticles(queryParams);

        if (data) {
          this.pagination = {
            ...this.pagination,
            totalPages: data.pagination.pageCount,
            totalItems: data.pagination.totalCount,
          };

          this.items = data.items.map((item) => {
            // Trouver les dates de stock et de publication
            const stockDate = this.getLastActivityByEvent(
              item.activities,
              "stocked"
            );
            const publishDate = this.getLastActivityByEvent(
              item.activities,
              "published"
            );

            return {
              id: item.id,
              reference: item.reference,
              name:
                item.metadata.fr_FR?.title || item.metadata.en_GB?.title || "",
              brand: item.brand.name,
              photo: item.photos?.[0]?.url || item.metadata.photos?.[0] || "",
              stockDate: stockDate
                ? moment(stockDate).format("YYYY-MM-DD")
                : "-",
              publicationDate: publishDate
                ? moment(publishDate).format("YYYY-MM-DD")
                : "-",
              status: item.articleCard?.state || item.state,

              channels: item.tradeInCart?.channel
                ? [item.tradeInCart.channel]
                : [],
              updateDate: item.articleCard?.updatedAt
                ? moment(item.articleCard.updatedAt).format("YYYY-MM-DD")
                : "-",
              errors:
                item.articleCard?.state === "to_correct"
                  ? (item.reportReasons || []).map((err) => ({
                      reason: err.reason,
                      description: err.description,
                    }))
                  : [],
            };
          });
        }
      } catch (error) {
        console.error("Erreur chargement articles:", error);
      }
    },

    getStatusFromState(state) {
      const stateMap = {
        to_publish: "À publier",
        on_sale: "En vente",
        unpublished: "Dépublié",
        to_correct: "À corriger",
        draft: "Brouillon",
      };
      return stateMap[state] || state;
    },

    handleFilterUpdate({ type, values, operator, value, value1, value2 }) {
      console.log("DEBUG - handleFilterUpdate input:", {
        type,
        values,
        operator,
        value,
        value1,
        value2,
      });

      const newFilters = { ...this.activeFilters };

      if (type === "stockDate" || type === "publicationDate") {
        // Pour les filtres de date
        if (value1 || value2 || value) {
          newFilters[type] = {
            operator,
            value1: value1 || "",
            value2: value2 || "",
            // Pour l'affichage du tag
            value: value || this.formatDateRange(value1, value2),
          };
        } else {
          delete newFilters[type];
        }
      } else {
        // Pour les autres types de filtres
        const filterKey =
          type === "publishState" ? "publishStates" : `${type}s`;
        if (values && values.length > 0) {
          newFilters[filterKey] = {
            operator,
            values,
          };
        } else {
          delete newFilters[filterKey];
        }
      }

      console.log("DEBUG - handleFilterUpdate newFilters:", newFilters);
      this.handleFiltersChange(newFilters);
    },

    async handleExport() {
      const params = {
        "currency[in]": this.currentCurrency,
        ...(this.currentTab !== "all" && {
          "publishState[in]": this.currentTab,
        }),
        ...this.transformFiltersForAPI(this.activeFilters),
      };

      // S'il y a au moins un article sélectionné, on ajoute la liste des références
      if (this.selectedItems.length > 0) {
        const selectedReferences = this.items
          .filter((item) => this.selectedItems.includes(item.id))
          .map((item) => item.reference);

        params["reference[in]"] = selectedReferences.join(",");
      }

      try {
        await this.$store.dispatch("exportArticles", params);
      } catch (error) {
        if (error.status === 202) {
          this.$refs.notification.open({
            text: "L'export va vous être envoyé par email",
            color: "green",
            confirmation: false,
            duration: 3000,
          });
        }
      }
    },
    getBrandParams(filters) {
      if (!filters.brands) return {};
      const operator = filters.brands.operator === "inclus" ? "in" : "nin";
      return {
        [`brand[${operator}]`]: filters.brands.values.join(","),
      };
    },
    handleStatusSelected({ items, status }) {
      const selectedItems = this.items.filter((item) =>
        items.includes(item.id)
      );
      this.selectedItems = selectedItems;

      // Trouver le premier item sélectionné pour obtenir le statut actuel
      const firstSelectedItem = selectedItems[0];
      this.fromStatus = firstSelectedItem ? firstSelectedItem.status : "";

      // Convertir le status.id en code interne
      const statusCodeMap = {
        on_sale: "on_sale",
        to_publish: "to_publish",
        to_correct: "to_correct",
        draft: "draft",
        unpublished: "unpublished",
      };

      this.toStatus = statusCodeMap[status.id];
      this.selectedStatus = status;
      this.showStatusConfirmationModal = true;
    },

    handleChannelSelected({ items, channel }) {
      // Ouvrir la modale de confirmation avec le canal sélectionné
      this.selectedItems = items;
      this.selectedChannel = channel;
      this.openChannelModal();
    },
  },

  mounted() {
    // Restaure l'état de sélection au montage du composant
    const savedFilters = localStorage.getItem("savedTableFilters");
    if (savedFilters) {
      const { selectedItems } = JSON.parse(savedFilters);
      if (this.$refs.baseTable) {
        this.$refs.baseTable.restoreSelection(selectedItems);
      }
      localStorage.removeItem("savedTableFilters");
    }
  },

  created() {
    this.$store.dispatch("getTableFilters", {
      name: "articles",
      url: "new-articles",
    });
    const query = this.$route.query;
    const filters = query.filters ? JSON.parse(query.filters) : {};

    const params = {
      "currency[in]": this.currentCurrency,
      ...this.transformFiltersForAPI(filters),
    };

    if (this.isClient) {
      this.currentTab = "on_sale";
    }

    if (filters.brands) {
      const operator = filters.brands.operator === "inclus" ? "in" : "nin";
      params[`brand[${operator}]`] = filters.brands.values.join(",");
    }

    this.$store.dispatch("fetchTotalStatePublish", params);

    this.tabCounters = this.tabCounts;
  },

  watch: {
    currentCurrency: {
      async handler(newCurrency, oldCurrency) {
        if (newCurrency !== oldCurrency) {
          await this.loadTableData();
          await this.$store.dispatch("fetchTotalStatePublish", {
            "currency[in]": newCurrency,
            ...this.getBrandParams(this.activeFilters),
          });
        }
      },
    },
    isClient: {
      immediate: true,
      handler(value) {
        if (value) {
          this.currentTab = "on_sale";
        }
      },
    },
  },
};
</script>

<style scoped>
.listing-container {
  min-height: 100vh;
  margin-bottom: 80px;
}

.header-section {
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-section h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 8px;
}

.header-section p {
  color: #666;
}

.btn {
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.btn-export {
  background: black;
  color: white;
  border: none;
}

.btn-filter {
  border: 1px solid #e0e0e0;
  background: white;
  height: 40px;
}

.product-image {
  width: 40px;
  height: 40px;
  background: #f0f0f0;
  border-radius: 4px;
  overflow: hidden;
}

.product-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  mix-blend-mode: multiply;
}

@media (max-width: 768px) {
  .header-section {
    flex-direction: column;
    gap: 16px;
  }

  .btn-export {
    width: 100%;
    justify-content: center;
  }
}

.btn.btn-export {
  position: relative;

  .loader-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    border: 2px solid #fff;
    border-top: 2px solid black;
    border-radius: 50%;
    animation: spin 0.8s linear infinite;
  }
}

.opacity-50 {
  opacity: 0.5;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.status-confirmation {
  .description {
    color: #666;
    margin-bottom: 24px;
  }

  .action-section h4 {
    font-size: 14px;
    margin: 0 0 8px;
  }

  .status-change {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .status {
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 14px;
  }

  .status-from {
    background: #ffefef;
    color: #e53935;
  }

  .status-to {
    background: #fff3e0;
    color: #f57c00;
  }

  .arrow {
    color: #666;
  }
}
</style>
