<template>
  <div class="filter-group">
    <div class="filter-group-header">
      <h4>{{ label }}</h4>
    </div>
    <div class="date-inputs-container">
      <!-- Date de début -->
      <div class="date-group">
        <label class="date-label">{{ $t("article_put_online.operator.supérieur à") }}</label>
        <div class="date-input-container">
          <v-date-picker
            v-model="startDate"
            :max-date="currentDate"
            :masks="dateMasks"
            :locale="$i18n.locale"
            :model-config="dateConfig"
            color="gray"
          >
            <template v-slot="{ inputEvents }">
              <div class="date-input-wrapper">
                <button class="date-picker-btn" v-on="inputEvents">
                  <SvgIcon name="calendar" class="icon" />
                </button>
                <input
                  :value="formatDateForDisplay(startDate)"
                  class="date-input"
                  :placeholder="dateFormatPlaceholder"
                  readonly
                />
                <button
                  v-if="startDate"
                  class="date-clear-btn"
                  @click="clearStartDate"
                  type="button"
                >
                  ×
                </button>
              </div>
            </template>
          </v-date-picker>
        </div>
      </div>

      <!-- Date de fin -->
      <div class="date-group">
        <label class="date-label">{{ $t("article_put_online.operator.inférieur à") }}</label>
        <div class="date-input-container">
          <v-date-picker
            v-model="endDate"
            :max-date="currentDate"
            :min-date="startDate"
            :masks="dateMasks"
            :locale="$i18n.locale"
            :model-config="dateConfig"
            color="gray"
          >
            <template v-slot="{ inputEvents }">
              <div class="date-input-wrapper">
                <button class="date-picker-btn" v-on="inputEvents">
                  <SvgIcon name="calendar" class="icon" />
                </button>
                <input
                  :value="formatDateForDisplay(endDate)"
                  class="date-input"
                  :placeholder="dateFormatPlaceholder"
                  readonly
                />
                <button
                  v-if="endDate"
                  class="date-clear-btn"
                  @click="clearEndDate"
                  type="button"
                >
                  ×
                </button>
              </div>
            </template>
          </v-date-picker>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/ui/svg/SvgIcon.vue";

export default {
  name: "DateFilter",
  components: { SvgIcon },
  props: {
    type: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    value1: {
      type: String,
      default: ""
    },
    value2: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      startDate: "",
      endDate: "",
      dateConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
      },
      dateMasks: {
        input: "YYYY-MM-DD",
        model: "YYYY-MM-DD",
      }
    };
  },
  computed: {
    currentDate() {
      return new Date().toISOString().split("T")[0];
    },
    dateFormatPlaceholder() {
      return this.$i18n.locale === "en" ? "YYYY-MM-DD" : "JJ/MM/AAAA";
    }
  },
  methods: {
    clearStartDate() {
      this.startDate = "";
      this.emitUpdate();
    },
    
    clearEndDate() {
      this.endDate = "";
      this.emitUpdate();
    },
    
    formatDateForDisplay(date) {
      if (!date) return "";

      // Si la date est déjà au format YYYY-MM-DD, on la convertit
      if (typeof date === "string") {
        const [year, month, day] = date.split("-");

        // Pour l'anglais, garder le format YYYY-MM-DD
        if (this.$i18n.locale === "en") {
          return date;
        }

        // Pour les autres locales (comme le français), format JJ/MM/AAAA
        return `${day}/${month}/${year}`;
      }

      return "";
    },
    
    emitUpdate() {
      this.$emit('update', this.type, 'value1', this.startDate);
      this.$emit('update', this.type, 'value2', this.endDate);
    }
  },
  created() {
    this.startDate = this.value1;
    this.endDate = this.value2;
  },
  watch: {
    startDate() {
      this.emitUpdate();
    },
    endDate() {
      this.emitUpdate();
    },
    value1(newVal) {
      if (newVal !== this.startDate) {
        this.startDate = newVal;
      }
    },
    value2(newVal) {
      if (newVal !== this.endDate) {
        this.endDate = newVal;
      }
    }
  }
};
</script>

<style scoped>
.filter-group {
  background: white;
  padding: 20px;
  padding-bottom: 32px;
  border-bottom: 1px solid #e9ecef;
}

.filter-group-header {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter-group-header h4 {
  font-weight: 600;
  color: #1a1a1a;
  font-size: 14px;
}

.date-inputs-container {
  display: flex;
  gap: 12px;
}

.date-group {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.date-label {
  font-size: 12px;
  color: #666;
  font-weight: 500;
}

.date-input-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.date-input-wrapper {
  position: relative;
  width: 100%;
}

.date-picker-btn {
  position: absolute;
  left: 1px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  padding: 8px;
  border-radius: 7px 0 0 7px;
  color: #495057;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 2px);
  border-right: 1px solid #dee2e6;
  cursor: pointer;
  background: transparent;
  border: none;
}

.date-picker-btn:hover {
  background: #f9f9f9;
}

.date-input {
  width: 100%;
  padding: 10px;
  padding-right: 30px;
  padding-left: 40px;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  font-size: 13px;
  color: #495057;
  background-color: white;
}

.icon {
  width: 16px;
  height: 16px;
}

.date-input:hover {
  border-color: #adb5bd;
}

.date-input:focus {
  outline: none;
  border-color: #000000;
  box-shadow: 0 0 0 3px rgb(0 0 0 / 10%);
}

.date-clear-btn {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: #666;
  font-size: 18px;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.date-clear-btn:hover {
  background: rgba(0, 0, 0, 0.1);
  color: #333;
}
</style>