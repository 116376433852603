<template>
    <TemplateDetails :get-function="getResale" :data="resale" :loading="resaleLoading">

        <PageBack/>
        
        <div v-if="resale">
            <PageHeader :title="$t('resale.title') + resale.shopifyId">
                <template v-slot:text>
                    <TextSimple color="grey">{{ resale.createdAt | dateHour }}</TextSimple>
                    <TagList>
                        <Tag v-if="isCancelled" color="red" :text="$t('global.cancelled')"/>
                        <TagType v-else-if="resale?.controlReview?.condition?.name?.length" :value="resale.controlReview.condition.name"/>
                        <Tag v-if="status && !isCancelled" :text="status" color="grey"/>
                        <Tag v-if="resale.inStock" :text="$t('global.inStock')" color="blue2"/>
                        <Tag v-if="resale.shopifyPublished" :text="$t('global.published')" color="blue"/>
                    </TagList>
                </template>
                <template v-slot:actions>
                    <Button v-if="canBeCancel && !isCancelled" @click.native.prevent="$refs.popinCancel.open()" icon="trash" size="m" color="red">{{ $t('resale.remove_user.title') }}</Button>
                    <ButtonLink :to="resale.printVoucherUrl" icon="download" size="m" color="grey">{{ $t("resale.print-voucher") }}</ButtonLink>
                    <ButtonLink :to="shopifyProductUrl" icon="tshirt" size="m" color="grey">{{ $t("resale.see-product") }}</ButtonLink>
                </template>
            </PageHeader>
            
            <Popin ref="popinCancel"
                   :center="true"
                   :title="$t('resale.remove_user.title')"
                   :text="$t('resale.remove_user.text')">
                <template v-slot:bottom>
                    <Button @click.native.prevent="$refs.popinCancel.close()" size="m" color="red" icon="cross">{{ $t('resale.remove_user.button_cancel') }}</Button>
                    <Button @click.native.prevent="cancel" size="m" color="green" :loading="canceling" icon="check">{{ $t('resale.remove_user.button_confirm') }}</Button>
                </template>
            </Popin>

            <GridContainer>
                <GridCard>
                    <GridContent>
                        <GridHeader :title="$t('resale.informations')"/>
                        <SectionTableInfos :object="resale" :fields="informationsFields"/>
                    </GridContent>
                </GridCard>

                <Table>
                    <Thead :fields="controlledFields"/>
                    <Tbody>
                    <Row v-for="(value, key) in resale.infos" :key="key">
                        <TableCell :value="$t(key === 'condition' ? 'resale.condition' : `global.${key}`) +' :'"/>
                        <TableCell :value="value[0]" :type="key === 'tradeinPrice' ? 'formatPrice' : null"/>
                        <TableCell :value="resale.controlReview ? value[1] : null" :type="key === 'tradeinPrice' ? 'formatPrice' : null"/>
                        <TableCell v-if="resale.controlReview" :value="value[0] && value[1] ? value[0] === value[1] : null" type="check"/>
                    </Row>
                    </Tbody>
                </Table>

                <ImagesWithLightbox v-if="resale?.rejectPhotos?.length" :images="resale.rejectPhotos"/>

                <template v-slot:aside>
                    <GridCard>
                        <GridContent>
                            <GridHeader :title="$t('global.customer')" size="xs"/>
                            <SectionTableInfos :object="resale" :fields="customerFields"/>
                        </GridContent>
                    </GridCard>

                    <GridCard>
                        <GridContent>
                            <GridHeader :title="$t('global.slot')" size="xs"/>
                            <SectionTableInfos :object="resale" :fields="slotFields"/>
                        </GridContent>
                    </GridCard>

                    <GridCard v-if="resale.postChecking || resale.postOpening || resale.postShooting">
                        <GridContent>
                            <GridHeader :title="$t('global.delays')" size="xs"/>
                            <SectionTableInfos :object="resale" :fields="delayFields"/>
                        </GridContent>
                    </GridCard>

                    <GridContent>
                        <GridHeader :title="$t('resale.calendar')"/>
                        <GridCalendar :dates="datesFields" :object="resale"/>
                    </GridContent>
                </template>
            </GridContainer>

            <SectionTable :title="$t('global.treatment')" v-if="resale.warehouseOperations?.length">
                <Table>
                    <Thead :fields="treatmentFields"/>
                    <TbodyLoading v-if="!resale"/>
                    <Tbody v-else>
                    <Row v-for="(operation, i) in resale.warehouseOperations" :key="i">
                        <TableCell :value="operation.label.includes('.') ? $t(operation.label) : operation.label"/>
                        <TableCell :value="operation.date" type="dateHour"/>
                        <TableCell :value="operation.operator"/>
                        <TableCell :value="operation.comment"/>
                    </Row>
                    </Tbody>
                </Table>
            </SectionTable>
        </div>
        
    </TemplateDetails>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import PageHeader from "@/components/ui/page/PageHeader";
import Thead from "@/components/ui/table/thead/Thead";
import Row from "@/components/ui/table/row/Row";
import TableCell from "@/components/ui/table/cell/TableCell";
import SectionTable from "@/components/sections/SectionTable";
import Table from "@/components/ui/table/Table";
import TemplateDetails from "@/components/templates/TemplateDetails";
import PageBack from "@/components/ui/page/PageBack";
import Tbody from "@/components/ui/table/body/Tbody";
import TbodyLoading from "@/components/ui/table/body/TbodyLoading";
import GridContainer from "@/components/ui/grid/GridContainer";
import GridCard from "@/components/ui/grid/GridCard";
import GridHeader from "@/components/ui/grid/GridHeader";
import GridContent from "@/components/ui/grid/GridContent";
import TextSimple from "@/components/ui/text/TextSimple";
import GridCalendar from "@/components/ui/grid/GridCalendar";
import TagType from "@/components/ui/tag/TagType";
import ButtonLink from "@/components/ui/button/ButtonLink";
import TagList from "@/components/ui/tag/TagList";
import Tag from "@/components/ui/tag/Tag";
import ImagesWithLightbox from "@/components/ui/imagesWithLightbox";
import SectionTableInfos from "@/components/sections/SectionTableInfos";
import Button from '@/components/ui/button/Button';
import Popin from '@/components/ui/popin/Popin';

export default {
    name: "ResalePage",
    components: {Popin, Button, SectionTableInfos, ImagesWithLightbox, Tag, TagList, ButtonLink, GridCalendar, TextSimple, GridContent, GridHeader, GridCard, GridContainer, TagType, TbodyLoading, Tbody, PageBack, TemplateDetails, Table, PageHeader, SectionTable, TableCell, Row, Thead,},
    data() {
        return {
            loading: true,
            canceling: false,
            cancelled: false,
            informationsFields: [
                {key: "shopifyId", type: "shopifyProduct"},
                {key: "token", type: "copy"},
                {key: "trackingId", type: "copy"},
                {key: "trackingUrl", type: "url"},
                {key: "country"},
                //{key: 'logisticianCondition'},
                {key: "promoCode", type: "copy"},
                {key: "irl", type: "boolean"},
                {key: "shopifyPublished", type: "boolean"},
                {key: "shopifyPublishedDate", type: "date"},
                {key: "rejectReason"},
            ],
            slotFields: [
                {key: "slot"},
                {key: "slotPosition"},
                {key: "inStock", type: "boolean"},
            ],
            publishFields: [
                {key: "shopifyPublished", type: "boolean"},
                {key: "shopifyPublishedDate", type: "date"},
            ],
            customerFields: [
                {key: "createdAt", type: "date"},
                {key: "client", type: "client"},
                {key: "user.firstname"},
                {key: "user.lastname"},
                {key: "user.email", type: "userEmail"},
            ],
            datesFields: [
                {key: "createdAt"},
                // {key: 'logisticsInformations.carrierSendingDate'},
                // {key: 'logisticsInformations.carrierDeliveryDate'},
                {key: "logisticsInformations.warehouseReceiveDate"},
                {key: "logisticsInformations.warehouseOpenDate"},
                {key: "logisticsInformations.warehouseValidationDate"},
                {key: "logisticsInformations.warehouseShootingDate"},
                {key: "logisticsInformations.warehouseStockDate"},
                {key: "shopifyPublishedDate"},
            ],
            delayFields: [
                {key: "postChecking"},
                {key: "postOpening"},
                {key: "postShooting"},
            ],
            treatmentFields: [
                {},
                { key: 'date' },
                { key: 'made_by' },
                { key: 'comments' },
            ],
            controlledFields: [
                {},
                { translation: 'resale.declared' },
                { translation: 'resale.controlled' },
                {},
            ]
        };
    },
    computed: {
        ...mapState(['resale', 'resaleLoading', 'clients']),
        ...mapGetters(['roles']),
        canBeCancel() {
            return ['ROLE_ADMIN', 'ROLE_BRAND_SAV', 'ROLE_BRAND_STORE'].find(role => this.roles.includes(role)) && this.resale?.irl && this.resale?.user?.id
        },
        isCancelled() {
            return this.cancelled || (this.resale?.irl && !this.resale?.user?.id)
        },
        shopifyId() {
            return this.$route.params.id;
        },
        shopifyProductUrl() {
            if (!this.resale || !this.resale.client) return false;
            return `${this.clients.find((c) => c.id === this.resale.client.id)?.frontUrl}/admin/products/${this.resale.shopifyId}`;
        },
        status() {
            if (!this.resale) return null;
            const validationDate = this.resale.logisticsInformations.warehouseValidationDate;
            const shootingDate = this.resale.logisticsInformations.warehouseShootingDate;
            const openDate = this.resale.logisticsInformations.warehouseOpenDate;
            const stockDate = this.resale.logisticsInformations.warehouseStockDate;
            const receiveDate = this.resale.logisticsInformations.warehouseReceiveDate;
            const sendingDate = this.resale.logisticsInformations.carrierSendingDate;
            const published = this.resale.shopifyPublished;
            const badCondition = this.resale.logisticianCondition === "Mauvais état";
            if (!shootingDate && validationDate && !badCondition)
                return this.$t("resale.status.waiting-shooting");
            if (shootingDate && validationDate && !badCondition && !published)
                return this.$t("resale.status.waiting-publishing");
            if (!validationDate && !shootingDate && !openDate && !receiveDate && !sendingDate)
                return this.$t("resale.status.waiting-shipping");
            if (!validationDate && !shootingDate && !openDate && !receiveDate)
                return this.$t("resale.status.waiting-delivery");
            if (!validationDate && !shootingDate && !openDate)
                return this.$t("resale.status.waiting-open");
            if (!validationDate) return this.$t("resale.status.waiting-validation");
            if (validationDate && !stockDate)
                return this.$t("resale.status.waiting-stock");
            return null;
        },
    },
    methods: {
        ...mapActions(["getResale", 'removeUserFromResale']),
        cancel() {
            this.canceling = true;
            this.removeUserFromResale(this.resale.id)
                    .then(() => {
                        this.cancelled = true;
                        this.$refs.popinCancel.close()
                    })
                    .finally(() => this.canceling = false)
        }
    },
};
</script>
