import API from "@/api/api-admin";
import listRoutes from "@/api/list-routes";

const state = {
  warehouseId: "e7e9a72b-d385-42df-b436-c61a8e28e9eb",
  warehouseStats: null,
  warehouseStatsFilters: null,
  warehousePeriod: null,
  warehouseHours: null,
  warehouses: null,
};

const mutations = {
  SET_WAREHOUSE_STATS(state, data) {
    state.warehouseStats = data;
  },
  SET_WAREHOUSE_PERIOD(state, data) {
    state.warehousePeriod = data;
  },
  SET_WAREHOUSE_HOURS(state, data) {
    state.warehouseHours = data;
  },
  SET_WAREHOUSES(state, data) {
    state.warehouses = data;
  },
  SET_WAREHOUSE_STATS_FILTERS(state, data) {
    state.warehouseStatsFilters = data;
  },
};

const actions = {
  getWarehouses({ commit }) {
    API.get(listRoutes.warehouses).then((res) => {
      let warehouses = res?.data?.items || [];
      warehouses = warehouses.filter((w) =>
        ["Berry", "Erren", "Blancliving"].includes(w.name)
      );
      commit("SET_WAREHOUSES", warehouses);
    });
  },
  getWarehouseStats({ commit }, filters) {
    let url = `${
      listRoutes.warehouseStats
    }?warehouse[in]=${filters.warehouses.join(",")}`;
    if (filters.clients?.length)
      url += `&clients[in]=${filters.clients.join(",")}`;
    commit("SET_WAREHOUSE_STATS", null);
    API.get(url).then((res) => {
      if (res && res.data) {
        let data = JSON.stringify(res.data);
        data = data
          .replaceAll("warehouse.", "")
          .replaceAll("live.", "")
          .replaceAll("pending.", "")
          .replaceAll("recap.", "")
          .replaceAll("order_and_stock.", "")
          .replaceAll("treatment.", "")
          .replaceAll("orders.packed", "orders_packed");
        data = JSON.parse(data);
        commit("SET_WAREHOUSE_STATS", data);
      }
    });
  },
  getWarehouseStatsFilters({ commit }) {
    return new Promise((resolve) => {
      API.get(`${listRoutes.warehouseStats}/filters`)
        .then((res) => res.data)
        .then((data) => {
          commit("SET_WAREHOUSE_STATS_FILTERS", data);
          resolve();
        });
    });
  },
  getWarehouseStatsPerPeriod({ commit }, data) {
    const { date, warehouses } = data;
    commit("SET_WAREHOUSE_PERIOD", null);
    let url = listRoutes.warehouseStats;
    let filters = [];
    if (date && date.start)
      filters.push(
        `start[gte]=${date.start} 00:00:00&end[lte]=${date.end} 23:59:59`
      );
    if (warehouses?.length)
      filters.push(`warehouse[in]=${warehouses.join(",")}`);
    if (filters.length) url += `?${filters.join("&")}`;
    API.get(url).then((res) => {
      if (res && res.data) {
        let data = JSON.stringify(res.data);
        data = data
          .replaceAll("warehouse.", "")
          .replaceAll("live.", "")
          .replaceAll("pending.", "")
          .replaceAll("recap.", "")
          .replaceAll("order_and_stock.", "")
          .replaceAll("treatment.", "")
          .replaceAll("orders.packed", "orders_packed");
        data = JSON.parse(data);
        commit("SET_WAREHOUSE_PERIOD", data);
      }
    });
  },
  getWarehouseHours({ commit, state }, prop) {
    API.get(
      `${listRoutes.warehouseHours.replace(":id", state.warehouseId)}${
        prop.playload
      }`
    ).then((res) => {
      if (res && res.data) commit("SET_WAREHOUSE_HOURS", res.data);
    });
  },
};

const getters = {
  warehouseRecap: (state) =>
    state.warehouseStats ? state.warehouseStats.recap : null,
  warehouseLive: (state) =>
    state.warehouseStats ? state.warehouseStats.live : null,
  warehousePending: (state) =>
    state.warehouseStats ? state.warehouseStats.pending : null,
  warehouseOptions: (state) =>
    state.warehouses
      ? state.warehouses.map((w) => ({ id: w.id, label: w.name }))
      : null,
};

const warehouse = {
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
};

export default warehouse;
